import { InterfaceWidgetCheckbox } from "./InWidgets/InterfaceWidgetCheckbox";
import { InterfaceWidgetText } from "./InWidgets/InterfaceWidgetText";
import { InterfaceWidgetTextArea } from "./InWidgets/InterfaceWidgetTextArea";
import {
  iWidgetModelTypesEnum,
  iWidgetScoreValue,
  iWidgetTableValue,
  IWidgetNestedCustomInputProps,
  iWidgetNestedParamsInput,
  iWidgetNestedParamsOutput,
  iWidgetOutputParams,
  iWidgetInputParams,
  iWidgetConversationProps,
} from "./interfaceWidgetTypes";
import * as Yup from "yup";
import mapValues from "lodash/mapValues";
import { InterfaceWidgetJson } from "./OutWidgets/InterfaceWidgetJson";
import { InterfaceWidgetScore } from "./OutWidgets/InterfaceWidgetScore";
import { InterfaceWidgetTable } from "./OutWidgets/InterfaceWidgetTable";
import { InterfaceWidgetTokenHighlight } from "./OutWidgets/InterfaceWidgetTokenHighlight";
import { InterfaceWidgetScoreText } from "./OutWidgets/InterfaceWidgetScoreText";
import { InterfaceWidgetConversation } from "./OutWidgets/InterfaceWidgetConversation";
import { InterfaceWidgetTextArea as InterfaceWidgetTextAreaOut } from "./OutWidgets/InterfaceWidgetTextArea";
import { InterfaceWidgetDynamicTexts } from "./InWidgets/InterfaceWidgetDynamicTexts";
import { InterfaceWidgetImageDropzone } from "./InWidgets/InterfaceWidgetImageDropzone";
import { InterfaceWidgetEditableTable } from "./InWidgets/InterfaceWidgetEditableTable";
import { inferenceToken } from "api/apiTypes";
import { InterfaceWidgetSlider } from "./InWidgets/InterfaceWidgetSlider";
import { InterfaceWidgetNumber } from "./InWidgets/InterfaceWidgetNumber";

/**
 * A list of classification specific output schemas
 * @category Schema
 */
const outputSchemas = {
  [iWidgetModelTypesEnum.TEXT_GENERATION]: Yup.array()
    .of(
      Yup.object().shape({
        generated_text: Yup.string().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.FEATURE_EXTRACTION]: Yup.array()
    .of(Yup.array().of(Yup.number().required()).required())
    .required(),
  [iWidgetModelTypesEnum.TEXT_CLASSIFICATION]: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        score: Yup.number().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.FILL_MASK]: Yup.array()
    .of(
      Yup.object().shape({
        score: Yup.number().required(),
        token: Yup.number().required(),
        token_str: Yup.string().required(),
        sequence: Yup.string().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.TOKEN_CLASSIFICATION]: Yup.array()
    .of(
      Yup.object().shape({
        entity: Yup.string().required(),
        score: Yup.number().required(),
        word: Yup.string().required(),
        start: Yup.number().required(),
        end: Yup.number().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.QUESTION_ANSWERING]: Yup.object().shape({
    score: Yup.number().required(),
    start: Yup.number().required(),
    end: Yup.number().required(),
    answer: Yup.string().required(),
  }),
  [iWidgetModelTypesEnum.TRANSLATION]: Yup.array()
    .of(
      Yup.object().shape({
        translation_text: Yup.string().required(),
      })
    )
    .required(),

  [iWidgetModelTypesEnum.SUMMARIZATION]: Yup.array()
    .of(
      Yup.object().shape({
        summary_text: Yup.string().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.TEXT2TEXT_GENERATION]: Yup.array()
    .of(
      Yup.object().shape({
        generated_text: Yup.string().required(),
      })
    )
    .required(),

  [iWidgetModelTypesEnum.TEXT_TO_IMAGE]: Yup.object(),
  [iWidgetModelTypesEnum.TEXT_TO_SPEECH]: Yup.object(),
  [iWidgetModelTypesEnum.CONVERSATIONAL]: Yup.object().shape({
    generated_text: Yup.string().required(),
    conversation: Yup.object().shape({
      generated_responses: Yup.array().of(Yup.string().required()).required(),
      past_user_inputs: Yup.array().of(Yup.string().required()).required(),
      warnings: Yup.array().of(Yup.string().required()),
    }),
  }),
  [iWidgetModelTypesEnum.ZERO_SHOT_CLASSIFICATION]: Yup.object().shape({
    sequence: Yup.string().required(),
    labels: Yup.array().of(Yup.string().required()).required(),
    scores: Yup.array().of(Yup.number().required()).required(),
    warnings: Yup.array().of(Yup.string().required()),
  }),
  [iWidgetModelTypesEnum.SENTENCE_SIMILARITY]: Yup.array()
    .of(Yup.number().required())
    .required(),
  [iWidgetModelTypesEnum.IMAGE_CLASSIFICATION]: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        score: Yup.number().required(),
      })
    )
    .required(),
  [iWidgetModelTypesEnum.TABLE_QUESTION_ANSWERING]: Yup.object().shape({
    answer: Yup.string().required(),
  }),
};

const singleTextFieldWidget = (
  componentProps: IWidgetNestedCustomInputProps
): iWidgetNestedParamsInput => {
  return {
    components: [
      {
        componentProps: {
          placeholder: "Enter the text",
          ...componentProps,
        },
        component: InterfaceWidgetTextArea,
      },
    ],
    componentMapper: (values: any) => {
      return { inputs: values[0] ?? "" };
    },
    formValidationSchema: Yup.object().shape({
      0: Yup.string().required("You need to enter a text"),
    }),
  };
};

const textFieldWithOptionsWidget = (
  componentProps: IWidgetNestedCustomInputProps
): iWidgetNestedParamsInput => {
  return {
    components: [
      {
        componentProps: {
          placeholder: "Enter the text",
        },
        component: InterfaceWidgetTextArea,
      },
      {
        componentProps: {
          label: "temperature",
        },
        component: InterfaceWidgetSlider,
      },
      {
        componentProps: {
          label: "do sample",
        },
        component: InterfaceWidgetCheckbox,
      },
      {
        componentProps: {
          label: "max new token [5-1000]",
        },
        component: InterfaceWidgetNumber,
      },
    ],
    componentMapper: (values) => {
      return {
        inputs: values[0] ?? "",
        parameters: {
          temperature: values[1],
          do_sample: values[2] ? true : false,
          max_new_tokens: parseInt(values[3] as string),
        },
      };
    },
    formValidationSchema: Yup.object().shape({
      0: Yup.string().required("You need to enter a text"),
      1: Yup.number().min(0.2).max(0.99).required("You need to enter a text"),
      2: Yup.boolean().required("You need to enter at least one class name"),
      3: Yup.number().min(5).max(1000).required("You need to enter a text"),
    }),
    tips: "Temperature sets the creativity level of the model generated output, the higher it is the more creative the model. The lower values is preferred for most medical applications to keep the model rather rigorous than creative. do_sample, changes the sampling generation of the model strategy. max_new_tokens tell the model approximately how many words it can generate.",
  };
};

/**
 * Maps the model classification to input components for the {@link InterfaceWidget | 'InterfaceWidget'}
 * @param modelType - model classification
 * @param inputs - depending on the classification, models input parameters
 * @returns an object, containing and array of input components and their configurations
 * @category Logic
 */
export const modelTypeToInput = (
  modelType: iWidgetModelTypesEnum,
  inputs?: any
): iWidgetInputParams | undefined => {
  let result: iWidgetInputParams | undefined = undefined;
  switch (modelType) {
    case iWidgetModelTypesEnum.TEXT_GENERATION:
      result = {
        input: textFieldWithOptionsWidget({}),
        defaultParams: {
          0: "My name is Thomas and my main",
          1: 0.9,
          2: true,
          3: 40,
        },
      };
      break;
    case iWidgetModelTypesEnum.FEATURE_EXTRACTION:
      result = {
        input: singleTextFieldWidget({}),
        defaultParams: {
          0: "Hello world",
        },
      };
      break;
    case iWidgetModelTypesEnum.TEXT_CLASSIFICATION:
      result = {
        input: singleTextFieldWidget({}),
        defaultParams: {
          0: "I like you. I love you",
        },
      };

      break;
    case iWidgetModelTypesEnum.FILL_MASK:
      result = {
        input: singleTextFieldWidget({
          label: "Mask token: <mask>",
        }),
        defaultParams: {
          0: "The goal of life is <mask>.",
        },
      };
      break;
    case iWidgetModelTypesEnum.TOKEN_CLASSIFICATION:
      result = {
        input: singleTextFieldWidget({}),
        defaultParams: {
          0: "My name is Clara and I live in Berkeley, California.",
        },
      };

      break;
    case iWidgetModelTypesEnum.QUESTION_ANSWERING:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the question",
              },
              component: InterfaceWidgetTextArea,
            },
            {
              componentProps: {
                placeholder: "Enter the context",
                label: "Context",
              },
              component: InterfaceWidgetTextArea,
            },
          ],
          componentMapper: (values) => {
            return {
              inputs: {
                question: values[0] ?? "",
                context: values[1] ?? "",
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a text"),
            1: Yup.string().required("You need to enter a context"),
          }),
        },
        defaultParams: {
          0: "What's my name?",
          1: "My name is Clara and I live in Berkeley.",
        },
      };
      break;
    case iWidgetModelTypesEnum.TRANSLATION:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the text",
              },
              component: InterfaceWidgetTextArea,
            },
          ],
          componentMapper: (values: any) => {
            return { inputs: values[0] ?? "" };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a text"),
          }),
        },
        defaultParams: {
          0: "My name is Wolfgang and I live in Berlin",
        },
      };
      break;
    case iWidgetModelTypesEnum.SUMMARIZATION:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the text",
              },
              component: InterfaceWidgetTextArea,
            },
          ],
          componentMapper: (values: any) => {
            return { inputs: values[0] ?? "" };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a text"),
          }),
        },
        defaultParams: {
          0: "The tower is 324 metres (1,063 ft) tall, about the same height as an 81-storey building, and the tallest structure in Paris. Its base is square, measuring 125 metres (410 ft) on each side. During its construction, the Eiffel Tower surpassed the Washington Monument to become the tallest man-made structure in the world, a title it held for 41 years until the Chrysler Building in New York City was finished in 1930. It was the first structure to reach a height of 300 metres. Due to the addition of a broadcasting aerial at the top of the tower in 1957, it is now taller than the Chrysler Building by 5.2 metres (17 ft). Excluding transmitters, the Eiffel Tower is the second tallest free-standing structure in France after the Millau Viaduct.",
        },
      };
      break;
    case iWidgetModelTypesEnum.TEXT2TEXT_GENERATION:
      result = {
        input: textFieldWithOptionsWidget({}),
        defaultParams: {
          0: "Once upon a time",
          1: 0.9,
          2: true,
          3: 40,
        },
      };
      break;
    case iWidgetModelTypesEnum.TEXT_TO_IMAGE:
      result = {
        input: singleTextFieldWidget({}),
        defaultParams: {
          0: "Summer park",
        },
      };
      break;
    case iWidgetModelTypesEnum.TEXT_TO_SPEECH:
      result = {
        input: singleTextFieldWidget({}),
        defaultParams: {
          0: "Hello world",
        },
      };
      break;
    case iWidgetModelTypesEnum.CONVERSATIONAL:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the text",
              },
              component: InterfaceWidgetTextArea,
            },
          ],
          componentMapper: (values: any) => {
            return {
              inputs: {
                text: values[0] ?? "",
                generated_responses:
                  inputs?.conversation?.generatedResponses ?? [],
                past_user_inputs: inputs?.conversation?.pastUserInputs ?? [],
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a text"),
          }),
        },
        defaultParams: {
          0: "Hey my name is julien! How are you?",
        },
      };

      break;
    case iWidgetModelTypesEnum.ZERO_SHOT_CLASSIFICATION:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the text",
              },
              component: InterfaceWidgetTextArea,
            },
            {
              componentProps: {
                label: "Possible class names (comma-separated)",
                placeholder: "Enter the classes",
              },
              component: InterfaceWidgetTextArea,
            },
            {
              componentProps: {
                label: "Allow multiple true classes",
              },
              component: InterfaceWidgetCheckbox,
            },
          ],
          componentMapper: (values) => {
            return {
              inputs: values[0] ?? "",
              parameters: {
                candidate_labels: (values[1] as string).split(/, |,+/) ?? [],
                multi_class: values[2] ?? false,
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a text"),
            1: Yup.string().required(
              "You need to enter at least one class name"
            ),
          }),
        },
        defaultParams: {
          0: "I have a problem with my iphone that needs to be resolved asap!!",
          1: "urgent, not urgent, phone, tablet, computer",
          2: true,
        },
      };
      break;
    case iWidgetModelTypesEnum.SENTENCE_SIMILARITY:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder: "Enter the source sentence",
                label: "Source Sentence",
              },
              component: InterfaceWidgetTextArea,
            },
            {
              componentProps: {
                placeholder: "Your sentence here ...",
                label: "Sentences to compare to",
              },
              component: InterfaceWidgetDynamicTexts,
            },
          ],
          componentMapper: (values) => {
            return {
              inputs: {
                source_sentence: values[0] ?? "",
                sentences: Object.values(values[1] as Record<number, string>),
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a source sentence"),
            1: Yup.lazy((obj) =>
              Yup.object(
                mapValues(obj, (value, key) => {
                  return Yup.string().required(
                    "You need to enter a comparison sentence"
                  );
                })
              )
            ),
          }),
        },
        defaultParams: {
          0: "That is a happy person",
          1: {
            0: "That is a happy dog",
            1: "That is a very happy person",
            2: "Today is a sunny day",
          },
        },
      };
      break;
    case iWidgetModelTypesEnum.IMAGE_CLASSIFICATION:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder:
                  "Drop image file here or click to browse from your device",
              },
              component: InterfaceWidgetImageDropzone,
            },
          ],
          componentMapper: (values: any) => {
            return { inputs: values[0] ?? "" };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to upload an image file"),
          }),
        },
        defaultParams: {},
      };
      break;
    case iWidgetModelTypesEnum.TABLE_QUESTION_ANSWERING:
      result = {
        input: {
          components: [
            {
              componentProps: {
                placeholder:
                  "How many stars does the transformers repository have?",
                label: "Question",
              },
              component: InterfaceWidgetTextArea,
            },
            {
              componentProps: {},
              component: InterfaceWidgetEditableTable,
            },
          ],
          componentMapper: (values) => {
            const mappedTable: Record<string, string[]> = {};
            const tableValue: string[][] = (values[1] as string[][]) ?? [[]];
            tableValue[0].forEach((header, index) => {
              const array: string[] = [];
              for (
                let innerIndex = 1;
                innerIndex < tableValue.length;
                innerIndex++
              ) {
                array.push(tableValue[innerIndex][index]);
              }
              mappedTable[header] = array;
            });

            return {
              inputs: {
                query: values[0] ?? "",
                table: mappedTable,
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.string().required("You need to enter a question"),
            1: Yup.array().of(
              Yup.array().of(
                Yup.string().required("All table fields should be fileld")
              )
            ),
          }),
        },
        defaultParams: {
          0: "How many stars does the transformers repository have?",
          1: [
            ["Repository", "Stars", "Contributors", "Programming language"],
            ["Transformers", "36543", "651", "Python"],
            ["Datasets", "4512", "77", "Python"],
            ["Tokenizers", "3934", "25", "Rust, Python and NodeJS"],
          ],
        },
      };
      break;
    case iWidgetModelTypesEnum.TABULAR_CLASSIFICATION:
      result = {
        input: {
          components: [
            {
              componentProps: {},
              component: InterfaceWidgetEditableTable,
            },
          ],
          componentMapper: (values) => {
            const mappedTable: Record<string, string[]> = {};
            const tableValue: string[][] = (values[0] as string[][]) ?? [[]];
            tableValue[0].forEach((header, index) => {
              const array: string[] = [];
              for (
                let innerIndex = 1;
                innerIndex < tableValue.length;
                innerIndex++
              ) {
                array.push(tableValue[innerIndex][index]);
              }
              mappedTable[header] = array;
            });

            return {
              inputs: {
                data: mappedTable,
              },
            };
          },
          formValidationSchema: Yup.object().shape({
            0: Yup.array().of(
              Yup.array().of(
                Yup.string().required("All table fields should be fileld")
              )
            ),
          }),
        },
        defaultParams: {
          0: [
            [
              "fixed_acidity",
              "volatile_acidity",
              "citric_acid",
              "residual_sugar",
            ],
            ["7.4", "0.7", "0", "1.9"],
            ["7.8", "0.88", "0", "2.6"],
            ["10.3", "0.32", "0.45", "6.4"],
          ],
        },
      };
      break;
  }

  return result;
};

/**
 * Maps the model classification to output components for the {@link InterfaceWidget | 'InterfaceWidget'}
 * @param modelType - model classification
 * @param results - depending on the classification, result variables of the inference API
 * @param inputs - depending on the classification, models input parameters
 * @returns an object, containing and array of output components and their configurations
 * @category Logic
 */
export const modelTypeToOutput = async (
  modelType: iWidgetModelTypesEnum,
  results: any,
  inputValue: any
): Promise<iWidgetOutputParams> => {
  let result: iWidgetOutputParams = { outputFailed: false };
  switch (modelType) {
    case iWidgetModelTypesEnum.TEXT_GENERATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TEXT_GENERATION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value[0]?.generated_text,
                label: "Results",
                placeholder: "No value was provided",
                inputValue: inputValue[0] ?? "",
              },
              component: InterfaceWidgetTextAreaOut,
            },
          ],
        } as iWidgetNestedParamsOutput;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.FEATURE_EXTRACTION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.FEATURE_EXTRACTION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: {
                  values: value,
                },
                label: "Results",
                placeholder: "No values are available",
              },
              component: InterfaceWidgetTable,
            },
          ],
        } as iWidgetNestedParamsOutput<iWidgetTableValue>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.TEXT_CLASSIFICATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TEXT_CLASSIFICATION
        ].validate(results);
        result.output = {
          components: value.map((item) => {
            return {
              componentProps: {
                value: { score: item.score },
                label: item.label,
                placeholder: "No values are available",
              },
              component: InterfaceWidgetScore,
            };
          }),
        } as iWidgetNestedParamsOutput<iWidgetScoreValue>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.FILL_MASK:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.FILL_MASK
        ].validate(results);
        result.output = {
          components: value.map((item) => {
            return {
              componentProps: {
                value: { score: item.score },
                label: item.token_str,
                placeholder: "No values are available",
              },
              component: InterfaceWidgetScore,
            };
          }),
        } as iWidgetNestedParamsOutput<iWidgetScoreValue>;
      } catch {
        result.outputFailed = true;
      }

      break;
    case iWidgetModelTypesEnum.TOKEN_CLASSIFICATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TOKEN_CLASSIFICATION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value.map((obj) => {
                  return {
                    entity: obj.entity,
                    score: obj.score,
                    word: obj.word,
                    start: obj.start,
                    end: obj.end,
                  };
                }),
                inputValue: inputValue[0] ?? "",
                placeholder: "No values are available",
              },
              component: InterfaceWidgetTokenHighlight,
            },
          ],
        };
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.QUESTION_ANSWERING:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.QUESTION_ANSWERING
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                label: "Answer",
                placeholder: "No answer is available",
                value: {
                  text: value.answer,
                  score: value.score,
                },
              },
              component: InterfaceWidgetScoreText,
            },
          ],
        };
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.TRANSLATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TRANSLATION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value[0]?.translation_text,
                label: "Results",
                placeholder: "No value was provided",
              },
              component: InterfaceWidgetTextAreaOut,
            },
          ],
        } as iWidgetNestedParamsOutput;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.SUMMARIZATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.SUMMARIZATION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value[0]?.summary_text ?? undefined,
                label: "Results",
                placeholder: "No value was provided",
              },
              component: InterfaceWidgetTextAreaOut,
            },
          ],
        } as iWidgetNestedParamsOutput;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.TEXT2TEXT_GENERATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TEXT2TEXT_GENERATION
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value[0]?.generated_text,
                label: "Results",
                placeholder: "No value was provided",
                inputValue: inputValue[0] ?? "",
              },
              component: InterfaceWidgetTextAreaOut,
            },
          ],
        } as iWidgetNestedParamsOutput;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.TEXT_TO_IMAGE:
      result.outputFailed = true;
      break;
    case iWidgetModelTypesEnum.TEXT_TO_SPEECH:
      result.outputFailed = true;
      break;
    case iWidgetModelTypesEnum.CONVERSATIONAL:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.CONVERSATIONAL
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: {
                  conversation: {
                    generatedResponses: value.conversation.generated_responses,
                    pastUserInputs: value.conversation.past_user_inputs,
                  },
                },
              },
              component: InterfaceWidgetConversation,
            },
          ],
        } as iWidgetNestedParamsOutput<iWidgetConversationProps>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.ZERO_SHOT_CLASSIFICATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.ZERO_SHOT_CLASSIFICATION
        ].validate(results);
        result.output = {
          components: value.scores.map((item, index) => {
            return {
              componentProps: {
                value: { score: item },
                label: value.labels[index] ?? "No label",
                placeholder: "No values are available",
              },
              component: InterfaceWidgetScore,
            };
          }),
        } as iWidgetNestedParamsOutput<iWidgetScoreValue>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.SENTENCE_SIMILARITY:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.SENTENCE_SIMILARITY
        ].validate(results);
        result.output = {
          components: value.map((item, index) => {
            return {
              componentProps: {
                value: { score: item },
                label: inputValue[1][index] ?? "No label",
                placeholder: "No values are available",
              },
              component: InterfaceWidgetScore,
            };
          }),
        } as iWidgetNestedParamsOutput<iWidgetScoreValue>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.IMAGE_CLASSIFICATION:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.IMAGE_CLASSIFICATION
        ].validate(results);
        result.output = {
          components: value.map((item) => {
            return {
              componentProps: {
                value: { score: item.score },
                label: item.label,
                placeholder: "No values are available",
              },
              component: InterfaceWidgetScore,
            };
          }),
        } as iWidgetNestedParamsOutput<iWidgetScoreValue>;
      } catch {
        result.outputFailed = true;
      }
      break;
    case iWidgetModelTypesEnum.TABLE_QUESTION_ANSWERING:
      try {
        const value = await outputSchemas[
          iWidgetModelTypesEnum.TABLE_QUESTION_ANSWERING
        ].validate(results);
        result.output = {
          components: [
            {
              componentProps: {
                value: value.answer,
                label: "Answer",
                placeholder: "No value was provided",
              },
              component: InterfaceWidgetTextAreaOut,
            },
          ],
        } as iWidgetNestedParamsOutput;
      } catch {
        result.outputFailed = true;
      }
      break;
  }
  if (
    results !== undefined &&
    results.status === undefined &&
    result.outputFailed
  ) {
    result = {
      ...result,
      output: {
        components: [
          {
            componentProps: {
              value: JSON.stringify(results),
              label: "JSON Output",
              placeholder: "No avaliable JSON",
            },
            component: InterfaceWidgetJson,
          },
        ],
      },
      outputFailed: true,
    };
  }

  return result;
};
