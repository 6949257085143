import { useEffect } from "react";
import useAxios from "axios-hooks";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import { FieldArray, Form, Formik } from "formik";
import {
  editCollaborationFormProps,
  EditCollaborationProps,
} from "./editCollaborationTypes";
import { PlusIcon, TrashIcon, UserIcon } from "@heroicons/react/solid";
import { OfficeBuildingIcon } from "@heroicons/react/solid";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { organizations } from "api/apiTypes";

const MODEL_ROLE_OPTIONS = ["owner", "admin", "maintainer", "user"];
const MODEL_ROLE_DEFAULT = "user";

/**
 * Renders a modal for organization edit
 *
 * @category Component
 */
export function EditMember({ model, onUpdate }: EditCollaborationProps) {
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, execute] = useAxios(
    { url: `models/${model.org_or_user_owner}/${model.name}`, method: "PATCH" },
    { manual: true }
  );

  const [{ loading, data: orgs }, fetchOrgs] = useAxios<organizations>(
    {},
    { manual: true }
  );

  useEffect(() => {
    fetchOrgs({ url: `orgs/` });
  }, [fetchOrgs]);

  const initialValues: editCollaborationFormProps = {
    collaborators: model.collaborations
      .map(({ account, model_role }) => ({
        role: model_role,
        username: account?.username,
        fullName: account?.full_name,
        isOrganization: account?.is_organization,
      }))
      .sort(
        (a, b) =>
          MODEL_ROLE_OPTIONS.indexOf(a.role) -
          MODEL_ROLE_OPTIONS.indexOf(b.role)
      ),
  };

  const handleSubmit = async (values: editCollaborationFormProps) => {
    const collaborators = values.collaborators.filter((c) =>
      c.isOrganization ? true : c.username.length === 5
    );
    const added = collaborators.filter(
      (collaborator) =>
        !model.collaborations.some(
          (modelColl) =>
            modelColl.model_role === collaborator.role &&
            modelColl.account.username === collaborator.username &&
            modelColl.account.is_organization === collaborator.isOrganization
        )
    );
    const deleted = model.collaborations.filter(
      (modelColl) =>
        !collaborators.some(
          (collaborator) => modelColl.account.username === collaborator.username
        )
    );
    try {
      await execute({
        data: {
          add_user_name: added
            .filter((coll) => !coll.isOrganization)
            .map((coll) => ({ [coll.username]: coll.role })),
          remove_user_name: deleted
            .filter((coll) => !coll.account.is_organization)
            .map((coll) => coll.account.username),
          add_org_name: added
            .filter((coll) => coll.isOrganization)
            .map((coll) => ({ [coll.username]: coll.role })),
          remove_org_name: deleted
            .filter((coll) => coll.account.is_organization)
            .map((coll) => coll.account.username),
        },
      });
      setNotification({
        label: `Model was updated successfully`,
        status: notificationStatusEnum.SUCCESS,
      });
    } finally {
      onUpdate();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, isSubmitting }) => {
        return (
          <Form className="w-full">
            <div className="mx-auto bg-gray-50 shadow-sm rounded-md p-4 border md:w-2/3">
              <p className="font-semibold text-sm">Manage Contributor</p>
              <div className="grid grid-cols-1 gap-4 mt-2">
                {values.collaborators && values.collaborators.length > 0 && (
                  <FieldArray
                    name="collaborators"
                    render={(arrayHelpers) => (
                      <div className="flex flex-col gap-4">
                        {values.collaborators.map((collaborator, index) => (
                          <div
                            key={`collaborator-${index}`}
                            className="flex gap-2"
                          >
                            <Dropdown
                              className="w-14 h-10 mr-0"
                              placeholder="Select model task"
                              style={accentStyleEnum.SIMPLE}
                              value={
                                collaborator.isOrganization
                                  ? {
                                      value: "Organization",
                                      label: (
                                        <OfficeBuildingIcon className="h-4 w-4" />
                                      ),
                                    }
                                  : {
                                      value: "Individual",
                                      label: <UserIcon className="h-4 w-4" />,
                                    }
                              }
                              options={["Individual", "Organization"].map(
                                (o) => ({
                                  value: o,
                                  label: o,
                                })
                              )}
                              onChange={(value) => {
                                arrayHelpers.replace(index, {
                                  ...collaborator,
                                  username: "",
                                  isOrganization:
                                    value.value === "Organization",
                                });
                              }}
                              disabled={
                                collaborator.role === "owner" &&
                                collaborator.username ===
                                  model.org_or_user_owner
                              }
                            />
                            {collaborator.isOrganization ? (
                              <Input
                                name={`collaborators.${index}.username`}
                                as="select"
                                disabled={
                                  collaborator.role === "owner" &&
                                  collaborator.username ===
                                    model.org_or_user_owner
                                }
                              >
                                <option hidden disabled value={""}></option>
                                {orgs?.map((org) => {
                                  return (
                                    <option
                                      key={org.username}
                                      value={org.username}
                                    >
                                      {org.full_name}
                                    </option>
                                  );
                                })}
                              </Input>
                            ) : (
                              <Input
                                placeholder="User name"
                                name={`collaborators.${index}.username`}
                                disabled={
                                  collaborator.role === "owner" &&
                                  collaborator.username ===
                                    model.org_or_user_owner
                                }
                              ></Input>
                            )}
                            <Dropdown
                              className="min-w-[120px] h-10 mr-0"
                              placeholder="Select model task"
                              style={accentStyleEnum.SIMPLE}
                              value={{
                                value: collaborator.role,
                                label: collaborator.role,
                              }}
                              options={MODEL_ROLE_OPTIONS.map((value) => {
                                return { label: value, value };
                              })}
                              onChange={(value) => {
                                arrayHelpers.replace(index, {
                                  ...collaborator,
                                  role: value.value,
                                });
                              }}
                              disabled={
                                collaborator.role === "owner" &&
                                collaborator.username ===
                                  model.org_or_user_owner
                              }
                            />
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              style={accentStyleEnum.SALMON}
                              disabled={
                                collaborator.role === "owner" &&
                                collaborator.username ===
                                  model.org_or_user_owner
                              }
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                        <Button
                          type="button"
                          className="w-full"
                          onClick={() =>
                            arrayHelpers.insert(values.collaborators.length, {
                              username: "",
                              role: MODEL_ROLE_DEFAULT,
                              isOrganization: false,
                            })
                          }
                          style={accentStyleEnum.VIOLET}
                        >
                          <PlusIcon className="h-4 w-4" />
                        </Button>
                      </div>
                    )}
                  />
                )}
              </div>
              <Button
                type="submit"
                className="h-10 mt-3"
                style={accentStyleEnum.SIMPLE}
                loading={isSubmitting}
                disabled={
                  isSubmitting ||
                  (values.collaborators.filter((c) => !!c.username).length ===
                    model.collaborations.length &&
                    values.collaborators
                      .filter((c) => !!c.username)
                      .every((collaborator) =>
                        model.collaborations.some(
                          (modelColl) =>
                            modelColl.account.username ===
                              collaborator.username &&
                            modelColl.model_role === collaborator.role
                        )
                      ))
                }
              >
                Update Contributor
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
