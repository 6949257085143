import { UserIcon } from "@heroicons/react/solid";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { AvatarProps } from "./avatarTypes";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { useEffect } from "react";
import { useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { meScopes } from "components/Auth/authConfig";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import axios from "axios";

/**
 * Renders an avatar with the user picture
 * If `ownerEmail` is provided, fetches an appropriate user profile picture from Azure
 * ```tsx
 * <Avatar ownerEmail={email} size="sm"/>
 * ```
 *
 * If profile picture is not available, uses a generic profile picture
 * ```tsx
 * <Avatar size="sm"/>
 * ```
 * @category Atom
 */
export function Avatar({ ownerEmail, size, onClick }: AvatarProps) {
  const [user] = useRecoilState(userAtom);
  const [userPhoto, setUserPhoto] = useState<File | undefined>();
  const { instance } = useMsal();
  const account = useAccount();
  const [, setNotification] = useRecoilState(notificationAtom);

  const fetchAvatar = useCallback(
    async (email: string) => {
      try {
        const token = await instance.acquireTokenSilent({
          scopes: meScopes,
          account: account ?? undefined,
        });

        const { data } = await axios.get(
          `https://graph.microsoft.com/beta/users/${email}/photo/$value`,
          {
            responseType: "blob",
            headers: {
              Authorization: `${token.tokenType} ${token.accessToken}`,
            },
          }
        );
        return data;
      } catch {
        setNotification({
          label: `Could not fetch the avatar`,
          status: notificationStatusEnum.ATTENTION,
        });
      }
    },
    [account, instance, setNotification]
  );

  useEffect(() => {
    if (ownerEmail) {
      fetchAvatar(ownerEmail).then((data) => {
        if (data) {
          setUserPhoto(data);
        } else {
          setUserPhoto(undefined);
        }
      });
    } else {
      setUserPhoto(user.photo);
    }
  }, [fetchAvatar, ownerEmail, user.photo]);

  return (
    <div
      className={`rounded-full ${size === "sm" && "w-7 h-7"} ${
        size === "xl" && "w-52 h-52"
      }  ${onClick && "cursor-pointer"}`}
      onClick={onClick}
    >
      {userPhoto ? (
        <img
          className="w-full h-full rounded-full object-cover"
          src={URL.createObjectURL(userPhoto)}
          alt="Profile avatar"
        ></img>
      ) : (
        <div
          className={`${size === "sm" && "p-1"} ${size === "xl" && "p-6"} ${
            accentStyles[accentStyleEnum.SALMON].bgColor
          } ${
            accentStyles[accentStyleEnum.SALMON].textColorContrast
          } rounded-full`}
        >
          <UserIcon className="h-full w-full" />
        </div>
      )}
    </div>
  );
}
