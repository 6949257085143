import SyntaxHighlighter from "react-syntax-highlighter";
import { arduinoLight } from "react-syntax-highlighter/dist/esm/styles/hljs";

function CodeCard({ label, code }: { label: string; code: string }) {
  return (
    <div>
      <p className="my-2 text-center text-white font-semibold text-lg">
        {label}
      </p>
      <div className="rounded-md">
        <SyntaxHighlighter
          className="rounded-md"
          language={"python"}
          style={arduinoLight}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}
export function GetStarted() {
  return (
    <div className="pt-40">
      <div className="pt-6 bg-gradient-to-r from-deepmind-between to-deepmind-green text-white">
        <p className="text-center mt-2 text-3xl font-bold leading-8 tracking-tight sm:text-4xl pb-8 text-white">
          How to get started
        </p>
        <div className=" pb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 container mx-auto lg:w-2/3">
            <div className="text-left space-y-2">
              <h2 className="text-2xl font-semibold pt-8">
                Load ML Models Instantly
              </h2>
              <h2 className="h-24">
                Deepmind&apos;s PythonAPI allows you to load transformer models
                instantly to your jupyter notebook on S@S or even your local PC.
              </h2>
              <h2 className="text-2xl font-semibold">
                Version Control Models and Data
              </h2>
              <h2>
                DeepMind extends git and combines it with dvc enabling large
                model version control, experimentation and team collaboration in
                one place, from your local setup, Sagemaker or S@S.
              </h2>
            </div>
            <div className="container mx-auto ml-6">
              <CodeCard
                label="Load models"
                code={`from deepmind_api.dm_api import DmApi\ndm_instance = DmApi()\nmodel = dm_instance.from_pretrained_model('hermione/distilbert-pico')`}
              />
              <CodeCard
                label="Version control"
                code={`# import Repository module from deepmind_api.dm_api import Repository
# You could either clone from an existing repo
repo = Repository(local_dir = '../hermione/distilbert-pico', clone_from='/hermione/distilbert-pico') 
repo = Repository(local_dir = '../hermione/distilbert-pico') 
# after adding files or making changes 
repo.git_dvc_add() 
repo.git_dvc_commit(commit_msg='added bert model')
repo.git_dvc_push()`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
