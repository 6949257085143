import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { overrideTailwindClasses } from "tailwind-override";
import { BreacrumbsProps, BreadcrumbLinkProps } from "./breadcrumbsTypes";

function BreadcrumbLink({ to, label, className }: BreadcrumbLinkProps) {
  const location = useLocation();

  const matchResult = useMemo(() => to === location.pathname, [to, location]);

  return (
    <div>
      {matchResult ? (
        <span className={overrideTailwindClasses(`font-medium ${className}`)}>
          {label}
        </span>
      ) : (
        <Link
          to={to}
          className={overrideTailwindClasses(
            `hover:underline font-normal text-blue-600 ${className}`
          )}
        >
          {label}
        </Link>
      )}
    </div>
  );
}

/**
 * Renders a breadcrumbs component
 * ```tsx
 * <Breadcrumbs
 *  basePath="/long/and/complicated/base/path"
 *  includeBasePath
 *  basePathAbrv="short-path"
 *  breadcumbsPath="bread/crumbs"
 * />
 * ```
 * @category Atom
 */
export function Breadcrumbs({
  basePath,
  includeBasePath = false,
  basePathAbrv,
  breadcrumbsPath,
}: BreacrumbsProps) {
  return (
    <div className="flex">
      {includeBasePath && (
        <BreadcrumbLink
          to={basePath}
          label={basePathAbrv ?? basePath}
          className="font-medium"
        />
      )}
      {breadcrumbsPath &&
        breadcrumbsPath
          .split("/")
          .reduce<{
            previousPath: string;
            layers: Array<{ path: string; fullPath: string }>;
          }>(
            (acc, cur) => ({
              previousPath: acc.previousPath + "/" + cur,
              layers: [
                ...acc.layers,
                {
                  path: cur,
                  fullPath: acc.previousPath + "/" + cur,
                },
              ],
            }),
            { previousPath: "", layers: [] }
          )
          .layers.map(({ path, fullPath }, index: number) => (
            <div className="flex" key={`file-link-${path}-${index}`}>
              <span className="mx-1">/</span>
              <BreadcrumbLink to={`${basePath}${fullPath}`} label={path} />
            </div>
          ))}
    </div>
  );
}
