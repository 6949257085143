import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Mermaid } from "mdx-mermaid/lib/Mermaid";
import { MarkdownProps } from "./markdownTypes";
import "./markdown.css";

/**
 * Renders a formated markdown content
 * @category Atom
 */
export function Markdown({ content }: MarkdownProps) {
  const metaRegExp = RegExp(/^---[\r\n](((?!---).|[\r\n])*)[\r\n]---$/m);

  return (
    <ReactMarkdown
      className="rounded-md markdown-body p-4"
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return inline ? (
            <code className={className} {...props}>
              {children}
            </code>
          ) : match ? (
            match[1] === "mermaid" ? (
              <Mermaid chart={String(children)} />
            ) : (
              <SyntaxHighlighter
                className="rounded-md"
                style={darcula}
                language={match[1]}
                PreTag="div"
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            )
          ) : (
            <div className="rounded-md p-2 block overflow-x-auto bg-[#2b2b2b] text-[#bababa]">
              <code {...props}>{children}</code>
            </div>
          );
        },
      }}
    >
      {content.replace(metaRegExp, "")}
    </ReactMarkdown>
  );
}
