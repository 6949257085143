import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { iWidgetStatusEnum, IWidgetStatusProps } from "./interfaceWidgetTypes";

/**
 * Renders a status markup for {@link InterfaceWidget | 'InterfaceWidget'}
 *
 * @category Atom
 */
export function InterfaceWidgetStatus({ status, loading }: IWidgetStatusProps) {
  return (
    <div className="text-sm text-slate-600">
      {loading ? (
        <div className="flex gap-2 items-center">
          <svg
            className={`animate-spin h-4 w-4 text-gray-400 `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Sagemaker pending
        </div>
      ) : (
        <>
          {status === iWidgetStatusEnum.READY && (
            <div className="flex gap-2 items-center">
              <CheckCircleIcon className="w-4 h-4 text-green-400" />
              Sagemaker ready
            </div>
          )}
          {status === iWidgetStatusEnum.STANDBY && (
            <div className="flex gap-2 items-center">
              <ExclamationCircleIcon className="w-4 h-4 text-yellow-400" />
              Sagemaker on standby
            </div>
          )}
        </>
      )}
    </div>
  );
}
