import logo from "assets/logo/logo_raw_min.png";
import { SVGProps } from "react";

const navigation = {
  documentation: [
    {
      name: "Backend Documentation",
      href: "https://docs.int.bayer.com/deepmind/",
    },
    { name: "API ReDoc", href: "https://dev.api.deepmind.int.bayer.com/redoc" },
    {
      name: "Frontend Documentation",
      href: "https://docs.int.bayer.com/deepmind-frontend/",
    },
  ],
  links: [
    {
      name: "GitHub",
      href: "https://github.com/bayer-int/MAPV-Deepmind-Frontend",
      icon: (props: SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

/**
 * Renders application's footer
 *
 * @category Component
 */
export function Footer() {
  return (
    <footer className="bg-gradient-to-t from-slate-100 to-white shadow w-full border-t">
      <div className="mx-auto max-w-7xl overflow-hidden py-8 px-4 sm:px-6 lg:px-8">
        <div className="md:grid md:grid-cols-2">
          <div>
            <nav className="-my-2 flex flex-wrap" aria-label="Footer">
              {navigation.documentation.map((item) => (
                <div key={item.name} className="px-5 py-2">
                  <a
                    href={item.href}
                    className="text-base text-gray-500 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                </div>
              ))}
            </nav>
            <div className="ml-5 mt-2 flex space-x-6">
              {navigation.links.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
              <p className="text-center text-base text-gray-400">
                &copy; 2022 Bayer, Inc. All rights reserved.
              </p>
            </div>
          </div>
          <div className="space-y-2 xl:max-w-lg xl:ml-auto xl:col-span-1">
            <p className="text-right text-gray-700">
              DeepMind was developed by DS&I under MAPV at Bayer
            </p>
            <p className="text-right text-gray-700">
              Contact
              <a
                href="mailto:hooman.sedghamiz@bayer.com"
                className="text-cadet-600 mx-1"
              >
                Hooman Sedghamiz
              </a>
              (project lead) with ideas and suggestions.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
