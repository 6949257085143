/**
 * Transforms a date to a format `MONTH-DAY-YEAR`
 * @param date - date to transform
 * @returns human readable text date
 * @category Logic
 */
export const dateToHumanText = (date: Date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()} ${date.getFullYear()}`;
};

/**
 * Gets a random text color class
 * @param exludeColor - class to exclude from randomization
 * @returns a randomized text color class
 */
export const getRandomTextAccentColor = (exludeColor = ""): string => {
  const maintextColorContrasts = [
    "text-violet-200",
    "text-yellow-500",
    "text-salmon-800",
    "text-cadet-500",
  ].filter((color) => color !== exludeColor);

  return maintextColorContrasts[
    Math.floor(Math.random() * maintextColorContrasts.length)
  ];
};

/**
 * Gets a random background color class
 * @param exludeColor - list of classes to exclude from randomization
 * @returns a randomized background color class
 */
export const getRandomBgAccentColor = (exludeColors: string[] = []): string => {
  const maintextColorContrasts = [
    "bg-violet-400",
    "bg-yellow-500",
    "bg-orange-500",
    "bg-cadet-500",
    "bg-red-400",
    "bg-blue-500",
    "bg-green-400",
    "bg-teal-500",
  ].filter((color) => !exludeColors.includes(color));

  return maintextColorContrasts[
    Math.floor(Math.random() * maintextColorContrasts.length)
  ];
};

/**
 * Transforms a number of bytes to human readable string
 * @param bytes - amount of bytes to transform
 * @returns human readable string
 */
export function bytesToHuman(bytes: number) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let unitStep = 0;
  let bytesCounter = bytes;
  while (bytesCounter >= 1024 && ++unitStep) {
    bytesCounter = bytesCounter / 1024;
  }
  return (
    bytesCounter.toFixed(bytesCounter < 10 && unitStep > 0 ? 1 : 0) +
    " " +
    units[unitStep]
  );
}

/**
 * Converts time to local time
 *
 * WANING: this funciton has a side-effect of modify the original/input date
 *
 * @param time - time to convert
 * @returns local time
 */
export function toLocalTime(time: Date) {
  const offset = new Date().getTimezoneOffset();
  const utcTime = time;
  utcTime.setMinutes(utcTime.getMinutes() - offset);
  return utcTime;
}

/**
 * Removes keys from object
 * @param object - object to modify
 * @param removalKeys - a list of keys to remove
 * @returns a stripdowned object
 */
export const removeObjectKey = (
  object: Record<string, any>,
  removalKeys: string[]
) => {
  const entries = Object.entries(object).filter(
    ([key, entry]) => !removalKeys.includes(key)
  );

  if (entries) {
    return Object.fromEntries(new Map(entries));
  } else {
    return [];
  }
};
