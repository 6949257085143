import { useFormikContext } from "formik";
import { genericFormProps } from "genericTypes";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders a dropzone input component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetImageDropzone(props: IWidgetNestedProps) {
  const { setFieldValue } = useFormikContext<genericFormProps>();
  const [image, setImage] = useState<string>();
  const { disabled, name, label, placeholder } = props;

  const handleLoad = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFieldValue(name, reader.result, true);
      setImage(URL.createObjectURL(files[0]));
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      {label && <span className="ml-2 text-sm text-gray-600">{label}</span>}
      <Dropzone
        maxSize={5000000}
        onDrop={handleLoad}
        multiple={false}
        disabled={disabled}
        accept={"image/*"}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section>
            <div
              {...getRootProps()}
              className={`rounded-lg border-2 border-dashed border-slate-500 min-h-[64px] w-full p-4 mt-2 flex flex-col gap-4 text-sm justify-center items-center ${
                isDragActive ? "text-slate-700 bg-slate-100" : "text-slate-500"
              } ${disabled && "cursor-not-allowed"}`}
            >
              <input {...getInputProps()} />
              {image ? (
                <img
                  src={image}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(image);
                  }}
                />
              ) : (
                <div>
                  <p className="text-center">{placeholder}</p>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}
