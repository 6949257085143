import { inferenceToken } from "api/apiTypes";
import { useMemo } from "react";
import { getRandomBgAccentColor } from "utils";
import { IWidgetNestedCustomOutputProps } from "../interfaceWidgetTypes";

function HighlightTab({
  text,
  category,
  bgClass,
}: {
  text: string;
  category: string;
  bgClass: string;
}) {
  return (
    <span
      className={`rounded-md px-1.5 inline-flex gap-1 w-fit my-0.5 items-center ${bgClass} bg-opacity-40`}
    >
      <span>{text}</span>
      <span
        className={`rounded-md px-1 text-xs font-semibold ${bgClass} text-white`}
      >
        {category}
      </span>
    </span>
  );
}

/**
 * Renders a token highlight output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetTokenHighlight(
  props: IWidgetNestedCustomOutputProps<inferenceToken[]>
) {
  const { label, placeholder, value, inputValue } = props;
  const colorMap = useMemo(() => {
    if (value) {
      const colorMapLoc: { [key: string]: string } = {};
      value.forEach((value) => {
        if (!colorMapLoc[value.entity]) {
          colorMapLoc[value.entity] = getRandomBgAccentColor(
            Object.values(colorMapLoc)
          );
        }
      });
      return colorMapLoc;
    }
  }, [value]);
  const splitText = useMemo(() => {
    if (value && inputValue && colorMap) {
      const textNodes: React.ReactNode[] = [];
      let highlightTabKey = 0;

      const entities = value;

      let textAccumulator = inputValue as string;
      let skipAccumulator = 0;
      entities.forEach((entity) => {
        textNodes.push(
          textAccumulator.slice(0, Math.max(entity.start - skipAccumulator, 0))
        );

        textAccumulator = textAccumulator.slice(
          Math.max(entity.end - skipAccumulator, 0)
        );
        skipAccumulator = inputValue.length - textAccumulator.length;

        const tabText = inputValue.slice(entity.start, entity.end);
        textNodes.push(
          <HighlightTab
            key={highlightTabKey}
            text={tabText}
            category={entity.entity}
            bgClass={colorMap[entity.entity]}
          />
        );
        highlightTabKey++;
      });
      textNodes.push(textAccumulator);

      return <div>{textNodes}</div>;
    }
  }, [value, inputValue, colorMap]);

  if (!value) {
    return (
      <div className="text-sm font-medium text-gray-500">
        {placeholder ? placeholder : "No value was provided"}
      </div>
    );
  }
  if (!splitText) {
    return (
      <div className="text-sm font-medium text-gray-500">
        Values could not be mapped
      </div>
    );
  }
  return (
    <div data-testid="widget-tokenhighlight-output">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <div className="bg-white p-4 rounded-sm">{splitText}</div>
    </div>
  );
}
