/**
 * Enum type for generic component styling
 * @category Type
 * @enum
 */
export enum accentStyleEnum {
  SIMPLE = "SIMPLE",
  SIMPLE_ALT = "SIMPLE_ALT",
  VIOLET = "VIOLET",
  YELLOW = "YELLOW",
  SALMON = "SALMON",
  CADET = "CADET",
  GRAY = "GRAY",
  GREEN = "GREEN",
}

/**
 * Type for specific style classes
 * @category Type
 */
export type accentStyle = {
  gradientTo: string;
  gradientFrom: string;
  gradientToToned: string;
  gradientFromToned: string;
  bgColor: string;
  textColor: string;
  textColorContrast: string;
  borderColor?: string;
};

/**
 * Generic accent styles
 */
export const accentStyles: { [key: string]: accentStyle } = {
  [accentStyleEnum.SIMPLE]: {
    gradientTo: "to-gray-200",
    gradientFrom: "from-white",
    gradientToToned: "to-gray-300",
    gradientFromToned: "from-gray-300",
    bgColor: "bg-gray-200",
    textColor: "text-gray-200",
    textColorContrast: "text-black",
    borderColor: "gray-300",
  },
  [accentStyleEnum.SIMPLE_ALT]: {
    gradientTo: "to-gray-100",
    gradientFrom: "from-gray-300",
    gradientToToned: "to-gray-300",
    gradientFromToned: "from-gray-300",
    bgColor: "bg-gray-200",
    textColor: "text-gray-200",
    textColorContrast: "text-black",
    borderColor: "gray-300",
  },
  [accentStyleEnum.GRAY]: {
    gradientTo: "to-gray-400",
    gradientFrom: "from-gray-400",
    gradientToToned: "to-gray-500",
    gradientFromToned: "from-gray-500",
    bgColor: "bg-gray-400",
    textColor: "text-gray-400",
    textColorContrast: "text-white",
  },
  [accentStyleEnum.VIOLET]: {
    gradientTo: "to-violet-100",
    gradientFrom: "from-violet-100",
    gradientToToned: "to-violet-200",
    gradientFromToned: "from-violet-200",
    bgColor: "bg-violet-200",
    textColor: "text-violet-200",
    textColorContrast: "text-black",
  },
  [accentStyleEnum.YELLOW]: {
    gradientTo: "to-yellow-400",
    gradientFrom: "from-yellow-400",
    gradientToToned: "to-yellow-500",
    gradientFromToned: "from-yellow-500",
    bgColor: "bg-yellow-500",
    textColor: "text-yellow-500",
    textColorContrast: "text-black",
  },
  [accentStyleEnum.SALMON]: {
    gradientTo: "to-salmon-700",
    gradientFrom: "from-salmon-700",
    gradientToToned: "to-salmon-800",
    gradientFromToned: "from-salmon-800",
    bgColor: "bg-salmon-800",
    textColor: "text-salmon-800",
    textColorContrast: "text-white",
  },
  [accentStyleEnum.CADET]: {
    gradientTo: "to-cadet-400",
    gradientFrom: "from-cadet-400",
    gradientToToned: "to-cadet-500",
    gradientFromToned: "from-cadet-500",
    bgColor: "bg-cadet-500",
    textColor: "text-cadet-500",
    textColorContrast: "text-white",
  },
  [accentStyleEnum.GREEN]: {
    gradientTo: "to-green-400",
    gradientFrom: "from-green-400",
    gradientToToned: "to-green-500",
    gradientFromToned: "from-green-500",
    bgColor: "bg-green-500",
    textColor: "text-green-500",
    textColorContrast: "text-white",
  },
};
