import { useEffect } from "react";
import { mapBeOrgsToFeOrgPlaques } from "api/apiHelpers";
import { organizations } from "api/apiTypes";
import { Loading } from "atoms/Loading/Loading";
import { OrganizationPlaque } from "atoms/OrganizationPlaque/OrganizationPlaque";
import useAxios from "axios-hooks";

/**
 * Renders and fetches a list of {@link OrganizationPlaque | 'OrgPlaque'}
 * @category Component
 */
export function OrganizationGrid() {
  const [{ loading, data: orgs }, fetchOrgs] = useAxios<organizations>(
    {},
    { manual: true }
  );

  useEffect(() => {
    fetchOrgs({
      url: `orgs/`,
    });
  }, [fetchOrgs]);

  if (!orgs || loading) {
    return (
      <div className="grow w-9/12 p-4 flex flex-col">
        <Loading />
      </div>
    );
  }
  return (
    <div className="grow w-9/12 p-4 flex flex-col">
      <div className="flex flex-col grow">
        <div className="grow grid gap-4 auto-rows-min grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3">
          {mapBeOrgsToFeOrgPlaques(orgs).map((org) => {
            return <OrganizationPlaque key={org.name} {...org} />;
          })}
        </div>
      </div>
    </div>
  );
}
