import { useParams } from "react-router-dom";

/**
 * Renders an error page
 *
 * @category Component
 */
export const Error = () => {
  const { code } = useParams();
  return (
    <div className="grow overflow-auto relative container mx-auto">
      <div className="w-2/3 mt-36">
        <p className="text-8xl font-bold mb-4">
          <span className="text-salmon-800">{code}</span> Server error
        </p>
        <p className="text-xl text-gray-250">
          Oops, something went wrong! If the issue persists, please reach out to{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-60"
            href="mailto:hendrik.hogertz@bayer.com"
          >
            Hendrik Hogertz
          </a>{" "}
          or{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-60"
            href="mailto:hooman.sedghamiz@bayer.com"
          >
            Hooman Sedghamiz
          </a>
        </p>
      </div>
    </div>
  );
};
