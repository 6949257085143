import useAxios from "axios-hooks";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import { Modal } from "atoms/Modal/Modal";
import { Form, Formik } from "formik";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  deleteOrganizationModalFormProps,
  DeleteOrganizationModalProps,
} from "./deleteOrganizationModalTypes";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";

/**
 * Renders a modal for organization deletion
 *
 * @category Component
 */
export function DeleteOrganizationModal({
  open,
  setOpen,
  username,
}: DeleteOrganizationModalProps) {
  const navigate = useNavigate();
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, execute] = useAxios(
    { url: `orgs/${username}`, method: "DELETE" },
    { manual: true }
  );

  const initialValuesDeletion: deleteOrganizationModalFormProps = {
    name: "",
  };

  const handleDeletionSubmit = async () => {
    try {
      await execute();
      setNotification({
        label: `Organization was deleted successfully`,
        status: notificationStatusEnum.SUCCESS,
      });
    } finally {
      navigate("/");
    }
  };

  return (
    <Modal className="max-w-2xl" open={open} setOpen={setOpen}>
      <Formik
        initialValues={initialValuesDeletion}
        onSubmit={handleDeletionSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className="w-full">
            <div className="">
              <div className="flex gap-4 items-center">
                <Input
                  name="name"
                  label="Enter organization name to delete the organization"
                  placeholder={username}
                  className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
                  disabled={isSubmitting}
                ></Input>
              </div>

              <Button
                type="submit"
                disabled={isSubmitting || values.name !== username}
                style={accentStyleEnum.SIMPLE}
                className="w-full mt-4 justify-center"
                loading={isSubmitting}
              >
                Delete organization
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
