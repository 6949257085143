import React from "react";
import useAxios from "axios-hooks";
import { Avatar } from "atoms/Avatar/Avatar";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import { Modal } from "atoms/Modal/Modal";
import { Form, Formik } from "formik";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { useNavigate } from "react-router-dom";
import {
  editProfileModalFormProps,
  EditProfileModalProps,
} from "./editProfileModalTypes";

/**
 * Renders a modal for profile edit
 *
 * @category Component
 */
export function EditProfileModal({
  open,
  setOpen,
  name,
  cwid,
  interests,
  organizations,
  email,
}: EditProfileModalProps) {
  const [user] = useRecoilState(userAtom);
  const navigate = useNavigate();

  const [, execute] = useAxios(
    { url: `users/${user.cwid}`, method: "PATCH" },
    { manual: true }
  );

  const initialValues: editProfileModalFormProps = {
    name: name,
    cwid: cwid,
    interests: interests || undefined,
    email: email,
    organizations: organizations?.join(" "),
  };

  const handleSubmit = async (values: editProfileModalFormProps) => {
    await execute({
      data: {
        full_name: values.name,
        email: values.email,
        research_interests: values.interests,
        add_org_name: [],
        remove_org_name: [],
        add_model_name: [],
        remove_model_name: [],
      },
    });
    setOpen(false);
    navigate(0);
  };

  return (
    <Modal className="max-w-2xl" open={open} setOpen={setOpen}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className="relative w-fit mx-auto mb-6">
            <Avatar size={"xl"} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Input id="name" name="name" label="Name" disabled />
            <Input id="cwid" name="cwid" label="CWID" disabled />
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex flex-col gap-2">
              <Input
                as="textarea"
                className="resize-none h-20"
                id="interests"
                name="interests"
                label="Research interests"
                placeholder="Research interests"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Input
                id="organizations"
                name="organizations"
                label="Organizations"
                placeholder="Organizations"
                disabled
              />
            </div>
          </div>
          <Button
            type="submit"
            className="w-full mt-4 justify-center"
            style={accentStyleEnum.SIMPLE}
          >
            Update account
          </Button>
        </Form>
      </Formik>
    </Modal>
  );
}
