import gitIcon from "assets/icons/git_icon.png";
import dvcIcon from "assets/icons/dvc_icon.png";
import handIcon from "assets/icons/handshake_icon.png";
import smileIcon from "assets/icons/smile_icon.png";
import sageIcon from "assets/icons/sage_icon.png";
import pythonIcon from "assets/icons/python_icon.png";

const infoCards = [
  {
    title: "Git-compatible",
    body: "Built on top of Git for easy tracking of history. Train and save your code and model with peace of mind knowing that you can always return to the previous version.",
    icon: gitIcon,
    iconAlt: "Git icon",
  },
  {
    title: "Efficient Experiments and storage",
    body: "ML models and datasets can end up being large in size. MAPV DeepMind leverages DVC to efficiently version control datasets and models on AWS S3, saving you the headache of storing large files locally.",
    icon: dvcIcon,
    iconAlt: "DVC icon",
  },
  {
    title: "Built for Collaboration",
    body: "MAPV DeepMind is built with collaboration in mind. It provides a dedicated model page for data scientists to describe their methodology, detail their results and share their references.",
    icon: handIcon,
    iconAlt: "Handshake icon",
  },
  {
    title: "Model Agnostic",
    body: "MAPV DeepMind is model agnostic. But it has native support for popular NLP libraries such as Huggingface.",
    icon: smileIcon,
    iconAlt: "Smile icon",
  },
  {
    title: "Real-time ML Inference",
    body: "MAPV DeepMind platform provides interactive inference experience using Deep Learning Containers (DLC), where you can experiment live with many models, or even deploy them.",
    icon: sageIcon,
    iconAlt: "Amazon sage icon",
  },
  {
    title: "Supporting many platforms",
    body: "Whether you prefer using native Python, Jupyter notebook or S@S, MAPV DeepMind provides CLI and a python package for easy integration of your project.",
    icon: pythonIcon,
    iconAlt: "Python icon",
  },
];

/**
 * Renders a table of home feature cards
 * @category Component
 */
export function FeatureCards() {
  return (
    <div className="pt-40">
      <div className="bg-gradient-to-r from-deepmind-green to-deepmind-between text-white">
        <div className="container mx-auto items-center text-center">
          <h2 className="pt-6 text-center container mx-auto lg:w-2/4 mt-2 text-3xl font-bold leading-8 tracking-tight sm:text-4xl text-white">
            Share, Collaborative, Deploy, Version Control and Experiment in One
            Platform
          </h2>
        </div>
        <div className="container mx-auto lg:w-2/3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6 py-8 ">
          {infoCards.map((info, index) => {
            return (
              <div key={`info-card-${info.title}-${index}`}>
                <div className="grid grid-cols-3 gap-6 auto-rows-[64px]">
                  <img
                    className="h-full w-auto"
                    alt={info.icon}
                    src={info.icon}
                  />
                  <div className="col-span-2 flex flex-col justify-center">
                    <p className="text-lg font-semibold">{info.title}</p>
                  </div>
                </div>
                <p className="mt-3">{info.body}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
