import { CubeTransparentIcon } from "@heroicons/react/solid";
import { modelSortEnum } from "api/apiTypes";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { accentStyleEnum } from "atoms/genericStyles";
import { GenericModelList } from "components/GenericModelList/GenericModelList";
import { valuePair } from "genericTypes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MODEL_LIMIT_PER_PAGE = 10;

/**
 * Renders an organization model list for {@link Organization | 'Organization'}
 *
 * @category Component
 */
export function OrganizationModels() {
  const { orgName } = useParams();
  const [fetchParams, setFetchParams] = useState<any>({ org_names: orgName });
  const [sorting, setSorting] = useState<valuePair<modelSortEnum>>({
    label: modelSortEnum.UPDATED,
    value: modelSortEnum.UPDATED,
  });

  useEffect(() => {
    setFetchParams({
      org_names: orgName,
      sort_option: sorting.value,
    });
  }, [orgName, sorting.value]);

  if (!orgName) {
    return null;
  }
  return (
    <div className="grow w-9/12 p-4 flex flex-col">
      <div className="mt-2 mb-4 flex items-center justify-between">
        <p className="ml-6 my-auto font-medium flex gap-1 items-center text-neutral-600 ">
          <CubeTransparentIcon className="h-4 w-4" />
          Models
        </p>
        <Dropdown
          containerClassName="min-w-[160px]"
          style={accentStyleEnum.SIMPLE}
          value={sorting}
          options={Object.values(modelSortEnum).map((value) => {
            return { label: value, value };
          })}
          onChange={(value: valuePair<modelSortEnum>) => {
            setSorting(value);
          }}
        />
      </div>
      <GenericModelList
        modelsFetchParams={fetchParams}
        modelMaxAmount={MODEL_LIMIT_PER_PAGE}
      />
    </div>
  );
}
