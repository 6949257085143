import * as Yup from "yup";

/**
 * Schema for organization creation
 * @category Schema
 */
export const organizationNewSchema = Yup.object().shape({
  /**
   * Name of the organization
   */
  name: Yup.string()
    .matches(/^[\w\-. ]+$/, "Name should be correctly formatted")
    .required("You need to input organization name"),
});

/**
 * Schema for organization update
 * @category Schema
 */
export const organizationUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[\w\-. ]+$/, "Name should be correctly formatted")
    .required("You need to input organization name"),
});
