import { RadioGroup } from "@headlessui/react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { CheckIcon, CubeIcon } from "@heroicons/react/solid";
import { user } from "api/apiTypes";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import useAxios from "axios-hooks";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { userAtom } from "recoil/user/atom";
import { modelNewSchema } from "./modelNewHelpers";
import { modelNewFormProps } from "./modelNewTypes";

/**
 * Renders a page for model creation
 *
 * @category Component
 */
export function ModelNew() {
  const user = useRecoilValue(userAtom);
  const [, setNotification] = useRecoilState(notificationAtom);
  const navigate = useNavigate();
  const defaultFormValues: modelNewFormProps = {
    owner: user.cwid ?? "",
    modelName: "",
    availability: "public",
  };

  const availabilityRadioOptions = [
    {
      label: "Public",
      description:
        "Anyone in Bayer can see this model. Only you (personal model) or members of your organization (organization model) can commit.",
      value: "public",
      icon: EyeIcon,
    },
    {
      label: "Private",
      description:
        "Only you (personal model) or members of your organization (organization model) can see and commit to this model.",
      value: "private",
      icon: EyeOffIcon,
    },
  ];

  const [{ data: userData }] = useAxios<user>({ url: `users/${user.cwid}` });

  const [, request] = useAxios(
    { url: "models/" },
    {
      manual: true,
    }
  );

  const handleSubmit = async (
    values: modelNewFormProps,
    formikHelpers: FormikHelpers<modelNewFormProps>
  ) => {
    const result = await request({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `models/`,
      data: {
        name: values.modelName,
        org_or_user_owner: values.owner,
        private: values.availability === "private",
      },
    });
    if (result.status === 200) {
      setNotification({
        label: `${values.modelName} modal is being created`,
        body: `It will be available in a few minutes`,
        status: notificationStatusEnum.SUCCESS,
      });
      navigate(`/models`);
    } else {
      navigate("/");
    }
    formikHelpers.setSubmitting(false);
  };
  return (
    <div className="bg-white grow container mx-auto">
      {userData && (
        <Formik
          initialValues={defaultFormValues}
          onSubmit={handleSubmit}
          validationSchema={modelNewSchema}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ isSubmitting, setFieldValue, values, isValid }) => (
            <Form className="w-full md:w-1/2 relative flex flex-col gap-2 mx-auto pt-8">
              <CubeIcon className="h-8 w-8 mx-auto text-salmon-600" />
              <p className="mx-auto font-semibold text-3xl pt-4">
                Create a new model repository
              </p>
              <p className="mx-auto font-semibold text-lg pb-2 text-gray-500">
                A repository contains all model files, including the revision
                history.
              </p>

              <div className="flex gap-4 items-center">
                <Input
                  name="owner"
                  as="select"
                  label="Owner"
                  className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
                  disabled={isSubmitting}
                >
                  <option>{user.cwid}</option>
                  {userData.organizations.map((org) => {
                    return (
                      <option key={org.org_id}>
                        {org.organization?.username}
                      </option>
                    );
                  })}
                </Input>

                <Input
                  name="modelName"
                  label="Model name"
                  className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
                  disabled={isSubmitting}
                ></Input>
              </div>
              <hr className="my-1" />
              <RadioGroup
                className="w-full flex flex-col gap-1"
                value={values.availability}
                onChange={(value: string) =>
                  setFieldValue("availability", value)
                }
              >
                {availabilityRadioOptions.map((item) => {
                  return (
                    <RadioGroup.Option key={item.value} value={item.value}>
                      {({ checked }) => (
                        <div
                          className={`flex p-2 gap-2 items-start rounded-lg cursor-pointer hover:bg-slate-50 ${
                            checked && "ring-2 ring-inset"
                          }`}
                        >
                          <item.icon className="w-full mt-2 max-w-[24px] text-slate-700" />
                          <div className="h-full">
                            <p className="text-sm font-semibold">
                              {item.label}
                            </p>
                            <p className="text-xs font-medium text-slate-500">
                              {item.description}
                            </p>
                          </div>
                          <div className="w-6 mr-1 ml-auto h-full place-self-center">
                            {checked && <CheckIcon className="w-6 h-6" />}
                          </div>
                        </div>
                      )}
                    </RadioGroup.Option>
                  );
                })}
              </RadioGroup>
              <hr className="my-1" />
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                style={accentStyleEnum.SIMPLE}
                loading={isSubmitting}
                className="h-10 mt-1 place-self-end"
              >
                Create model
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
