import { Dropdown } from "atoms/Dropdown/Dropdown";
import { accentStyleEnum } from "atoms/genericStyles";
import { iWidgetModelTypesEnum } from "atoms/InterfaceWidget/interfaceWidgetTypes";
import { useFormikContext } from "formik";
import { modelParamsSettingsFormProps } from "../modelParamsSettingsTypes";
/**
 * Renders a task dropdown for task setting
 * @category Component
 */
export function TaskSettings() {
  const { setFieldValue, values } =
    useFormikContext<modelParamsSettingsFormProps>();

  return (
    <div>
      <p>
        <span className="font-semibold text-sm">Task</span>
      </p>
      <div className="ml-4 mt-1">
        <Dropdown
          className="w-full min-w-[200px] mb-2 h-10"
          placeholder="Select model task"
          style={accentStyleEnum.SIMPLE}
          value={
            values.task ? { value: values.task, label: values.task } : undefined
          }
          options={Object.values(iWidgetModelTypesEnum).map((value) => {
            return { label: value, value };
          })}
          onChange={(value) => {
            setFieldValue("task", value.value);
          }}
        />
      </div>
    </div>
  );
}
