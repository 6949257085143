/**
 * Generic tag used for specifying backend model libraries, languages, etc.
 * @category ApiType
 */
export type genericTag = {
  text: string;
};

/**
 * A list of datasets
 * @category ApiType
 */
export type dataset = {
  id: number;
  name: string;
};

/**
 * A list of retrieved models provided by:
 * `GET /models`
 * @category ApiType
 */
export type modelList = {
  model_meta_data: model[];
  total_count: number;
};

/**
 * Type for model's comment
 * @category ApiType
 */
export type commentContent = {
  id: number;
  user: {
    id: number;
    username: string;
    full_name: string;
    email: string;
  };
  model_id: number;
  parent_id: number;
  body: string;
  created_at: string;
};

/**
 * Type for model
 * @category ApiType
 */
export type model = {
  org_or_user_owner: string;
  name: string;
  private: boolean;
  id: number;
  deployed: boolean;
  deploy_endpoint: string;
  sagemaker_pinned: number;
  git_url: string;
  client_url: string;
  last_updated: number;
  modality: modelModalityEnum;
  thumbs: [
    {
      id: number;
      user_id: number;
      model_id: number;
    }
  ];
  collaborations: [
    {
      account: {
        id: number;
        username: string;
        full_name: string;
        email: string;
        is_organization: boolean;
      };
      model_role: string;
    }
  ];
  tags: genericTag[];
  tasks: genericTag[];
  libraries: genericTag[];
  languages: genericTag[];
  datasets: dataset[];
  comments: commentContent[];
};

/**
 * Type for model modality
 * @category ApiType
 * @enum
 */
export enum modelModalityEnum {
  HUGGINGFACE = "Huggingface",
  PYTORCH = "Pytorch",
  TENSORFLOW = "Tensorflow",
}

/**
 * A list of revisions provided by:
 * `GET /models/{org_or_user_owner}/{name}/revision`
 * @category ApiType
 */
export type revisions = {
  name: string;
  hexsha: string;
  author_name: string;
  authored_date_unix: string;
  message: string;
}[];

/**
 * A file provided by:
 * `GET /models/{org_or_user_owner}/{name}/{revision}`
 * @category ApiType
 */
export type file = {
  name: string;
  size: number | null;
  type: string;
  relative_path: string;
  last_updated: number | null;
  commit_message: string | null;
  children: file[] | null;
};

/**
 * A file information provided by:
 * `GET /models/{org_or_user_owner}/{name}/{revision}/info`
 * @category ApiType
 */
export type fileInfo = {
  mime_type: string;
  is_binary: boolean;
  file_size: number;
  last_updated_time: string;
  last_updated_author: string;
  line_count: number;
  content: string;
};

/**
 * A user information provided by:
 * `GET /users/{username}`
 * @category ApiType
 */
export type user = {
  full_name: string;
  research_interests: string;
  email: string;
  disabled: boolean;
  budget: number;
  engagement: number;
  role: string;
  department: string;
  country: string;
  username: string;
  sys_role: userSysRoleEnum | string;
  api_token: string;
  api_token_expiration_date: string;
  models: [
    {
      org_or_user_owner: string;
      name: string;
      private: boolean;
      id: number;
      likes: number;
      git_url: string;
      client_url: string;
    }
  ];
  organizations: [
    {
      org_id: number;
      org_role: string;
      organization: {
        id: number;
        username: string;
        full_name: string;
      };
    }
  ];
  thumbs: [
    {
      id: number;
      user_id: number;
      model_id: number;
    }
  ];
  comments: [
    {
      id: number;
      user_id: number;
      model_id: number;
      body: string;
      parent_id: number;
      created_at: string;
      user_email: string;
    }
  ];
};

/**
 * Type for user sys role
 * @category ApiType
 * @enum
 */
export enum userSysRoleEnum {
  USER = "Deepmind.User",
  ADMINISTRATOR = "Deepmind.Administrator",
}

/**
 * A list of organizations provided by:
 * `GET /orgs`
 * @category ApiType
 */
export type organizations = organization[];

/**
 * A simple organization information
 * @category ApiType
 */
export type organization = {
  username: string;
  full_name: string;
  email: string;
  research_interests: string;
  budget: string;
  engagement: string;
  department: string;
  country: string;
};

/**
 * An organizations provided by:
 * `GET /orgs/{name}`
 * @category ApiType
 */
export type organizationDetailed = {
  username: string;
  full_name: string;
  research_interests: string;
  department: string;
  country: string;
  api_token: string;
  api_token_expiration_date: string;
  users: [
    {
      user_id: number;
      org_role: string;
      user: {
        id: number;
        full_name: string;
        research_interests: string;
        email: string;
        disabled: boolean;
        budget: number;
        engagement: number;
        role: string;
        department: string;
        country: string;
        username: string;
      };
    }
  ];
  models: [
    {
      org_or_user_owner: string;
      name: string;
      private: boolean;
      last_updated: string;
      deployed: boolean;
      deploy_endpoint: string;
      sagemaker_pinned: number;
      likes: number;
      id: number;
      git_url: string;
      client_url: string;
    }
  ];
};

/**
 * A classification specific inference token provided by:
 * `GET /api-inference/test`
 * @category ApiType
 */
export type inferenceToken = {
  entity: string;
  score: number;
  word: string;
  start: number;
  end: number;
};

/**
 * A list of inference tokens
 * @category ApiType
 */
export type inferenceTokens = inferenceToken[];

/**
 * An api token  provided by:
 * `GET /api_token`
 * @category ApiType
 */
export type apiTokenResults = {
  token: string;
  valid_until: string;
};

/**
 * Model sorting enum type
 * @category ApiType
 */
export enum modelSortEnum {
  UPDATED = "Recently Updated",
  LIKES = "Most Likes",
}

/**
 * A websocket message type provided by:
 * `WS /ws/{username}`
 * @category ApiType
 */
export type wsMessage = {
  id: string;
  type: string;
  autoHide: boolean;
  payload: {
    pipeline: string;
    metadata: unknown;
  };
  content: {
    title: string;
    description: string;
    value?: number;
    status?: string;
  };
};
