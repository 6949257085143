import { FolderIcon } from "@heroicons/react/solid";
import { DocumentIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { overrideTailwindClasses } from "tailwind-override";
import { toLocalTime } from "utils";
import { FileTableProps, fileTypeEnum } from "./fileTableTypes";
import { formatDistance } from "date-fns";

export function FileTable({
  lastCommiter,
  timeLastCommited,
  hexSHA,
  commitMessage,
  files,
  className,
  showPrev,
}: FileTableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const getFileIcon = {
    [fileTypeEnum.DOCUMENT]: (
      <DocumentIcon className={"h-4 w-4 overflow-visible text-gray-700 mr-1"} />
    ),
    [fileTypeEnum.FOLDER]: (
      <FolderIcon
        className={"h-4 w-4 overflow-visible text-gray-700 mr-1 fill-blue-400"}
      />
    ),
  };

  return (
    <div
      className={overrideTailwindClasses(
        `container mx-auto table-fixed w-full ${className}`
      )}
    >
      <div className="border border-b-0 border-gray-300 px-3 py-2 flex items-baseline rounded-t-lg bg-gradient-to-t from-gray-200 to-white">
        <div className="mr-5 truncate flex items-center flex-none text-sm font-medium">
          <Link to={`/profile/${lastCommiter}`} className="hover:underline">
            {lastCommiter}
          </Link>
        </div>
        <div className="mr-5 truncate flex items-center flex-none text-sm font-medium">
          Hex-SHA: {hexSHA}
        </div>
        <div className="mr-5 truncate flex items-center flex-none text-sm font-medium">
          Commit Message: {commitMessage}
        </div>
        <time
          className="ml-auto hidden sm:block text-sm text-gray-600 truncate flex-none pl-2"
          dateTime="2021-05-19T16:25:59"
          title="Wed, 19 May 2021 16:25:59 GMT"
        >
          {formatDistance(timeLastCommited, new Date(), {
            addSuffix: true,
          })}
        </time>
      </div>
      <ul className="border border-gray-300 border-t-0 rounded-b-lg mb-8 ">
        {files.length === 0 && (
          <li className="grid grid-cols-12 px-3 place-content-center h-10 border-t border-gray-200 gap-x-3 text-sm">
            <div className="col-span-12 truncate text-ellipsis flex items-center group text-gray-900">
              No files were found
            </div>
          </li>
        )}
        {showPrev && (
          <li className="grid grid-cols-12 px-3 place-content-center h-10 border-t border-gray-200 gap-x-3 text-sm">
            <button
              onClick={() => {
                navigate(
                  `${location.pathname.split("/").slice(0, -1).join("/")}`
                );
              }}
              className="col-span-8 md:col-span-4 lg:col-span-2 truncate text-ellipsis flex items-center group text-gray-900"
            >
              {getFileIcon[fileTypeEnum.FOLDER]}
              ...
            </button>
          </li>
        )}
        {files.map((file) => {
          return (
            <li
              key={file.name}
              className="grid grid-cols-12 px-3 place-content-center h-10 border-t border-gray-200 gap-x-3 text-sm"
            >
              <button
                onClick={() => {
                  navigate(`${location.pathname + "/" + file.name}`);
                }}
                className="col-span-4 flex items-center group text-gray-900"
              >
                {getFileIcon[file.type]}
                <span className="truncate">{file.name}</span>
              </button>

              <div className="text-gray-500 text-left col-span-6 truncate text-sm">
                {file.lastCommitMsg}
              </div>

              <div className="text-gray-500 text-right col-span-2 truncate md:block text-sm">
                {file.lastUpdated &&
                  formatDistance(file.lastUpdated, new Date(), {
                    addSuffix: true,
                  })}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
