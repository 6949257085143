import { Transition } from "@headlessui/react";
import { HeartIcon as HeartOut } from "@heroicons/react/outline";
import { HeartIcon as HeartSol } from "@heroicons/react/solid";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { LikeButtonProps } from "./likeButtonTypes";

/**
 * Renders a like button
 * @category Atom
 */
export function LikeButton({ likes, onLike }: LikeButtonProps) {
  const [user] = useRecoilState(userAtom);
  const [liked, setLiked] = useState(false);
  const likedByUser = useMemo(() => {
    return likes.some((like) => like.user_id === user.id);
  }, [likes, user.cwid]);

  const numberOfLikes = useMemo(() => {
    if (liked) {
      if (!likedByUser) {
        return likes.length + 1;
      } else {
        return likes.length;
      }
    } else {
      if (!likedByUser) {
        return likes.length;
      } else {
        return likes.length - 1;
      }
    }
  }, [liked, likedByUser, likes.length]);

  useEffect(() => {
    if (likedByUser) {
      setLiked(true);
    }
  }, [likedByUser]);

  return (
    <button
      className="rounded-md flex border hover:bg-gradient-to-b hover:from-transparent hover:to-red-50 shadow-sm"
      onClick={() => {
        onLike(likedByUser);
        setLiked(!likedByUser);
      }}
    >
      <div className="border-r m-auto px-1">
        <div className="relative h-4 w-4">
          <Transition
            show={liked}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeartSol className="absoulte top-0 h-4 w-4 text-red-600" />
          </Transition>
          {!liked && (
            <HeartOut className="absolute top-0 h-4 w-4 text-gray-400" />
          )}
        </div>
      </div>

      <div className="text-sm text-gray-400 m-auto px-2">{numberOfLikes}</div>
    </button>
  );
}
