import { createContext, FC, useContext } from "react";

/**
 * The props type for {@link ModelDiscussionProvider | 'ModelDiscussionProvider'}.
 * @category Type
 */
export type ModelDiscussionProviderProps = {
  modelId: number;
  onUpdate: () => void;
};

/**
 * The model discussion context
 */
export const ModelDiscussionContext =
  createContext<ModelDiscussionProviderProps | null>(null);

/**
 * Gets the current model id
 *
 * Must be used within {@link ModelDiscussionContext | 'ModelDiscussionContext'}
 *
 * @returns a model id
 * @category Hook
 */
export const useModelId = () => {
  const context = useContext(ModelDiscussionContext);

  if (context === null) {
    throw new Error(
      "useModelId can only be used if the component is wrapped in a ModelDiscussionProvider"
    );
  }

  return context.modelId;
};

/**
 * Gets the current model update method
 *
 * Must be used within {@link ModelDiscussionContext | 'ModelDiscussionContext'}
 *
 * @returns a model update method
 * @category Hook
 */
export const useUpdateHandler = () => {
  const context = useContext(ModelDiscussionContext);

  if (context === null) {
    throw new Error(
      "useUpdateHandler can only be used if the component is wrapped in a ModelDiscussionProvider"
    );
  }

  return context.onUpdate;
};

/**
 * Renders a model discussion provider
 *
 * @category Component
 */
export const ModelDiscussionProvider: FC<ModelDiscussionProviderProps> = ({
  children,
  modelId,
  onUpdate,
}) => (
  <ModelDiscussionContext.Provider value={{ modelId, onUpdate }}>
    {children}
  </ModelDiscussionContext.Provider>
);
