import { RadioGroup } from "@headlessui/react";
import { EyeIcon, EyeOffIcon, CheckIcon } from "@heroicons/react/solid";
import { useFormikContext } from "formik";
import { modelParamsSettingsFormProps } from "../modelParamsSettingsTypes";

/**
 * Renders radio options for model availability setting
 * @category Component
 */
export function AvailabilitySetting() {
  const { setFieldValue, values } =
    useFormikContext<modelParamsSettingsFormProps>();
  const availabilityRadioOptions = [
    {
      label: "Public",
      description:
        "Anyone in Bayer can see this model. Only you (personal model) or members of your organization (organization model) can commit.",
      value: "public",
      icon: EyeIcon,
    },
    {
      label: "Private",
      description:
        "Only you (personal model) or members of your organization (organization model) can see and commit to this model.",
      value: "private",
      icon: EyeOffIcon,
    },
  ];

  return (
    <div>
      <p className="font-semibold text-sm">Availability</p>
      <div className="ml-4">
        <RadioGroup
          className="w-full flex flex-col gap-1"
          value={values.availability}
          onChange={(value: string) => setFieldValue("availability", value)}
        >
          {availabilityRadioOptions.map((item) => {
            return (
              <RadioGroup.Option key={item.value} value={item.value}>
                {({ checked }) => (
                  <div
                    className={`flex p-2 gap-2 items-start rounded-lg cursor-pointer hover:bg-slate-50 ${
                      checked && "ring-2 ring-inset"
                    }`}
                  >
                    <item.icon className="w-full mt-2 max-w-[24px] text-slate-700" />
                    <div className="h-full">
                      <p className="text-sm font-semibold">{item.label}</p>
                      <p className="text-xs font-medium text-slate-500">
                        {item.description}
                      </p>
                    </div>
                    <div className="w-6 mr-1 ml-auto h-full place-self-center">
                      {checked && <CheckIcon className="w-6 h-6" />}
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
}
