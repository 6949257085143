/**
 * Authentication scopes for reaching Diana API
 */
export const authScopes = [
  "https://bayer.com/b2capi/.default",
  "profile",
  "email",
];
/**
 * Authentication scopes for reaching Azure API
 */
export const meScopes = [
  "email",
  "GroupMember.Read.All",
  "openid",
  "profile",
  "User.Read",
  "User.ReadBasic.All",
];
