import { model } from "api/apiTypes";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import useAxios from "axios-hooks";
import { FormikHelpers, Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { AvailabilitySetting } from "./AvailabilitySetting/AvailabilitySetting";
import { ModalitySetting } from "./ModalitySetting/ModalitySetting";
import {
  modelParamsSettingsFormProps,
  ModelParamsSettingsProps,
} from "./modelParamsSettingsTypes";
import { TaskSettings } from "./TaskSettings/TaskSettings";

/**
 * Renders a model parameters section for the {@link ModelSettings | 'ModelSettings'} component
 * @category Component
 */
export function ModelParamsSettings({
  model,
  onUpdate,
}: ModelParamsSettingsProps) {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, requestModel] = useAxios<model>({
    url: `models/${modelOwner}/${modelName}`,
  });

  const defaultFormValues: modelParamsSettingsFormProps = {
    availability: model?.private ? "private" : "public",
    modality: model.modality,
    task: model?.tasks[0]?.text,
  };

  const handleUpdateSubmit = async (
    values: modelParamsSettingsFormProps,
    formikHelpers: FormikHelpers<modelParamsSettingsFormProps>
  ) => {
    try {
      await requestModel({
        method: "PATCH",
        data: {
          private: values.availability === "private",
          modality: values.modality,
          update_task_name: values.task ?? "",
        },
      });
      setNotification({
        label: `Model has been sucessfuly updated`,
        status: notificationStatusEnum.SUCCESS,
      });
      onUpdate();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleValidate = (values: modelParamsSettingsFormProps) => {
    const errors: Partial<Record<keyof modelParamsSettingsFormProps, string>> =
      {};

    if (
      values.task === defaultFormValues.task &&
      values.modality === defaultFormValues.modality &&
      values.availability === defaultFormValues.availability
    ) {
      errors.task = "Values must be different from the default values";
    }

    return errors;
  };
  return (
    <Formik
      initialValues={defaultFormValues}
      onSubmit={handleUpdateSubmit}
      validate={handleValidate}
      validateOnMount
    >
      {({ isSubmitting, isValid }) => (
        <Form className="w-full">
          <div className="mx-auto bg-gray-50 shadow-sm rounded-md p-4 border md:w-2/3">
            <p className="font-semibold text-sm">Change model parameters</p>
            <hr className="mt-2 mb-2"></hr>
            <div className="grid gap-4 grid-cols-1">
              <AvailabilitySetting />
              <TaskSettings />
              <ModalitySetting />
            </div>
            <hr className="mt-1"></hr>
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              style={accentStyleEnum.SIMPLE}
              className="h-10 mt-3"
              loading={isSubmitting}
            >
              Update model
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
