import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { messageAtom, messageAtomType } from "recoil/message/atom";
import { Notification } from "atoms/Notification/Notification";
import { NotificationCenterProps } from "./notificationCenterTypes";
import { mapWsMessageToNotification } from "api/apiHelpers";

/**
 * Reders a notification center for {@link Header | 'Header'}
 *
 * Is dependant on a notification webworker and controls two global state parameters - `notification` and `notificationStack`
 *
 * @category Type
 */
export function NotificationCenter({
  websocketReady,
}: NotificationCenterProps) {
  const [message, setMessage] = useRecoilState<messageAtomType>(messageAtom);

  const [readAll, setReadAll] = useState<boolean>(false);

  useEffect(() => setReadAll(false), [message]);

  const deleteMessage = (id: string) => {
    const newList = [...message.filter((listMessage) => listMessage.id !== id)];
    setMessage(newList);
  };

  return (
    <Popover
      className="relative ml-auto flex items-center"
      onClick={() => setReadAll(true)}
    >
      {({ open, close }) => (
        <>
          {/* {console.log('aaa', readAll)} */}
          <Popover.Button
            className={`${
              open ? "text-zinc-700" : "text-zinc-900"
            } relative w-full group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-zinc-700 focus:outline-none focus:bg-highlight/50 focus:ring-2 focus:ring-offset-0 focus:ring-highlight/50`}
          >
            <InformationCircleIcon className="w-6 h-6" />
            {websocketReady ? (
              message.length > 0 && (
                <div
                  className={`absolute top-1/2 left-1/2 rounded-full h-3 w-3 flex items-center justify-center ${
                    readAll ? "bg-sky-800" : "bg-red-800"
                  }`}
                >
                  <span className="relative text-white text-sm text-bold p-1">
                    {message.length}
                  </span>
                </div>
              )
            ) : (
              <div className={`absolute top-1/2 left-1/2 rounded-full h-3 w-3`}>
                <ExclamationCircleIcon className="text-red-800 bg-white w-full h-full rounded-full border-0 ring-0" />
              </div>
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-full -bottom-1/2 transform translate-y-full -translate-x-full w-screen max-w-xs">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden ">
                <div className="relative grid bg-white">
                  {websocketReady ? (
                    <>
                      {message.length === 0 && (
                        <div className="p-4">
                          <p className="font-semibold text-sm text-gray-900">
                            You have zero notifications
                          </p>
                          <p className="font-medium text-xs text-gray-500">
                            When you do have any, they will show up here
                          </p>
                        </div>
                      )}
                      {message.map((item, index) => (
                        <div
                          key={`notification-status-${index}`}
                          className="flex items-start rounded-lg truncate"
                        >
                          <Notification
                            {...mapWsMessageToNotification(item)}
                            progress={item.content?.value}
                            // showX={true}
                            // showX={
                            //   item.type !== messageTypeEnum.PROGRESS ||
                            //   (item.type === messageTypeEnum.PROGRESS &&
                            //     (item.content as apiProgressMessage)?.status !==
                            //       progressTypeEnum.RUNNING)
                            // }
                            onXClick={() => deleteMessage(item.id)}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="p-4">
                        <p className="font-semibold text-sm text-gray-900">
                          No connection to notify server
                        </p>
                        <p className="font-medium text-xs text-gray-500">
                          Try again later or contact support
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
