import { BriefcaseIcon } from "@heroicons/react/solid";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import useAxios from "axios-hooks";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { organizationNewSchema } from "./organizationHelpers";
import { organizationNewFormProps } from "./organizationTypes";

/**
 * Renders a page for new organization creation
 *
 * @category Component
 */
export function OrganizationNew() {
  const defaultFormValuesNew: organizationNewFormProps = {
    name: "",
  };
  const navigate = useNavigate();

  const [, request] = useAxios(
    {},
    {
      manual: true,
    }
  );

  const handleSubmitNewOrg = async (
    values: organizationNewFormProps,
    formikHelpers: FormikHelpers<organizationNewFormProps>
  ) => {
    await request({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `orgs/`,
      data: {
        full_name: values.name,
      },
    });
    navigate("/");
    formikHelpers.setSubmitting(false);
  };
  return (
    <div className="bg-white grow container mx-auto">
      <Formik
        initialValues={defaultFormValuesNew}
        onSubmit={handleSubmitNewOrg}
        validationSchema={organizationNewSchema}
      >
        {({ isSubmitting, touched, setFieldValue, values }) => (
          <Form className="w-full md:w-1/2 relative flex flex-col gap-2 mx-auto pt-8">
            <div className="w-full items-center flex flex-col gap-2 pt-8">
              <BriefcaseIcon className="h-8 w-8 mx-auto text-salmon-600" />
              <p className="mx-auto font-semibold text-3xl pt-4">
                Create a new organization or update existing one
              </p>
              <p className="mx-auto font-semibold text-lg pb-2 text-gray-500">
                An organization contains all model files, including the revision
                history.
              </p>
            </div>
            <div className="flex gap-4 items-center">
              <Input
                name="name"
                label="Name"
                className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
                disabled={isSubmitting}
              ></Input>
            </div>

            <Button
              type="submit"
              disabled={isSubmitting}
              style={accentStyleEnum.SIMPLE}
              className="h-10 mt-3 place-self-end"
            >
              Create organization
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
