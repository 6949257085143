import { Link, matchPath, useLocation } from "react-router-dom";
import { NavTabsProps, NavTabProps } from "./navTabsTypes";

/**
 * Renders a location aware navigation tab
 * @category Atom
 */
export function NavTab(props: NavTabProps) {
  const location = useLocation();

  const matchResult = matchPath(
    props.pathToMatch ? props.pathToMatch : props.link,
    location.pathname
  );

  return (
    <Link
      to={props.link}
      className={`px-5 py-2 flex gap-2 items-center border-b-2 text-sm font-medium ${
        matchResult === null
          ? "text-neutral-500 border-transparent hover:text-neutral-700 hover:border-neutral-300"
          : "border-b-2 text-neutral-900 border-neutral-900 font-semibold"
      }`}
    >
      {props.icon && <props.icon className={`h-4 w-4`} />}
      {props.label}
    </Link>
  );
}

/**
 * Renders a list of {@link NavTab}
 * ```tsx
 * const navTabs = [
 *     {
 *       label: "Tab 1",
 *       icon: IdentificationIcon,
 *       link: `/tabs/1`,
 *     },
 *     {
 *       label: "Tab 2",
 *       icon: DocumentIcon,
 *       link: `/tabs/2`,
 *       pathToMatch: `/tabs/2/*`,
 *     }
 * ]
 * ...
 * <NavTabs tabs={navTabs} />
 * ```
 * @category Atom
 */
export function NavTabs({ tabs }: NavTabsProps) {
  return (
    <div className="flex pt-2">
      {tabs.map((tab) => {
        return <NavTab key={tab.link} {...tab} />;
      })}
    </div>
  );
}
