import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { lowlight } from "lowlight/lib/all.js";
import { FC } from "react";
import { EditorControls } from "./EditorControls";
import { RichTextEditorProps } from "./richTextEditorProps";

/**
 * Renders a rich text editor for {@link Comment| 'Comment'} creation
 *
 * @category Component
 */
export const RichTextEditor: FC<RichTextEditorProps> = ({
  children,
  onClick,
  placeholder = "Leave a comment",
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: "list-disc pl-4",
          },
        },
      }),
      Placeholder.configure({
        placeholder,
        emptyEditorClass:
          "!text-gray-400 before:content-[attr(data-placeholder)]",
      }),
      CodeBlockLowlight.configure({ lowlight }),
    ],
    editorProps: {
      attributes: {
        class: "!mx-0 !my-4 border-2 border-gray-200 rounded-xl list-disc",
      },
    },
  });

  return (
    <EditorControls
      editor={editor}
      onClick={onClick(editor?.getHTML())}
      Cancel={children}
    >
      <EditorContent editor={editor} />
    </EditorControls>
  );
};
