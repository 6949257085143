import { useEffect, useMemo } from "react";
import useAxios from "axios-hooks";
import { fileInfo } from "api/apiTypes";
import { Link, useParams } from "react-router-dom";
import { overrideTailwindClasses } from "tailwind-override";
import { bytesToHuman } from "utils";
import { mapBePreviewToPreviewFile } from "api/apiHelpers";
import { FilePreviewProps } from "./filePreviewTypes";
import { formatDistance } from "date-fns";
import { BinaryPreview } from "./BinaryPreview";
import { TextPreview } from "./TextPreview";
import { Loading } from "atoms/Loading/Loading";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";

export function FilePreview({ className }: FilePreviewProps) {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const branchName = urlParams["branch"];
  const gitPath = urlParams["*"];

  const [, setNotification] = useRecoilState(notificationAtom);

  const [
    { loading: isLoadingInfo, data: fetchedInfo, error: infoError },
    fetchInfo,
  ] = useAxios<fileInfo>(
    {
      url: `models/${modelOwner}/${modelName}/${branchName}/info?filepath=${gitPath}`,
    },
    { manual: true }
  );

  const info = useMemo(
    () => (fetchedInfo ? mapBePreviewToPreviewFile(fetchedInfo) : undefined),
    [fetchedInfo]
  );

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  useEffect(() => {
    if (infoError)
      setNotification({
        label: `Server error: ${infoError.code}`,
        body: "Could not load information of file",
      });
  }, [infoError]);

  if (isLoadingInfo) {
    return (
      <div className="h-auto min-w-[246px] p-4 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={overrideTailwindClasses(
        `container mx-auto table-fixed w-full flex flex-col grow ${className}`
      )}
    >
      <div className="border border-b-0 border-gray-300 px-3 py-2 flex items-baseline rounded-t-lg bg-gradient-to-t from-gray-200 to-white">
        <div className="divide-x divide-gray-300 flex truncate items-center flex-none text-sm">
          <div className="pr-2">
            {info?.lineCount && `${info.lineCount} lines`}
          </div>
          <div className="pl-2">{info?.size && bytesToHuman(info?.size)}</div>
        </div>
        <div className="ml-auto divide-x divide-gray-300 flex truncate items-center flex-none text-sm">
          <div className="flex items-center font-medium pr-2">
            <Link to={`/profile/${info?.author}`} className="hover:underline">
              {info?.author}
            </Link>
          </div>
          <time
            className="hidden sm:block text-gray-600 truncate flex-none pl-2"
            dateTime="2021-05-19T16:25:59"
            title="Wed, 19 May 2021 16:25:59 GMT"
          >
            {info?.lastUpdated &&
              formatDistance(info.lastUpdated, new Date(), {
                addSuffix: true,
              })}
          </time>
        </div>
      </div>
      <ul className="border border-gray-300 border-t-0 rounded-b-lg mb-8 grow flex">
        {info?.isBinary ? <BinaryPreview /> : <TextPreview size={info?.size} />}
      </ul>
    </div>
  );
}
