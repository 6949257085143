import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { RecoilRoot } from "recoil";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_OICD_CLIENT_ID || "",
    authority: process.env.REACT_APP_OICD_AUTH_ENDPOINT || "",
    redirectUri: process.env.REACT_APP_OICD_REDIRECT || window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <RecoilRoot>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </RecoilRoot>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
