import { Link } from "react-router-dom";
import { dateToHumanText } from "utils";
import { FilterTag } from "atoms/FilterTag/FilterTag";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { OrganizationPlaqueProps } from "./organizationPlaqueTypes";
import { ThumbUpIcon } from "@heroicons/react/solid";

/**
 * Renders a plaque with organization specific information
 * @category Atom
 */
export function OrganizationPlaque({
  name,
  fullName,
  email,
  interests,
  budget,
  engagement,
  department,
  country,
  urlTo,
}: OrganizationPlaqueProps) {
  return (
    <Link
      className="h-fit overflow-hidden rounded-lg drop-shadow-md px-5 py-4 flex flex-col gap-2 items-center bg-gradient-to-b from-slate-50 to-slate-100 hover:to-slate-200 active:bg-gradient-to-t"
      to={urlTo}
    >
      <div className="flex gap-4 w-full justify-between">
        <div className="text-md font-medium">{fullName}</div>
        <div
          className={`${
            accentStyles[accentStyleEnum.SIMPLE].bgColor
          } hidden sm:block mt-1 py-0.5 px-2 text-sm ${
            accentStyles[accentStyleEnum.SIMPLE].textColorContrast
          } rounded-md w-fit`}
        >
          {name}
        </div>
      </div>
      <div className="flex w-full">
        <div className="text-ellipsis overflow-hidden flex-wrap h-[26px] flex gap-2"></div>

        <div className="ml-auto mr-3 flex items-center text-slate-400"></div>

        <div className="whitespace-nowrap text-slate-400"></div>
      </div>
    </Link>
  );
}
