import { Field } from "formik";
import { overrideTailwindClasses } from "tailwind-override";
import { removeObjectKey } from "utils";
import { InputProps } from "./formAtomsTypes";

/**
 * Renders an input component
 *
 * Must be used inside the Formik form
 *
 * ```tsx
 * <Formik initialValues={initialValues} onSubmit={handleSubmit}>
 *  <Form>
 *    <Input
 *      as="textarea"
 *      className="ml-4 h-20"
 *      id="interests"
 *      name="interests"
 *      label="Research interests"
 *      placeholder="Research interests"
 *    />
 *  </Form>
 * </Formik>
 * ```
 *
 * @category Atom
 */
export function Input(props: InputProps) {
  return (
    <div
      className={overrideTailwindClasses(
        `w-full flex flex-col gap-2 ${props.containerClass}`
      )}
    >
      {props.label && (
        <label htmlFor={props.name} className="text-sm font-medium">
          {props.label}
        </label>
      )}
      <Field
        {...removeObjectKey(props, ["label", "containerClass"])}
        id={props.name}
        className={overrideTailwindClasses(
          `bg-white rounded-lg w-full h-10 px-4 border-2 border-neutral-300 sm:text-sm ${
            props.disabled && "text-neutral-500 border-neutral-200"
          } ${props.className}`
        )}
      >
        {props.children}
      </Field>
    </div>
  );
}
