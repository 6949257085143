import { model } from "api/apiTypes";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import useAxios from "axios-hooks";
import { FormikHelpers, Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import {
  DeleteSettingProps,
  modelDeletionFormProps,
} from "./deleteSettingTypes";

/**
 * Renders a deletion model section for the {@link ModelSettings | 'ModelSettings'} component
 * @category Component
 */
export function DeleteSetting({ model }: DeleteSettingProps) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const [, requestModel] = useAxios<model>({
    url: `models/${modelOwner}/${modelName}`,
  });
  const [, setNotification] = useRecoilState(notificationAtom);

  const defaultDeletionFormValues: modelDeletionFormProps = {
    name: "",
  };

  const handleDeletionSubmit = async (
    values: modelDeletionFormProps,
    formikHelpers: FormikHelpers<modelDeletionFormProps>
  ) => {
    try {
      await requestModel({ method: "DELETE" });
      setNotification({
        label: `Model has been sucessfuly deleted`,
        status: notificationStatusEnum.SUCCESS,
      });
      navigate("/models");
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={defaultDeletionFormValues}
      onSubmit={handleDeletionSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="w-full">
          <div className="mx-auto bg-gray-50 shadow-sm rounded-md p-4 border md:w-2/3">
            <div className="flex gap-4 items-center">
              <Input
                name="name"
                label="Enter model name to delete the model"
                placeholder={model.name}
                className="w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
                disabled={isSubmitting}
              ></Input>
            </div>

            <Button
              type="submit"
              disabled={isSubmitting || values.name !== model.name}
              style={accentStyleEnum.SIMPLE}
              className="h-10 mt-3"
              loading={isSubmitting}
            >
              Delete model
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
