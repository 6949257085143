import { CubeTransparentIcon } from "@heroicons/react/outline";
import { modelSortEnum } from "api/apiTypes";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { ProfileModelsProps } from "../profileTypes";
import { useEffect, useState } from "react";
import { GenericModelList } from "components/GenericModelList/GenericModelList";
import { valuePair } from "genericTypes";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { accentStyleEnum } from "atoms/genericStyles";

const MODEL_LIMIT_PER_PAGE = 10;

/**
 * Renders a model side of an profile page
 * @category Component
 */
export function ProfileModels({ profileId }: ProfileModelsProps) {
  const [user] = useRecoilState(userAtom);
  const [fetchParams, setFetchParams] = useState<any>({
    usernames: profileId ? profileId : user.cwid,
  });
  const [sorting, setSorting] = useState<valuePair<modelSortEnum>>({
    label: modelSortEnum.UPDATED,
    value: modelSortEnum.UPDATED,
  });
  useEffect(() => {
    setFetchParams({
      usernames: profileId ? profileId : user.cwid,
      sort_option: sorting.value,
    });
  }, [profileId, sorting.value, user.cwid]);
  return (
    <div className="grow flex flex-col">
      <div className="mt-2 mb-4 flex items-center justify-between">
        <p className="ml-6 my-auto font-medium flex gap-1 items-center text-neutral-600 ">
          <CubeTransparentIcon className="h-4 w-4" />
          Models
        </p>
        <Dropdown
          containerClassName="min-w-[160px]"
          style={accentStyleEnum.SIMPLE}
          value={sorting}
          options={Object.values(modelSortEnum).map((value) => {
            return { label: value, value };
          })}
          onChange={(value: valuePair<modelSortEnum>) => {
            setSorting(value);
          }}
        />
      </div>
      <GenericModelList
        modelsFetchParams={fetchParams}
        modelMaxAmount={MODEL_LIMIT_PER_PAGE}
      />
    </div>
  );
}
