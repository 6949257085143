import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { lowlight } from "lowlight/lib/all.js";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { sanitize } from "dompurify";
import { FC, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { AddComment } from "./AddComment";
import { CommentProps } from "./commentSectionProps";
import { DeleteComment } from "./DeleteComment";
import formatDistance from "date-fns/formatDistance";
import { toLocalTime } from "utils";

import { Avatar } from "atoms/Avatar/Avatar";
import { Link } from "react-router-dom";

/**
 * Renders a comment for {@link CommentSection | 'CommentSection'}
 *
 * @category Component
 */
export const Comment: FC<CommentProps> = ({
  id,
  parent_id,
  user,
  body,
  created_at,
  children,
}) => {
  const [currentUser] = useRecoilState(userAtom);
  const [isReplying, setIsReplying] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: "list-disc pl-4",
          },
        },
      }),
      CodeBlockLowlight.configure({ lowlight }),
    ],
    editorProps: {
      attributes: {
        class: "!m-0 !h-fit !min-h-0",
      },
    },
    content: sanitize(body),
    editable: false,
  });

  const handleReply = () => {
    setIsReplying(true);
  };

  const handleCancel = () => {
    setIsReplying(false);
  };

  const isOwner = useMemo(
    () => user.username === currentUser.cwid,
    [currentUser.cwid, user.username]
  );

  return (
    <div className={parent_id !== null ? "pl-8 bg-white" : "bg-white"}>
      <div className="mt-4 border-2 border-gray-200 rounded-xl">
        <header className="px-3 py-2 rounded-t-lg bg-gradient-to-t from-gray-200 to-white flex">
          <Link
            to={`/profile/${user.username}`}
            className={"font-bold mr-1 flex"}
          >
            <div className="mr-2">
              <Avatar size="sm" ownerEmail={user.email} />
            </div>
            {user.full_name}
          </Link>
          <div className="inline">
            {" commented "}
            {formatDistance(toLocalTime(new Date(created_at)), new Date(), {
              addSuffix: true,
            })}
          </div>
          {isOwner && <DeleteComment id={id} />}
        </header>
        <EditorContent editor={editor} />

        {isReplying ? (
          <div className="p-4 shadow-md">
            <AddComment variant={{ type: "reply", id }}>
              <Button
                className="w-fit"
                style={accentStyleEnum.GRAY}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </AddComment>
          </div>
        ) : (
          <div className="border-t-2 border-gray-200">
            <div className="flex flex-row gap-4 py-2 px-4">
              <Button style={accentStyleEnum.SIMPLE} onClick={handleReply}>
                Reply
              </Button>
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
