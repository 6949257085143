import {
  mapBeDatasetsToFeFilter,
  mapBeGenericTagsToFeFilter,
} from "api/apiHelpers";
import { dataset, genericTag } from "api/apiTypes";
import { accentStyleEnum } from "atoms/genericStyles";
import { Loading } from "atoms/Loading/Loading";
import useAxios from "axios-hooks";
import { FilterSection } from "components/FilterSection/FilterSection";
import { FilterSectionProps } from "components/FilterSection/filterSectionTypes";
import { useMemo } from "react";

/**
 * Renders a list of filters for {@link ModelList | 'ModelList'}
 *
 * @category Component
 */
export function ModelListFilters() {
  const [{ loading: isLoadingTags, data: tags }] = useAxios<genericTag[]>({
    url: "tags/",
  });

  const [{ loading: isLoadingLibs, data: libs }] = useAxios<genericTag[]>({
    url: "libs/",
  });

  const [{ loading: isLoadingLangs, data: langs }] = useAxios<genericTag[]>({
    url: "languages/",
  });

  const [{ loading: isLoadingTasks, data: tasks }] = useAxios<genericTag[]>({
    url: "tasks/",
  });

  const [{ loading: isLoadingDatasets, data: datasets }] = useAxios<dataset[]>({
    url: "datasets/",
  });
  const filterSections: FilterSectionProps[] = useMemo(() => {
    return [
      {
        label: "Tags",
        sectionColor: accentStyleEnum.CADET,
        filterOptions: mapBeGenericTagsToFeFilter(tags || []),
        filterApiLabel: "tag_names",
        multiselect: true,
      },
      {
        label: "Libraries",
        sectionColor: accentStyleEnum.SALMON,
        filterOptions: mapBeGenericTagsToFeFilter(libs || []),
        filterApiLabel: "lib_names",
        multiselect: true,
      },
      {
        label: "Languages",
        sectionColor: accentStyleEnum.YELLOW,
        filterOptions: mapBeGenericTagsToFeFilter(langs || []),
        filterApiLabel: "language_names",
        multiselect: true,
      },
      {
        label: "Tasks",
        sectionColor: accentStyleEnum.VIOLET,
        filterOptions: mapBeGenericTagsToFeFilter(tasks || []),
        filterApiLabel: "task_name",
        multiselect: false,
      },
      {
        label: "Datasets",
        sectionColor: accentStyleEnum.CADET,
        filterOptions: mapBeDatasetsToFeFilter(datasets || []),
        filterApiLabel: "dataset_names",
        multiselect: true,
      },
    ];
  }, [tags, libs, langs, tasks, datasets]);

  if (
    isLoadingDatasets ||
    isLoadingTasks ||
    isLoadingLangs ||
    isLoadingLibs ||
    isLoadingTags
  ) {
    return (
      <div className="h-auto min-w-[246px] p-4 w-3/12 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
        <Loading />
      </div>
    );
  }
  return (
    <div className="h-auto min-w-[246px] p-4 w-3/12 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
      <div className="sticky top-24 flex gap-4 flex-col">
        {filterSections.map((filterSection) => {
          return <FilterSection key={filterSection.label} {...filterSection} />;
        })}
      </div>
    </div>
  );
}
