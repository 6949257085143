/**
 * Renders a not found page
 *
 * @category Component
 */
export function NotFound() {
  return (
    <div className="grow overflow-auto relative container mx-auto">
      <div className="w-2/3 mt-36">
        <p className="text-8xl font-bold mb-4">
          <span className="text-salmon-800">404</span> Page not found
        </p>
        <p className="text-xl text-gray-250">
          {"The page you are looking for didn't or no longer exists."}
        </p>
      </div>
    </div>
  );
}
