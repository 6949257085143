import useAxios from "axios-hooks";
import { useMemo } from "react";
import { model } from "api/apiTypes";
import { Navigate, useParams } from "react-router-dom";
import { ModelInfo } from "../ModelInfo/ModelInfo";
import { mapBeModelToFeModelInfo } from "api/apiHelpers";
import { Loading } from "atoms/Loading/Loading";
import { DeleteSetting } from "./DeleteSetting/DeleteSetting";
import { ModelParamsSettings } from "./ModelParamsSettings/ModelParamsSettings";
import { EditMember } from "./EditCollaboration/EditCollaboration";

/**
 * Renders a settings page for the specific model
 *
 * Requires `model` and `owner` url parameters
 * - model - name of the model
 * - owner - owners cwid
 * @category Component
 */
export function ModelSettings() {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];

  const [
    { loading: isLoadingModel, data: originalModel, error: modelError },
    requestModel,
  ] = useAxios<model>({
    url: `models/${modelOwner}/${modelName}`,
  });

  const model = useMemo(
    () =>
      originalModel && originalModel.name
        ? mapBeModelToFeModelInfo(originalModel)
        : undefined,
    [originalModel]
  );

  if (isLoadingModel) {
    return (
      <div className="relative grow">
        <Loading />
      </div>
    );
  }

  if (modelError) {
    return <Navigate to={"/404"} />;
  }

  if (!model || !originalModel) {
    return null;
  }

  return (
    <div className="relative grow mb-6">
      <>
        <ModelInfo
          {...model}
          onModelUpdate={() => {
            requestModel();
          }}
        />
        <div className="container mx-auto mt-8 grid grid cols-1 gap-6 ">
          <ModelParamsSettings
            model={originalModel}
            onUpdate={() => requestModel()}
          />
          <EditMember model={originalModel} onUpdate={() => requestModel()} />
          <DeleteSetting model={originalModel} />
        </div>
      </>
    </div>
  );
}
