import { Link, useNavigate } from "react-router-dom";
import { SearchBar } from "./SearchBar/SearchBar";
import { CubeTransparentIcon } from "@heroicons/react/solid";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import logo from "assets/logo/logo_raw_min.png";
import { Avatar } from "atoms/Avatar/Avatar";
import { Popover } from "@headlessui/react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BookOpenIcon, DocumentIcon } from "@heroicons/react/outline";
import { NotificationCenter } from "./NotificationCenter/NotificationCenter";

/**
 * Renders application's header
 *
 * @category Component
 */
export function Header({ websocketReady }: { websocketReady: boolean }) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();
  const avatarMenuOptions = [
    { label: "My profile", to: "profile" },
    { label: "New model", to: "models/new" },
    { label: "New organization", to: "organizations/new" },
    {
      label: "Logout",
      action: () => {
        instance.logoutRedirect({ account, onRedirectNavigate: () => false });
        navigate("/");
      },
    },
  ];
  return (
    <div className="sticky top-0 z-20 bg-white border-b-2 border-gray-100">
      <nav className="mx-auto container flex items-center justify-between flex-wrap p-4">
        <div className="max-w-sm w-8/12 flex gap-3 items-center">
          <Link to="/">
            <img className="h-8 w-8" alt="deepmind" src={logo} />
          </Link>
          {isAuthenticated && <SearchBar defaultLabel="Search models ..." />}
        </div>
        <div className="justify-self-end h-fit flex gap-4 lg:gap-16 items-center">
          <div className="flex gap-4 items-center">
            <a
              className="font-medium flex gap-1 items-center text-neutral-700 hover:text-cadet-200"
              href={`${process.env.REACT_APP_BACKEND_ENDPOINT}redoc`}
            >
              <BookOpenIcon className="h-4 w-4" />
              <span className="hidden md:block">API ReDoc</span>
            </a>
            <a
              className="font-medium flex gap-1 items-center text-neutral-700 hover:text-cadet-200"
              href={`${process.env.REACT_APP_DOC_ENDPOINT}`}
            >
              <DocumentIcon className="h-4 w-4" />
              <span className="hidden md:block">Documentation</span>
            </a>
            {isAuthenticated && (
              <Link
                className="font-medium flex gap-1 items-center text-neutral-700 hover:text-cadet-200"
                to="/models"
              >
                <CubeTransparentIcon className="h-4 w-4" />
                <span className="hidden md:block">Models</span>
              </Link>
            )}
            {isAuthenticated && (
              <Link
                className="font-medium flex gap-1 items-center text-neutral-700 hover:text-cadet-200"
                to="/organizations"
              >
                <OfficeBuildingIcon className="h-4 w-4" />
                <span className="hidden md:block">Organizations</span>
              </Link>
            )}
          </div>
          {isAuthenticated && (
            <div className="flex gap-4 items-center">
              <NotificationCenter websocketReady={websocketReady} />
              <Popover className="relative w-max flex justify-center rounded-lg">
                {({ close }) => (
                  <>
                    <Popover.Button>
                      <Avatar size="sm" />
                    </Popover.Button>

                    <Popover.Panel className="absolute z-10 h-fit w-max transform right-1/2 top-full rounded-lg drop-shadow-lg bg-white">
                      <div className="grid grid-cols-1 text-sm font-medium">
                        {avatarMenuOptions.map((item) => {
                          return (
                            <div
                              className="px-3 py-2 hover:underline cursor-pointer"
                              key={item.label}
                              onClick={() => {
                                if (item.to) {
                                  navigate(item.to);
                                }
                                if (item.action) {
                                  item.action();
                                }
                                close();
                              }}
                            >
                              {item.label}
                            </div>
                          );
                        })}
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}
