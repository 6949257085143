import { InterfaceWidgetContent } from "./InterfaceWidgetContent";
import { IWidgetProps } from "./interfaceWidgetTypes";

/**
 * Renders interface widget for API hosted model testing
 * @category Atom
 */
export function InterfaceWidget(props: IWidgetProps) {
  return (
    <div>
      <div className="bg-slate-50 flex flex-col gap-1 p-6 rounded-lg border-2 border-gray-100">
        <div className="font-medium">Hosted Interface API</div>
        {!props.classification ? (
          <div className="px-3 py-2 rounded-md font-medium border-2 border-yellow-300 bg-yellow-100 text-sm text-gray-900">
            <p>Interface widget is disabled</p>
            <p>Please update model type to enable it</p>
          </div>
        ) : (
          <InterfaceWidgetContent {...props} />
        )}
      </div>
    </div>
  );
}
