import { AddComment } from "./AddComment";
import { Comment } from "./Comment";
import { addReplies, byDate } from "./commentSectionHelpers";
import { CommentSectionProps, CommentWithReplies } from "./commentSectionProps";

const CommentTree = (commentsWithReplies: CommentWithReplies[]) => {
  return commentsWithReplies.map((commentWithReply: CommentWithReplies) => (
    <Comment key={commentWithReply.id} {...commentWithReply}>
      {CommentTree(commentWithReply.replies)}
    </Comment>
  ));
};

/**
 * Renders a comment section
 *
 * @category Component
 */
export const CommentSection = ({ comments = [] }: CommentSectionProps) => {
  const commentsWithReplies = addReplies(comments.sort(byDate));

  return (
    <section className="flex flex-col p-4">
      <div className="container mx-auto">
        <AddComment />
      </div>
      {commentsWithReplies.length > 0 && (
        <div className="container mx-auto">
          {CommentTree(commentsWithReplies)}
        </div>
      )}
    </section>
  );
};
