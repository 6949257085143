import graph from "assets/other/DeepMind_Architecture_vertical.png";

export function GraphSection() {
  return (
    <div>
      <div className="pt-40 container mx-auto py-8">
        <div className="text-center">
          <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Architecture
          </h2>
        </div>
      </div>
      <div className="flex mt-4 max-w-5xl lg:mx-auto">
        <img
          className="h-auto w-auto"
          alt={"Deepmind architecture"}
          src={graph}
        />
      </div>
    </div>
  );
}
