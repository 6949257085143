import * as Yup from "yup";

/**
 * Schema for new model creation
 *
 * @category Schema
 */
export const modelNewSchema = Yup.object().shape({
  owner: Yup.string().required("You need to select an owner"),
  modelName: Yup.string()
    .matches(/^[\w\-. ]+$/, "Name should be correctly formatted")
    .required("You need to input model name"),
  availability: Yup.string().required("You need to select availability"),
});
