import { Loading } from "atoms/Loading/Loading";
import { RequireAuthProps } from "./requireAuthTypes";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

/**
 * Renders a authentication gate, that protect nested routes
 *
 * ```tsx
 * <Routes>
 *    <Route element={<RequireAuth axiosConfigured={axiosConfigured} />}>
 *        <Route path="home" element={<HomeComponent />} />
 *        ...
 *    </Route>
 * </Routes>
 *
 * ```
 * @category Component
 */
export function RequireAuth({
  children,
  redirectPath = "/",
  axiosConfigured,
}: RequireAuthProps) {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }
  if (!axiosConfigured) {
    return <Loading />;
  }

  return children ? children : <Outlet />;
}
