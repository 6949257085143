import { useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { authScopes, meScopes } from "components/Auth/authConfig";
import { userAtom, userAtomType } from "recoil/user/atom";
import { stringify } from "query-string";
import { useNavigate } from "react-router-dom";
import { messageAtom } from "recoil/message/atom";
import { mapWsMessageToNotification } from "./apiHelpers";
import { wsMessage } from "./apiTypes";

let ws: WebSocket | undefined = undefined;
/**
 * Sets up an axios instance with token Azure provisioning
 * @returns a configured axios instance with initiated flag
 * @category Hook
 */
export const useSetup = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [, setNotification] = useRecoilState(notificationAtom);
  const [, setMessage] = useRecoilState(messageAtom);

  /**
   * Logs out of the application
   */
  const logout = useCallback(() => {
    instance.logoutRedirect({ account, onRedirectNavigate: () => false });
    navigate("/");
  }, [account, instance, navigate]);

  /**
   * Handle generic token error
   */
  const genericErrorHandler = useCallback(() => {
    setNotification({
      label: `Could not fetch the token, logging out`,
      status: notificationStatusEnum.ALERT,
    });
    logout();
  }, [logout, setNotification]);

  /**
   * Gets the appropriate Azure token
   *
   * If the token acquisition fails, logs out
   *
   * @param scopes - a list of Azure scopes
   * @returns an Azure access token
   */
  const aquireToken = useCallback(
    async (scopes: string[]) => {
      try {
        const token = await instance.acquireTokenSilent({
          scopes,
          account: account ?? undefined,
        });

        return token;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          genericErrorHandler();
        }
      }
    },
    [account, genericErrorHandler, instance]
  );

  const axiosInstance = useMemo(async () => {
    if (process.env.REACT_APP_BACKEND_ENDPOINT) {
      if (isAuthenticated) {
        const localAxiosInstance = axios.create({
          baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
          timeout: 50000,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          paramsSerializer: function (params) {
            return stringify(params, { skipNull: true });
          },
        });

        localAxiosInstance.interceptors.request.use(async (config) => {
          const token = await aquireToken(authScopes);
          if (token) {
            config.headers = {
              ...config.headers,
              Authorization: `${token.tokenType} ${token.accessToken}`,
            };
          }
          return config;
        });

        localAxiosInstance.interceptors.response.use(
          function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
          },
          function (error) {
            if (axios.isAxiosError(error)) {
              if (!axios.isCancel(error)) {
                setNotification({
                  label:
                    error?.message ??
                    `Server error ${error.code ? error.code : ""}`,
                  body: error.response?.data?.detail,
                  status: notificationStatusEnum.ALERT,
                });

                if (
                  error.response &&
                  (error.response.status === 403 ||
                    error.response.status === 401)
                ) {
                  logout();
                }
              }
            } else {
              if (error.code || error.message) {
                setNotification({
                  label: `Unexpected error occured`,
                  status: notificationStatusEnum.ALERT,
                });
              }
            }
            return Promise.reject(error);
          }
        );

        return localAxiosInstance;
      }
    } else {
      setNotification({
        label: `Backend endpoint is not configured`,
        body: `Please contact support to solve this issue`,
        status: notificationStatusEnum.ALERT,
      });
    }
  }, [isAuthenticated, aquireToken, setNotification, logout]);

  useEffect(() => {
    if (accounts[0]) {
      instance.setActiveAccount(accounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, accounts[0]]);

  useEffect(() => {
    /**
     * Fetches information about the current user and sets it as a global state
     */
    const setGlobalUser = async () => {
      const axiosInstanceResolved = await axiosInstance;
      if (!user.cwid && account && isAuthenticated && axiosInstanceResolved) {
        const token = await aquireToken(meScopes);
        const localUserInfo: userAtomType = {};

        try {
          const { data } = await axiosInstanceResolved.get(`me`);
          localUserInfo.id = data.id;
          localUserInfo.cwid = data.username.toLocaleLowerCase();
          localUserInfo.email = data.email;
          localUserInfo.fullName = data.full_name;
          localUserInfo.sysRole = data.sys_role;
        } catch (error) {
          setNotification({
            label: `User error`,
            body: "Could not fetch the user info, logging out",
          });
          logout();
        }

        if (token) {
          try {
            const userPhoto = await axios.get(
              "https://graph.microsoft.com/beta/me/photo/$value",
              {
                responseType: "blob",
                headers: {
                  Authorization: `${token.tokenType} ${token.accessToken}`,
                },
              }
            );

            localUserInfo.photo = userPhoto.data;
          } catch (error: any) {
            if (error.code !== 404) {
              setNotification({
                label: `Azure photo error`,
                body: "Could not fetch the use photo",
              });
            }
          }
        }
        setUser(localUserInfo);
      }
    };
    setGlobalUser();
  }, [
    account,
    aquireToken,
    axiosInstance,
    isAuthenticated,
    logout,
    setNotification,
    setUser,
    user.cwid,
  ]);

  const websocket: WebSocket | undefined = useMemo(() => {
    const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT_WS;
    if (isAuthenticated && user && user.cwid && endpoint) {
      if (ws) return ws;

      const wsUrl = `${endpoint}${user.cwid}`;
      ws = new WebSocket(wsUrl);
      ws.onopen = function (evt) {
        setMessage([]);
        aquireToken(authScopes).then((token) => {
          const json = JSON.stringify({ token: token?.accessToken });
          if (ws) ws.send(json);
        });
      };
      ws.onclose = () => {
        setNotification({
          label: "No connection to notify server",
          status: notificationStatusEnum.ALERT,
        });
        setMessage([]);
      };
      ws.onerror = () => {
        setNotification({
          label: "Error connection to notify server",
          status: notificationStatusEnum.ALERT,
        });
        setMessage([]);
      };
      ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        setNotification(mapWsMessageToNotification(data as wsMessage));
        setMessage((msg) => [...msg.filter((m) => m.id !== data.id), data]);
      };
      return ws;
    }
  }, [!!isAuthenticated, user, user.cwid]);

  return {
    axiosInstance,
    initiated: isAuthenticated && user.cwid && account,
    wsState: websocket?.readyState,
  };
};
