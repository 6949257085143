import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { FC } from "react";
import { controls } from "./ControlsConfig";
import { EditorControlsProps } from "./richTextEditorProps";

/**
 * Renders controls for {@link RichTextEditor| 'RichTextEditor'}
 *
 * @category Component
 */
export const EditorControls: FC<EditorControlsProps> = ({
  editor,
  children,
  Cancel,
  onClick,
}) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="container flex flex-col self-center border-2 border-gray-200 rounded-xl pt-4 pb-2">
      <div className="px-4 flex gap-1">
        {controls.map((control) => (
          <Button
            key={control.name}
            style={
              editor.isActive(control.name, control.variant)
                ? accentStyleEnum.SIMPLE_ALT
                : accentStyleEnum.SIMPLE
            }
            onClick={() => {
              control.action(editor.chain());
            }}
          >
            {control.icon}
          </Button>
        ))}
      </div>
      <div className="px-4">{children}</div>
      <div className="border-t-2 border-gray-200">
        <div className="flex flex-row-reverse gap-4 pt-2 px-4">
          <Button
            className="w-fit"
            style={accentStyleEnum.SALMON}
            onClick={onClick}
            disabled={editor.getText() === ""}
          >
            Post comment
          </Button>
          {Cancel}
        </div>
      </div>
    </div>
  );
};
