import { useMemo } from "react";
import {
  DocumentIcon,
  IdentificationIcon,
  CogIcon,
  ChatIcon,
} from "@heroicons/react/solid";
import { FilterTag } from "atoms/FilterTag/FilterTag";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { NavTabs } from "atoms/NavTabs/NavTabs";
import { NavTabProps } from "atoms/NavTabs/navTabsTypes";
import { ModelInfoProps } from "./modelInfoTypes";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { Link } from "react-router-dom";
import { user, userSysRoleEnum } from "api/apiTypes";
import { DuplicateIcon } from "@heroicons/react/outline";
import { notificationAtom } from "recoil/notification/atom";
import useAxios from "axios-hooks";
import { model } from "api/apiTypes";
import { ModelTip } from "./ModelTip/ModelTip";
import { LikeButton } from "atoms/LikeButton/LikeButton";

/**
 * Renders a model information card with the navigation bar.
 *
 * Card includes related to model information:
 * - Name
 * - Owner
 * - Tags
 * - Languages
 * - Libraries
 *
 * Navigation bar links to:
 * - Model information
 * - Model Discussion
 * - Model Files
 * - Model Settings
 *
 * @category Component
 */
export function ModelInfo({
  name,
  owner,
  tasks,
  tags,
  languages,
  libraries,
  collaborations,
  thumbs,
  onModelUpdate,
}: ModelInfoProps) {
  const [user] = useRecoilState(userAtom);
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, updateModel] = useAxios<model>(
    {
      url: `models/${owner}/${name}`,
      method: "PATCH",
    },
    { manual: true }
  );

  const [{ data: userData }] = useAxios<user>({ url: `users/${user.cwid}` });

  const navTabs: NavTabProps[] = useMemo(() => {
    const navTabsLocal = [
      {
        label: "Model Info",
        icon: IdentificationIcon,
        link: `/models/${owner}/${name}`,
      },
      {
        label: "Files",
        icon: DocumentIcon,
        link: `/models/${owner}/${name}/files/`,
        pathToMatch: `/models/${owner}/${name}/files/*`,
      },
      {
        label: "Discussion",
        icon: ChatIcon,
        link: `/models/${owner}/${name}/discussion/`,
      },
    ];
    if (
      userData?.sys_role === userSysRoleEnum.ADMINISTRATOR ||
      (user.cwid &&
        collaborations.some(
          (account) =>
            account.username === user.cwid &&
            ["owner", "maintainer"].includes(account.model_role)
        )) ||
      userData?.organizations?.some(
        (org) =>
          ["owner", "maintainer"].includes(org.org_role) &&
          collaborations.some(
            (account) =>
              account.id === org.org_id &&
              ["owner", "maintainer"].includes(account.model_role)
          )
      )
    ) {
      navTabsLocal.push({
        label: "Settings",
        icon: CogIcon,
        link: `/models/${owner}/${name}/settings/`,
      });
    }
    return navTabsLocal;
  }, [
    name,
    owner,
    collaborations,
    user.cwid,
    userData?.sys_role,
    userData?.organizations,
  ]);

  const copyName = () => {
    navigator.clipboard.writeText(`${owner}/${name}`);
    setNotification({
      label: "Model name has been copied to the clipboard",
    });
  };

  const onThumb = async (liked: boolean) => {
    try {
      await updateModel({
        data: liked
          ? {
              remove_like_user: [user.cwid],
            }
          : {
              add_like_user: [user.cwid],
            },
      });
    } finally {
      onModelUpdate();
    }
  };

  return (
    <div className="w-full bg-slate-50 border-b-2 border-gray-100">
      <div className="container mx-auto px-4 pt-4">
        <div className="text-lg mb-4 flex justify-between items-center">
          <div className="flex gap-2">
            <p className="font-bold">
              <span className="font-semibold text-gray-400">{owner}/</span>
              {name}
            </p>
            <button className="hover:opacity-50" onClick={() => copyName()}>
              <DuplicateIcon className="h-5 w-5" />
            </button>
            <LikeButton likes={thumbs} onLike={onThumb} />
          </div>
          <div className=" flex-wrap flex gap-2 mb-4">
            {collaborations.map((account, index) => (
              <Link
                key={index}
                to={
                  account.is_organization
                    ? `/organizations/${account.username}`
                    : `/profile/${account.username}`
                }
                className={`${
                  accentStyles[
                    accentStyleEnum[
                      account.is_organization ? "VIOLET" : "SIMPLE"
                    ]
                  ].bgColor
                } mt-1 py-0.5 px-2 text-sm ${
                  accentStyles[
                    accentStyleEnum[
                      account.is_organization ? "VIOLET" : "SIMPLE"
                    ]
                  ].textColorContrast
                } rounded-md w-fit`}
              >
                {account.username}
              </Link>
            ))}
          </div>
        </div>

        <div className="text-ellipsis overflow-hidden flex-wrap flex gap-2">
          {tasks.map((task) => {
            return (
              <FilterTag
                key={task}
                label={task}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.VIOLET}
              />
            );
          })}
          {tags.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.CADET}
              />
            );
          })}
          {libraries.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.SALMON}
              />
            );
          })}
          {languages.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.YELLOW}
              />
            );
          })}
        </div>
        <div className="flex items-end justify-between">
          {navTabs && <NavTabs tabs={navTabs} />}
          <ModelTip modelName={name} modelOwner={owner} />
        </div>
      </div>
    </div>
  );
}
