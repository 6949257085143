import { atom } from "recoil";
import { wsMessage } from "api/apiTypes";

/**
 * Enum for message statuses
 *
 * @category Type
 * @enum
 */
export enum messageStatusEnum {
  ALERT,
  SUCCESS,
  ATTENTION,
}

/**
 * Type for {@link messageAtomType | 'messageAtomType'}
 *
 * @category Type
 */
export type messageAtomType = wsMessage[];

/**
 * Recoil message atom
 */
export const messageAtom = atom<messageAtomType>({
  key: "message",
  default: [],
});
