export function Collaborators() {
  return (
    <div className="pt-40 container mx-auto py-8">
      <div className="text-center">
        <p className="text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Collaborators
        </p>
        <p className="mt-4 flex gap-6 text-xl text-gray-500 justify-center sm:text-2xl">
          <a href="https://bayergroup.sharepoint.com/sites/DrivingDataasanAsset/SitePages/S@S%20Landing%20Page%20(Level1).aspx">
            <button
              type="button"
              className="inline-flex text-2xl items-center px-10 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-deepmind-green-between hover:bg-deepmind-between focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-blue"
            >
              Science@Scale
            </button>
          </a>
          <a href="https://linguist.skgt.int.bayer.com/">
            <button
              type="button"
              className="inline-flex text-2xl items-center px-10 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-deepmind-green-between hover:bg-deepmind-between focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-blue"
            >
              Linguist
            </button>
          </a>
          <a href="https://wiki.intranet.cnb/display/DECSCI/First+contact+NLP@scale">
            <button
              type="button"
              className="inline-flex text-2xl items-center px-10 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-deepmind-green-between hover:bg-deepmind-between focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-blue"
            >
              NLP@Scale
            </button>
          </a>
          <a href="https://diana.int.bayer.com/">
            <button
              type="button"
              className="inline-flex text-2xl items-center px-10 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-deepmind-green-between hover:bg-deepmind-between focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-blue"
            >
              Diana
            </button>
          </a>
        </p>
      </div>
    </div>
  );
}
