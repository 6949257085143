import useAxios from "axios-hooks";
import { Button } from "atoms/Button/Button";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum } from "atoms/genericStyles";
import { Modal } from "atoms/Modal/Modal";
import { FieldArray, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  editOrganizationModalFormProps,
  EditOrganizationModalProps,
} from "./editOrganizationModalTypes";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { Dropdown } from "atoms/Dropdown/Dropdown";

const ORG_ROLE_OPTIONS = ["user", "owner", "maintainer"];
const ORG_ROLE_DEFAULT = "user";

/**
 * Renders a modal for organization edit
 *
 * @category Component
 */
export function EditOrganizationModal({
  open,
  setOpen,
  username,
  full_name,
  users,
}: EditOrganizationModalProps) {
  const navigate = useNavigate();
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, execute] = useAxios(
    { url: `orgs/${username}`, method: "PATCH" },
    { manual: true }
  );

  const initialValues: editOrganizationModalFormProps = {
    fullName: full_name,
    members: users.map((user) => ({
      role: user.org_role,
      username: user.user?.username,
      fullName: user.user?.full_name,
    })),
  };

  const handleSubmit = async (values: editOrganizationModalFormProps) => {
    const members = values.members.filter((m) => m.username.length === 5);
    const addedUsers = members
      .filter(
        (member) =>
          !users.some(
            (user) =>
              user.org_role === member.role &&
              user.user.username === member.username
          )
      )
      .map((member) => ({ [member.username]: member.role }));
    const deletedUsers = users
      .filter(
        (user) =>
          !members.some((member) => user.user.username === member.username)
      )
      .map((user) => user.user.username);
    try {
      await execute({
        data: {
          add_user_name: addedUsers,
          remove_user_name: deletedUsers,
        },
      });
      setNotification({
        label: `Organization was updated successfully`,
        status: notificationStatusEnum.SUCCESS,
      });
    } finally {
      setOpen(false);
      navigate(0);
    }
  };

  return (
    <Modal className="max-w-2xl" open={open} setOpen={setOpen}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting }) => {
          return (
            <Form className="">
              <Input
                id="name"
                name="fullName"
                label="Organization name"
                disabled
              />
              <p className="font-semibold text-sm mt-4">Users</p>
              <div className="grid grid-cols-1 gap-4 mt-2">
                {values.members && values.members.length > 0 && (
                  <FieldArray
                    name="members"
                    render={(arrayHelpers) => (
                      <div className="flex flex-col gap-4">
                        {values.members.map((member, index) => (
                          <div key={`member-${index}`} className="flex gap-2">
                            <Input
                              placeholder="User name"
                              name={`members.${index}.username`}
                              disabled={users.some(
                                (u) => u.user.username === member.username
                              )}
                            ></Input>
                            <Dropdown
                              className="w-full min-w-[100px] h-10"
                              placeholder="Select model task"
                              style={accentStyleEnum.SIMPLE}
                              value={{ value: member.role, label: member.role }}
                              options={ORG_ROLE_OPTIONS.map((value) => {
                                return { label: value, value };
                              })}
                              onChange={(value) => {
                                arrayHelpers.replace(index, {
                                  ...member,
                                  role: value.value,
                                });
                              }}
                              disabled={
                                member.username.length === 5 &&
                                member.role === "owner" &&
                                values.members.filter(
                                  (m) =>
                                    m.role === "owner" &&
                                    m.username.length === 5
                                ).length === 1
                              }
                            />
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              style={accentStyleEnum.SALMON}
                              disabled={
                                member.username.length === 5 &&
                                member.role === "owner" &&
                                values.members.filter(
                                  (m) =>
                                    m.role === "owner" &&
                                    m.username.length === 5
                                ).length === 1
                              }
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                        <Button
                          type="button"
                          className="w-full"
                          onClick={() =>
                            arrayHelpers.insert(values.members.length, {
                              username: "",
                              role: ORG_ROLE_DEFAULT,
                            })
                          }
                          style={accentStyleEnum.VIOLET}
                        >
                          <PlusIcon className="h-4 w-4" />
                        </Button>
                      </div>
                    )}
                  />
                )}
              </div>
              <Button
                type="submit"
                className="w-full mt-4 justify-center"
                style={accentStyleEnum.SIMPLE}
                loading={isSubmitting}
                disabled={
                  isSubmitting ||
                  (values.members.length === users.length &&
                    values.members.every((member) =>
                      users.some(
                        (u) =>
                          u.user.username === member.username &&
                          u.org_role === member.role
                      )
                    ))
                }
              >
                Update organization
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
