import { useState } from "react";
import logo from "assets/logo/logo_raw.png";
import { getRandomTextAccentColor } from "utils";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { FeatureCards } from "./FeatureCards";
import { GraphSection } from "./GraphSection";
import { GetStarted } from "./GetStarted";
import { Collaborators } from "./Collaborators";
import { AnimatedText } from "./AnimationTitle";

/**
 * Renders a home page
 *
 * @category Component
 */
export function Home() {
  const [titleColor, setTitleColor] = useState<string>(
    getRandomTextAccentColor()
  );

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  return (
    <div className="bg-white grow overflow-auto">
      <div className="mt-12 container mx-auto flex flex-col items-center">
        <img className="h-80 w-80" alt="deepmind" src={logo} />
        <div className="flex flex-col items-center">
          <span className="text-7xl pb-7 font-bold text-transparent bg-clip-text bg-gradient-to-r from-deepmind-blue to-deepmind-between hover:from-deepmind-between hover:to-deepmind-green">
            DeepMind
          </span>
        </div>
        {!isAuthenticated && (
          <div className="my-4 flex justify-center">
            <button
              onClick={() => {
                instance.loginRedirect();
              }}
              className="inline-flex items-center px-10 py-2 border border-transparent text-sm text-2xl rounded-full shadow-sm text-white bg-deepmind-between hover:bg-deepmind-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-dark-blue"
            >
              LOGIN
            </button>
          </div>
        )}
      </div>
      <div className="my-4 grid grid-cols-1">
        <AnimatedText />
        <FeatureCards />
        <GraphSection />
        <GetStarted />
        <Collaborators />
      </div>
    </div>
  );
}
