import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { useFormikContext } from "formik";
import { genericFormProps } from "genericTypes";
import { useState } from "react";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

function DynamicText(
  props: IWidgetNestedProps & {
    onChange: (text: string) => void;
    value: string | undefined;
  }
) {
  const { disabled, placeholder } = props;
  return (
    <input
      placeholder={placeholder}
      className="mt-2 w-full h-10 pl-4 pr-12 text-slate-600 sm:text-sm border-gray-300 rounded-md rounded-lg shadow-sm"
      disabled={disabled}
      value={props.value ?? ""}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}

/**
 * Renders multiple dynamic text input components for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetDynamicTexts(props: IWidgetNestedProps) {
  const { name, label, disabled } = props;
  const { setFieldValue, values } = useFormikContext<genericFormProps>();
  const [sentenceAmount, setSentenceAmount] = useState(3);

  const handleChange = (id: number, value: string) => {
    setFieldValue(name, {
      ...(values[name] as Record<number, string>),
      [id]: value,
    });
  };

  return (
    <div data-testid="widget-dynamictexts-input">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      {Array.from({ length: sentenceAmount }, (_, index) => index).map((i) => {
        return (
          <DynamicText
            key={`dynamic-text-${i}`}
            {...props}
            onChange={(value) => handleChange(i, value)}
            value={
              (values[name] as Record<number, string | undefined>)
                ? (values[name] as Record<number, string | undefined>)[i]
                : undefined
            }
          />
        );
      })}
      <Button
        type="button"
        disabled={disabled || sentenceAmount === 5}
        style={accentStyleEnum.SIMPLE}
        className="h-10 mt-2 w-full"
        onClick={() => setSentenceAmount(sentenceAmount + 1)}
      >
        Add sentence
      </Button>
    </div>
  );
}
