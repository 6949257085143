import { useEffect, useState } from "react";
import { useSetup } from "api/useSetup";
import { configure } from "axios-hooks";
import { Header } from "components/Header/Header";
import { Route, Routes } from "react-router-dom";
import { ModelList } from "components/ModelList/ModelList";
import { ModelDetails } from "components/Model/ModelDetails/ModelDetails";
import { NotFound } from "components/NotFound";
import { Home } from "components/Home/Home";
import { ModelFiles } from "components/Model/ModelFiles";
import { Profile } from "components/Profile/Profile";
import { NotificationBlock } from "components/NotificationBlock";
import { RequireAuth } from "components/Auth/RequireAuth";
import { ModelNew } from "components/Model/ModelNew/ModelNew";
import { OrganizationNew } from "components/Organization/OrganizationNew";
import { Footer } from "components/Footer";
import { ModelSettings } from "components/Model/ModelSettings/ModelSettings";
import { Organization } from "components/Organization/Organization";
import { Error } from "components/Error";
import { ModelDiscussion } from "components/Model/ModelDiscussion/ModelDiscussion";
import { OrganizationList } from "components/OrganizationList/OrganizationList";

export function App() {
  const { axiosInstance, initiated, wsState } = useSetup();
  const [axiosConfigured, setAxiosConfigured] = useState(false);

  useEffect(() => {
    const configureAxios = async () => {
      if (initiated) {
        const finalInstance = await axiosInstance;
        configure({ axios: finalInstance });
        setAxiosConfigured(true);
      }
    };
    configureAxios();
  }, [initiated, axiosInstance]);
  return (
    <div className="bg-white relative min-h-screen flex flex-col">
      <NotificationBlock />
      <Header websocketReady={wsState === 1} />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route element={<RequireAuth axiosConfigured={axiosConfigured} />}>
          <Route path="models" element={<ModelList />} />
          <Route path="models/new/" element={<ModelNew />} />
          <Route path="models/:owner/:model/" element={<ModelDetails />} />
          <Route
            path="models/:owner/:model/discussion"
            element={<ModelDiscussion />}
          />
          <Route path="models/:owner/:model/files/" element={<ModelFiles />}>
            <Route path=":branch/*" element={<ModelFiles />} />
            <Route path="" element={<ModelFiles />} />
          </Route>
          <Route
            path="models/:owner/:model/settings"
            element={<ModelSettings />}
          />
          <Route path="profile/:profileId" element={<Profile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="organizations" element={<OrganizationList />} />
          <Route path="organizations/new/" element={<OrganizationNew />} />
          <Route path="organizations/:orgName" element={<Organization />} />
          <Route path="/error/:code" element={<Error />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}
