import { ModelListFilters } from "./ModelListFilters";
import { ModelListModels } from "./ModelListModels";

/**
 * Renders a models page
 *
 * @category Component
 */
export function ModelList() {
  return (
    <div className="grow container mx-auto relative flex flex-row bg-white">
      <ModelListFilters />
      <ModelListModels />
    </div>
  );
}
