import { useEffect } from "react";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Loading } from "atoms/Loading/Loading";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";
import { TextPreviewProps } from "./filePreviewTypes";
import { Markdown } from "atoms/Markdown/Markdown";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

// list from https://github.com/microsoft/monaco-editor/tree/main/src/basic-languages
const suffix2type: Record<string, string> = {
  ts: "typeScript",
  typescript: "typeScript",
  js: "javaScript",
  javascript: "javaScript",
  css: "css",
  less: "less",
  scss: "scss",
  json: "json",
  html: "html",
  xml: "xml",
  php: "php",
  cs: "csharp",
  csharp: "csharp",
  cpp: "cpp",
  razor: "razor",
  markdown: "markdown",
  md: "markdown",
  java: "java",
  vb: "vb",
  coffeescript: "coffeescript",
  handlebars: "handlebars",
  bat: "bat",
  pug: "pug",
  fs: "fsharp",
  fsharp: "fsharp",
  lua: "lua",
  powershell: "powershell",
  ps: "powershell",
  python: "python",
  py: "python",
  ruby: "ruby",
  sass: "sass",
  r: "r",
  m: "objective-c",
  "objective-c": "objective-c",
  "": "",
};

export function TextPreview({ size }: TextPreviewProps) {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const branchName = urlParams["branch"];
  const gitPath = urlParams["*"];
  const fileSuffix = gitPath?.split(".").slice(-1)[0].toLowerCase() || "";

  const [, setNotification] = useRecoilState(notificationAtom);

  const [
    { loading: isLoadingFile, data: fetchedFile, error: fileError },
    fetchFile,
  ] = useAxios<string>(
    {
      url: `models/${modelOwner}/${modelName}/${branchName}/download?filepath=${gitPath}`,
      transformResponse: [],
    },
    { manual: true }
  );

  useEffect(() => {
    fetchFile();
  }, [fetchFile]);

  useEffect(() => {
    if (fileError)
      setNotification({
        label: `Server error: ${fileError.code}`,
        body: "Could not load file",
      });
  }, [fileError]);

  if (isLoadingFile) {
    return (
      <div className="h-auto min-w-[246px] p-4 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
        {size && size / 1024 / 1024 > 1 ? (
          <Loading text="previewing large file may take longer time" />
        ) : (
          <Loading />
        )}
      </div>
    );
  }

  return (
    <div className="grow">
      {fetchedFile && (
        <Editor
          className="grow"
          value={fetchedFile}
          language={suffix2type[fileSuffix] || fileSuffix}
          options={{ readOnly: true }}
        />
      )}
    </div>
  );
}
