import { UserGroupIcon } from "@heroicons/react/solid";
import { SparklesIcon } from "@heroicons/react/solid";
import { organizationDetailed, userSysRoleEnum } from "api/apiTypes";
import { AccessToken } from "atoms/AccessToken/AccessToken";
import { Button } from "atoms/Button/Button";
import { accentStyles, accentStyleEnum } from "atoms/genericStyles";
import { Loading } from "atoms/Loading/Loading";
import useAxios from "axios-hooks";
import { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/user/atom";
import { DeleteOrganizationModal } from "./DeleteOrganizationModal.tsx/DeleteOrganizationModal";
import { EditOrganizationModal } from "./EditOrganizationModal/EditOrganizationModal";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";

/**
 * Renders an organization information strip for {@link Organization | 'Organization'}
 *
 * @category Component
 */
export function OrganizationInfo() {
  const { orgName } = useParams();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [user] = useRecoilState(userAtom);
  const [, setNotification] = useRecoilState(notificationAtom);
  const [
    { loading: isLoadingOrgInfo, data: fetchedOrg, error: orgError },
    fetchOrg,
  ] = useAxios<organizationDetailed>({}, { manual: true });
  const [, createToken] = useAxios(
    {
      method: "POST",
      url: `api_token/${orgName}`,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (orgName) {
      fetchOrg({ url: `orgs/${orgName}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrg, orgName]);
  if (orgError) {
    return <Navigate to="/404" replace />;
  }
  if (!fetchedOrg || isLoadingOrgInfo) {
    return (
      <div className="flex h-auto min-w-[246px] p-4 w-3/12 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start gap-4 flex-col">
        <Loading />
      </div>
    );
  }

  const handleRefreshToken = async () => {
    await createToken();
    fetchOrg({ url: `orgs/${orgName}` });
    setNotification({
      label: "User API token successfully refreshed",
      status: notificationStatusEnum.SUCCESS,
    });
  };

  const orgOwner = fetchedOrg.users.filter((u) => u.org_role === "owner");
  const orgMaintainer = fetchedOrg.users.filter(
    (u) => u.org_role === "maintainer"
  );
  const orgUser = fetchedOrg.users.filter((u) => u.org_role === "user");
  const isOwner = orgOwner.some((u) => u.user_id === user.id);
  const isMaintainer = orgMaintainer.some((u) => u.user_id === user.id);
  const isUser = orgUser.some((u) => u.user_id === user.id);

  return (
    <div className="flex h-auto min-w-[246px] p-4 w-3/12 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start gap-4 flex-col">
      <div>
        <p className="font-bold text-xl">{fetchedOrg.full_name}</p>
        <p className="text-sm">{orgName}</p>
      </div>
      <div>
        <div className="text-md font-semibold flex gap-2 items-center">
          <UserGroupIcon
            className={`w-4 h-4 ${
              accentStyles[accentStyleEnum.CADET].textColor
            }`}
          />
          Owner
        </div>
        <p className={`text-neutral-600 flex gap-2`}>
          {orgOwner.length > 0 ? (
            orgOwner.map((user, index) => {
              return (
                <Link
                  key={`org-owner-${index}`}
                  to={`/profile/${user.user.username}`}
                  className={`${
                    accentStyles[accentStyleEnum.VIOLET].bgColor
                  } mt-1 py-0.5 px-2 text-sm ${
                    accentStyles[accentStyleEnum.VIOLET].textColorContrast
                  } rounded-md w-fit`}
                >
                  {user.user.full_name}
                </Link>
              );
            })
          ) : (
            <span className="text-sm italic">None yet</span>
          )}
        </p>
      </div>
      <div>
        <div className="text-md font-semibold flex gap-2 items-center">
          <UserGroupIcon
            className={`w-4 h-4 ${
              accentStyles[accentStyleEnum.CADET].textColor
            }`}
          />
          Users
        </div>
        <p className={`text-neutral-600 flex gap-2`}>
          {orgMaintainer.map((user, index) => (
            <Link
              key={`org-maintainer-${index}`}
              to={`/profile/${user.user.username}`}
              className={`${
                accentStyles[accentStyleEnum.VIOLET].bgColor
              } mt-1 py-0.5 px-2 text-sm ${
                accentStyles[accentStyleEnum.VIOLET].textColorContrast
              } rounded-md w-fit`}
            >
              {user.user.full_name}
            </Link>
          ))}
          {orgUser.map((user, index) => (
            <Link
              key={`org-user-${index}`}
              to={`/profile/${user.user.username}`}
              className={`${
                accentStyles[accentStyleEnum.VIOLET].bgColor
              } mt-1 py-0.5 px-2 text-sm ${
                accentStyles[accentStyleEnum.VIOLET].textColorContrast
              } rounded-md w-fit`}
            >
              {user.user.full_name}
            </Link>
          ))}
          {orgMaintainer.length === 0 && orgUser.length === 0 && (
            <span className="text-sm italic">None yet</span>
          )}
        </p>
      </div>
      {fetchedOrg.research_interests && (
        <div>
          <div className="text-md font-semibold flex gap-2 items-center">
            <SparklesIcon
              className={`w-4 h-4 ${
                accentStyles[accentStyleEnum.CADET].textColor
              }`}
            />
            Research interests
          </div>
          <p className={`text-neutral-600`}>{fetchedOrg.research_interests}</p>
        </div>
      )}
      {user.cwid &&
        (user.sysRole === userSysRoleEnum.ADMINISTRATOR ||
          isOwner ||
          isMaintainer) && (
          <AccessToken
            token={fetchedOrg.api_token}
            until={fetchedOrg.api_token_expiration_date}
            onRefresh={handleRefreshToken}
          />
        )}
      {user.cwid && isUser && (
        <AccessToken
          token={fetchedOrg.api_token}
          until={fetchedOrg.api_token_expiration_date}
        />
      )}
      {user.cwid &&
        (user.sysRole === userSysRoleEnum.ADMINISTRATOR ||
          isOwner ||
          isMaintainer) && (
          <div className="w-full flex flex-col gap-4">
            <Button
              className="w-full"
              style={accentStyleEnum.VIOLET}
              onClick={() => {
                setEditModalOpen(true);
              }}
            >
              Edit organization
            </Button>
            <Button
              className="w-full"
              style={accentStyleEnum.SALMON}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              Delete organization
            </Button>
          </div>
        )}
      <EditOrganizationModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        {...fetchedOrg}
      />
      <DeleteOrganizationModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        {...fetchedOrg}
      />
    </div>
  );
}
