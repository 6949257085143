import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
// import { buttonStyleEnum } from "atoms/Button/buttonTypes";
import { notificationStatusEnum } from "recoil/notification/atom";
import { NotificationProps } from "./notificationTypes";

/**
 * Renders a notification
 * @category Component
 */
export function Notification({
  label,
  body,
  status,
  progress,
  showX = false,
  onXClick,
  onClick,
}: NotificationProps) {
  return (
    <div className="relative rounded-lg w-full px-4 py-3" onClick={onClick}>
      <div className="flex gap-2 items-center">
        {status !== undefined && (
          <div className="">
            {status === notificationStatusEnum.ALERT && (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-600"
                aria-hidden="true"
              />
            )}
            {status === notificationStatusEnum.ATTENTION && (
              <ExclamationIcon
                className="h-5 w-5 text-yellow-500"
                aria-hidden="true"
              />
            )}
            {status === notificationStatusEnum.SUCCESS && (
              <CheckCircleIcon
                className="h-5 w-5 text-green-600"
                aria-hidden="true"
              />
            )}
          </div>
        )}
        <div className="whitespace-normal break-all pr-4">
          {label && (
            <p className="font-semibold text-sm text-gray-900">{label}</p>
          )}
          {body && <p className="font-medium text-xs text-gray-500">{body}</p>}
        </div>
      </div>
      {progress !== undefined && (
        <div className="mt-1 w-full rounded-xl border h-2 bg-gray-200 w-full">
          <div
            className="rounded-xl flex justify-end items-center h-full bg-green-500 animate-pulse relative"
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </div>
      )}
      {showX && (
        <Button
          type="button"
          style={accentStyleEnum.SIMPLE}
          className="absolute top-3 right-3 px-0 py-0 mb-auto"
          onClick={onXClick}
        >
          <XIcon className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
}
