import {
  IWidgetNestedCustomOutputProps,
  iWidgetScoreTextValue,
} from "../interfaceWidgetTypes";

/**
 * Renders a score output component with text for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetScoreText(
  props: IWidgetNestedCustomOutputProps<iWidgetScoreTextValue>
) {
  const { label, placeholder, value } = props;

  if (!value) {
    <div className="text-sm font-medium text-gray-500">
      {placeholder ? placeholder : "No value was provided"}
    </div>;
  }

  return (
    <div data-testid="widget-scoretext-output">
      <div className="p-4 bg-white rounded-sm flex justify-between items-center">
        <div className="text-base">{value?.text}</div>
        <div className="text-mono text-sm">{value?.score.toPrecision(3)}</div>
      </div>
      {label && (
        <div className="text-sm text-gray-800 font-mono w-max mt-1">
          {label}
        </div>
      )}
    </div>
  );
}
