import { OrganizationGrid } from "./OrganizationGrid";

/**
 * Renders a organization page
 *
 * @category Component
 */
export function OrganizationList() {
  return (
    <div className="grow container mx-auto relative flex flex-row bg-white">
      <OrganizationGrid />
    </div>
  );
}
