import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { shouldRenderPageButton } from "./paginationHelpers";
import { PaginationProps } from "./paginationTypes";

let hasRenderedElipsis = false;

/**
 * Renders a pagination component
 *
 * @category Component
 */
export const Pagination = ({
  pages = [],
  activePage,
  bounds = 2,
  onPageSelect,
  onPrevPage,
  onNextPage,
}: PaginationProps) => (
  <nav
    className="relative z-0 p-4 inline-flex rounded-md -space-x-px"
    aria-label="Pagination"
  >
    <button
      onClick={onPrevPage}
      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
    >
      <span className="sr-only">Previous</span>
      <ChevronLeftIcon className="h-4 w-4" />
    </button>

    {pages.map((page, index) => {
      if (shouldRenderPageButton(page, pages.length, bounds, activePage)) {
        hasRenderedElipsis = false;
        return (
          <button
            key={`page-${index}`}
            onClick={onPageSelect(page)}
            className={`z-10 ${
              page === activePage
                ? "border-salmon-500 text-salmon-700 z-20"
                : "border-gray-300 text-gray-500"
            } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
          >
            {page}
          </button>
        );
      } else if (!hasRenderedElipsis) {
        hasRenderedElipsis = true;
        return (
          <button
            key={`page-${index}`}
            className="z-10 bg-indigo-50 border-indigo-300 text-indigo-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            ...
          </button>
        );
      }
      return null;
    })}
    <button
      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      onClick={onNextPage}
    >
      <span className="sr-only">Next</span>
      <ChevronRightIcon className="h-4 w-4" />
    </button>
  </nav>
);
