import { Dialog } from "@headlessui/react";
import { DocumentAddIcon, DocumentIcon, XIcon } from "@heroicons/react/solid";
import useAxios from "axios-hooks";
import { Button } from "atoms/Button/Button";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { Input } from "atoms/FormAtoms/Input";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { Modal } from "atoms/Modal/Modal";
import { Form, Formik } from "formik";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom";
import { bytesToHuman } from "utils";
import { uploadModalSchema } from "./uploadModalHelpers";
import { uploadModalFormProps, UploadModalProps } from "./uploadModalTypes";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";

/**
 * Renders a modal for model file upload
 *
 * Requires `model` and `owner` url parameters
 * - model - name of the model
 * - owner - owners cwid
 * @category Component
 */
export function UploadModal({
  availableBranches,
  path,
  open,
  setOpen,
  onUpdate,
}: UploadModalProps) {
  const urlParams = useParams();
  const modelOwner = urlParams["owner"];
  const modelName = urlParams["model"];
  const initialValues: uploadModalFormProps = {
    branch: availableBranches[0] || { value: "", label: "" },
    path: path,
    files: [],
  };
  const [, setNotification] = useRecoilState(notificationAtom);

  const [, requestUpload] = useAxios(
    {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
    }
  );

  const closeAndClear = () => {
    setOpen(false);
  };

  const handleSubmit = async (values: uploadModalFormProps) => {
    try {
      const formData = new FormData();
      values.files.forEach((file) => {
        formData.append("file", file);
      });
      await requestUpload({
        data: formData,
        url: `models/${modelOwner}/${modelName}/${values.branch.value}`,
        params: { path_in_repo: values.path, commit_msg: values.commitMessage },
      });
      setNotification({
        label: "File(s) have been commited sucessfuly",
        status: notificationStatusEnum.SUCCESS,
      });
      if (onUpdate) {
        onUpdate();
      }
    } finally {
      setOpen(false);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen} className="max-w-2xl">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={uploadModalSchema}
        validateOnMount={true}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <Dialog.Title
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900"
            >
              Upload File
            </Dialog.Title>
            <div className="mt-4">
              <div className="mt-1 mb-4 flex gap-4 items-center">
                <Dropdown
                  label="Branch"
                  style={accentStyleEnum.SIMPLE}
                  value={values.branch}
                  options={availableBranches}
                  onChange={(value) => {
                    setFieldValue("branch", value);
                  }}
                ></Dropdown>
                <Input
                  id="path"
                  name="path"
                  label="File path"
                  placeholder='Enter upload path, e.g. "/src/module"'
                />
              </div>
              <Dropzone
                maxSize={1000000000}
                onDrop={(acceptedFiles) => {
                  const mergedFiles = [
                    ...values.files,
                    ...acceptedFiles.filter(
                      (file) =>
                        !values.files.some(
                          (valueFile) => valueFile.name === file.name
                        )
                    ),
                  ];
                  setFieldValue("files", mergedFiles, true);
                }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className={`rounded-lg border-2 border-dashed border-slate-500 min-h-[64px] w-full p-4 mt-2 flex flex-col gap-4 text-sm justify-center items-center ${
                        isDragActive
                          ? "text-slate-700 bg-slate-100"
                          : "text-slate-500"
                      }`}
                    >
                      <input {...getInputProps()} />
                      <DocumentAddIcon className="h-16 w-16" />
                      <div>
                        <p className="text-center">
                          Upload or drag and drop your files
                        </p>
                        <p className="text-center text-xs">
                          (max size 1GB, use git server or python api to upload
                          more)
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <Input
              as="textarea"
              containerClass="mt-4"
              className="resize-none h-20"
              id="commitMessage"
              name="commitMessage"
              label="Commit message"
              placeholder="Enter commit message"
            />
            {values.files.length > 0 && (
              <ul className="mt-4">
                {values.files.map((file) => {
                  return (
                    <li
                      key={file.name}
                      className={`py-2 px-4 mt-2 flex justify-between items-center rounded-md text-white ${
                        accentStyles[accentStyleEnum.CADET].bgColor
                      }`}
                    >
                      <div className="flex gap-2 items-center">
                        <DocumentIcon className="w-6 h-6" />
                        <div>
                          <p className="text-sm font-medium">{file.name}</p>
                          <p className="text-xs">
                            {bytesToHuman(file.size)}
                            {file.size > 500000000 && (
                              <span className="px-1 mx-1 rounded-sm bg-orange-400">
                                Using Git or Python api is recommanded for large
                                files
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <button>
                        <XIcon
                          className="w-6 h-6 p-1 rounded-full hover:bg-cadet-50"
                          onClick={() => {
                            setFieldValue(
                              "files",
                              values.files.filter(
                                (innerFile) => innerFile.name !== file.name
                              ),
                              true
                            );
                          }}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="mt-4 flex justify-between">
              <Button
                type="button"
                style={accentStyleEnum.GRAY}
                onClick={closeAndClear}
              >
                Close
              </Button>
              <Button
                type="submit"
                style={accentStyleEnum.CADET}
                disabled={!isValid}
                loading={isSubmitting}
              >
                Commit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
