import { accentStyle } from "atoms/genericStyles";

/**
 * Additional style for {@link FilterTag | 'FilterTag'}.
 */
export const GrayedOutTag: accentStyle = {
  gradientTo: "to-slate-50",
  gradientFrom: "from-slate-50",
  gradientToToned: "to-slate-150",
  gradientFromToned: "from-slate-150",
  bgColor: "bg-slate-100",
  textColor: "text-slate-100",
  textColorContrast: "text-neutral-600",
  borderColor: "transparent",
};
