import { useMemo } from "react";
import {
  IWidgetNestedCustomOutputProps,
  iWidgetTableValue,
} from "../interfaceWidgetTypes";

/**
 * Renders a table output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetTable(
  props: IWidgetNestedCustomOutputProps<iWidgetTableValue>
) {
  const { label, placeholder, value } = props;

  const getTableGrayItem = (item?: number) => {
    return (
      <td
        key={`table-head-${item}`}
        className="bg-gray-100 text-gray-400 pt-1 px-1"
      >
        {item}
      </td>
    );
  };

  const values = value?.values ? JSON.stringify(value?.values) : "[]";

  const topRowItems = useMemo(() => {
    const parsedValues: number[][] = JSON.parse(values);
    const items = [getTableGrayItem()];
    if (parsedValues) {
      let max = 0;
      parsedValues.forEach((value) => {
        if (value.length > max) {
          max = value.length;
        }
      });
      for (let index = 0; index < max; index++) {
        items.push(getTableGrayItem(index));
      }
    }
    return items;
  }, [values]);

  if (!value) {
    return (
      <div className="text-sm font-medium text-gray-500">
        {placeholder ? placeholder : "No value was provided"}
      </div>
    );
  }
  return (
    <div data-testid="widget-table-output">
      <div className="overflow-auto">
        <table className="text-xs font-mono text-right border bg-white">
          <thead>
            <tr>{topRowItems?.map((item) => item)}</tr>
          </thead>
          <tbody>
            {value.values?.map((value, index) => (
              <tr key={`table-${value}-${index}`}>
                <td className="bg-gray-100 text-gray-400 pl-4 pr-1">{index}</td>
                <td className="p-1">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {label && (
        <div className="text-sm text-gray-800 font-mono w-max mt-1">
          {label}
        </div>
      )}
    </div>
  );
}
