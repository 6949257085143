import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { useFormikContext } from "formik";
import { genericFormProps } from "genericTypes";
import { useMemo } from "react";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders an editable table input component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetEditableTable(props: IWidgetNestedProps) {
  const { disabled, name, label, placeholder } = props;
  const { setFieldValue, values } = useFormikContext<genericFormProps>();

  const tableValue = useMemo(
    () => (values[name] as string[][]) ?? [[]],
    [name, values]
  );

  const handleValueChange = (x: number, y: number, text: string) => {
    const copiedValue = [...tableValue];
    copiedValue[x][y] = text;
    setFieldValue(name, copiedValue);
  };

  const handleAddRow = () => {
    const copiedValue = [...tableValue];
    copiedValue[copiedValue.length] = Array.from(
      new Array(copiedValue[0].length),
      () => ""
    );
    setFieldValue(name, copiedValue);
  };

  const handleAddColumn = () => {
    const copiedValue = [...tableValue];
    if (copiedValue[0]) {
      for (let index = 0; index < copiedValue.length; index++) {
        copiedValue[index].push("");
      }
    }
    setFieldValue(name, copiedValue);
  };

  return (
    <div data-testid="widget-editabletable-input">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <div className="overflow-y-scroll">
        {tableValue ? (
          <table>
            {(tableValue as string[][]).map((row, index) => {
              if (index === 0) {
                return (
                  <thead key={`table-head-${index}}`}>
                    <tr>
                      {row.map((entry, entryIndex) => (
                        <th key={`row-${index}-${entryIndex}`}>
                          <input
                            className="font-bold w-full box-border px-1"
                            value={entry}
                            onChange={(e) =>
                              handleValueChange(
                                index,
                                entryIndex,
                                e.target.value
                              )
                            }
                            disabled={disabled}
                          ></input>
                        </th>
                      ))}
                    </tr>
                  </thead>
                );
              } else {
                return (
                  <tbody key={`table-body-${index}}`}>
                    <tr>
                      {row.map((entry, entryIndex) => (
                        <td key={`row-${index}-${entryIndex}`}>
                          <input
                            className="px-1"
                            value={entry}
                            onChange={(e) =>
                              handleValueChange(
                                index,
                                entryIndex,
                                e.target.value
                              )
                            }
                            disabled={disabled}
                          ></input>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                );
              }
            })}
          </table>
        ) : (
          <div>{placeholder}</div>
        )}
      </div>
      <div className="mt-1 flex gap-1">
        <Button
          type="button"
          disabled={disabled || tableValue[0]?.length >= 6}
          style={accentStyleEnum.SIMPLE}
          className="h-8"
          onClick={handleAddColumn}
        >
          Add column
        </Button>
        <Button
          type="button"
          disabled={disabled || tableValue?.length >= 6}
          style={accentStyleEnum.SIMPLE}
          className="h-8"
          onClick={handleAddRow}
        >
          Add row
        </Button>
      </div>
    </div>
  );
}
