import { accentStyles } from "atoms/genericStyles";
import { overrideTailwindClasses } from "tailwind-override";
import { ButtonProps } from "./buttonTypes";

/**
 * Renders a button component
 * ```tsx
 *  <Button
 *    type="button"
 *    loading={loading}
 *    style={accentStyleEnum.SIMPLE}
 *    className="mt-1"
 *    onClick={handleClick}
 *    disabled={true}
 *  >
 *    Button label
 *  </Button
 * ```
 * @category Atom
 */
export function Button({
  className,
  type,
  children,
  style,
  loading,
  disabled = false,
  onClick,
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={overrideTailwindClasses(
        `text-sm font-medium py-2 px-3 text-left rounded-lg overflow-hidden inline-flex justify-center gap-1 items-center sm:text-sm 
        ${
          disabled
            ? `bg-gray-200 text-gray-500 ${
                accentStyles[style].borderColor && "border border-gray-400"
              }`
            : `bg-gradient-to-b hover:brightness-95 ${
                accentStyles[style].gradientFrom
              } ${accentStyles[style].textColorContrast} ${
                accentStyles[style].gradientTo
              } ${
                accentStyles[style].borderColor &&
                "border " + accentStyles[style].borderColor
              } `
        }
        ${className}`
      )}
    >
      {children}
      {loading && (
        <svg
          className={`animate-spin ml-1 mr-1 h-5 w-5 ${
            disabled ? "text-gray-500" : accentStyles[style].textColorContrast
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </button>
  );
}
