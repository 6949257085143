import { IWidgetNestedCustomOutputProps } from "../interfaceWidgetTypes";
import JSONPretty from "react-json-pretty";

/**
 * Renders a json output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetJson(props: IWidgetNestedCustomOutputProps) {
  const { label, placeholder, value } = props;
  return (
    <div data-testid="widget-json-output" className="max-h-96 overflow-auto">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      {value ? (
        <JSONPretty
          theme={{
            main: "line-height:1.3;color:#adbac7;background:#22272e;overflow:auto;padding:0.5em;border-radius: 0.125rem;",
            error:
              "line-height:1.3;color:#adbac7;background:#22272e;overflow:auto;",
            key: "color:#6cb6ff;",
            string: "color:#f69d50;",
            value: "color:#dcbdfb;",
            boolean: "color:#96d0ff;",
          }}
          data={JSON.parse(value)}
        />
      ) : (
        <pre className="bg-white p-4 overflow-auto whitespace-pre-line">
          <code>{value ? value : placeholder}</code>
        </pre>
      )}
    </div>
  );
}
