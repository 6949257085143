import { atom } from "recoil";

/**
 * Type for {@link userAtomType | 'userAtomType'}
 *
 * @category Type
 */
export type userAtomType = {
  id?: number;
  cwid?: string;
  fullName?: string;
  email?: string;
  photo?: File;
  sysRole?: string;
};

/**
 * Recoil user atom
 */
export const userAtom = atom<userAtomType>({
  key: "user",
  default: {},
});
