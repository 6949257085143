import { Field } from "formik";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders a slider component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetSlider(props: IWidgetNestedProps) {
  const { disabled, name, label, placeholder } = props;

  return (
    <div data-testid="widget-slider-input">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <Field
        placeholder={placeholder}
        name={name}
        className="h-2 ml-2 mr-2 border-gray-300"
        disabled={disabled}
        as="input"
        type="range"
        min={0.2}
        max={0.99}
        step={0.01}
      ></Field>
    </div>
  );
}
