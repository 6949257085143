import { TrashIcon } from "@heroicons/react/solid";
import { model } from "api/apiTypes";
import { Loading } from "atoms/Loading/Loading";
import useAxios from "axios-hooks";
import { useUpdateHandler } from "components/Model/ModelDiscussion/ModelDiscussionContext";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";
import {
  DeleteCommentProps,
  ModelDetailPageParams,
} from "./commentSectionProps";

/**
 * Renders a delete button for the {@link Comment | 'Comment'}
 *
 * @category Component
 */
export const DeleteComment = ({ id }: DeleteCommentProps) => {
  const refetchComments = useUpdateHandler();
  const [, setNotification] = useRecoilState(notificationAtom);
  const { owner, model } = useParams<ModelDetailPageParams>();
  const [{ data, loading, error }, request] = useAxios<model>(
    {
      url: `models/${owner}/${model}`,
      method: "PATCH",
    },
    { manual: true }
  );

  const handleDelete = async () => {
    await request({
      data: {
        remove_comment: [
          {
            id,
          },
        ],
      },
    });
    refetchComments();
  };

  if (loading && !data) {
    return <Loading />;
  }

  if (error) {
    setNotification({
      label: `Server error: ${error.code}`,
      body: "Could not post comment",
    });
  }

  return (
    <div className="rounded-full sm w-4 h-4 cursor-pointer text-gray-500 self-center ml-1">
      <TrashIcon onClick={handleDelete} />
    </div>
  );
};
