import { info } from "atoms/FilePreview/filePreviewTypes";
import { fileTypeEnum, tableFile } from "atoms/FileTable/fileTableTypes";
import { ModelPlaqueProps } from "atoms/ModelPlaque/modelPlaqueTypes";
import { OrganizationPlaqueProps } from "atoms/OrganizationPlaque/organizationPlaqueTypes";
import { filter } from "components/FilterSection/filterSectionTypes";
import { ModelInfo } from "components/Model/ModelInfo/modelInfoTypes";
import { profileInfo } from "components/Profile/profileTypes";
import { valuePair } from "genericTypes";
import {
  notificationAtomType,
  notificationStatusEnum,
} from "recoil/notification/atom";
import {
  model,
  revisions,
  genericTag,
  user,
  file,
  fileInfo,
  dataset,
  organization,
  wsMessage,
} from "./apiTypes";

/**
 * Maps a generic tag to a filter format
 * @param tags - a list of api generic tags
 * @returns a list of filters
 * @category ApiMapper
 */
export const mapBeGenericTagsToFeFilter = (tags: genericTag[]): filter[] => {
  return tags.map((tag) => {
    return { label: tag.text };
  });
};

/**
 * Maps a generic tag to a filter format
 * @param tags - a list of api generic tags
 * @returns a list of filters
 * @category ApiMapper
 */
export const mapBeDatasetsToFeFilter = (tags: dataset[]): filter[] => {
  return tags.map((tag) => {
    return { label: tag.name };
  });
};

/**
 * Maps a list of api organizations to a list of OrganizationPlaque props
 * @param orgs - list of api organizations
 * @returns a list of {@link OrganizationPlaque | 'OrganizationPlaque'} specific props
 * @category ApiMapper
 */
export const mapBeOrgsToFeOrgPlaques = (
  orgs: organization[] = []
): OrganizationPlaqueProps[] => {
  return orgs.map((org) => {
    return mapBeOrgToFeOrgPlaque(org);
  });
};

/**
 * Maps an api organization api to OrganizationPlaque props
 * @param org - api organizations
 * @returns a {@link OrganizationPlaque | 'OrganizationPlaque'} specific prop object
 * @category ApiMapper
 */
export const mapBeOrgToFeOrgPlaque = (
  org: organization
): OrganizationPlaqueProps => {
  return {
    name: org.username,
    fullName: org.full_name,
    email: org.email,
    interests: org.research_interests,
    budget: org.budget,
    engagement: org.engagement,
    department: org.department,
    country: org.country,
    urlTo: `/organizations/${org.username}`,
  };
};

/**
 * Maps a list of api models to a list of ModelPlaque props
 * @param models - list of api models
 * @returns a list of {@link ModelPlaque | 'ModelPlaque'} specific props
 * @category ApiMapper
 */
export const mapBeModelsToFeModelPlaques = (
  models: model[] = []
): ModelPlaqueProps[] => {
  return models.map((model) => {
    return mapBeModelToFeModelPlaque(model);
  });
};

/**
 * Maps an api model api to ModelPlaque props
 * @param model - api models
 * @returns a {@link ModelPlaque | 'ModelPlaque'} specific prop object
 * @category ApiMapper
 */
export const mapBeModelToFeModelPlaque = (model: model): ModelPlaqueProps => {
  return {
    name: model.name,
    author: model.org_or_user_owner,
    sagemakerStatus: model.sagemaker_pinned,
    tasks: model.tasks ? model.tasks.map((task) => task.text) : [],
    tags: model.tags ? model.tags.map((tag) => tag.text) : [],
    languages: model.languages ? model.languages.map((lang) => lang.text) : [],
    libraries: model.libraries ? model.libraries.map((lib) => lib.text) : [],
    lastUpdated: new Date(model.last_updated * 1000),
    thumbs:
      model.thumbs?.map((thumb) => {
        return {
          id: thumb.id,
          user_id: thumb.user_id,
          model_id: thumb.model_id,
        };
      }) || [],
    urlTo: `/models/${model.org_or_user_owner}/${model.name}`,
    // users: model.users?.map((user) => user.username) || [],
    // organizations: model.organizations?.map((org) => org.name) || [],
  };
};

/**
 * Maps an api model api to ModelPlaque props
 * @param model - api models
 * @returns a {@link ModelPlaque | 'ModelPlaque'} specific prop object
 * @category ApiMapper
 */
export const mapBeModelToFeModelInfo = (model: model): ModelInfo => {
  return {
    name: model.name,
    owner: model.org_or_user_owner,
    tasks: model.tasks ? model.tasks.map((task) => task.text) : [],
    tags: model.tags ? model.tags.map((tag) => tag.text) : [],
    languages: model.languages ? model.languages.map((lang) => lang.text) : [],
    libraries: model.libraries ? model.libraries.map((lib) => lib.text) : [],
    lastUpdated: new Date(model.last_updated * 1000),
    thumbs:
      model.thumbs?.map((thumb) => {
        return {
          id: thumb.id,
          user_id: thumb.user_id,
          model_id: thumb.model_id,
        };
      }) || [],
    collaborations:
      model.collaborations?.map(({ account, model_role }) => ({
        model_role: model_role,
        id: account.id,
        username: account.username,
        full_name: account.full_name,
        email: account.email,
        is_organization: account.is_organization,
      })) || [],
  };
};

/**
 * Maps api revisions to generic value pairs
 * @param revisions - a list of api revisions
 * @returns a revision value pair
 * @category ApiMapper
 */
export const mapBeRevisionsToFeValuePairs = (
  revisions: revisions
): valuePair<string>[] => {
  const pairs: valuePair<string>[] = revisions.map((revision) => {
    return {
      value: revision.name,
      label: revision.name.charAt(0).toUpperCase() + revision.name.slice(1),
    };
  });
  return pairs;
};

/**
 * Maps an api user to profile information
 * @param user - an api user
 * @returns a profile information of the user
 * @category ApiMapper
 */
export const mapBeUserToFeProfileInfo = (user: user): profileInfo => {
  return {
    name: user.full_name,
    cwid: user.username.toUpperCase(),
    interests: user.research_interests,
    email: user.email,
    apiToken: user.api_token,
    apiTokenExpiration: user.api_token_expiration_date,
    organizations: user.organizations?.map((org) => ({
      org_id: org.org_id,
      org_role: org.org_role,
      org_name: org.organization?.username,
      org_fullname: org.organization?.full_name,
    })),
    thumbs: user.thumbs?.map((th) => ({
      th_id: th.id,
      th_user_id: th.user_id,
      th_model_id: th.model_id,
    })),
    comments: user.comments?.map((c) => ({
      c_id: c.id,
      c_user_id: c.user_id,
      c_model_id: c.model_id,
      c_body: c.body,
      c_parent_id: c.parent_id,
      c_created_at: c.created_at,
      c_user_email: c.user_email,
    })),
  };
};

/**
 * Maps an api file to a table file
 * @param file - an api file
 * @returns a {@link FileTable | 'FileTable'} specific table file
 * @category ApiMapper
 */
export const mapBeFileToTableFile = (file: file): tableFile => {
  return {
    name: file.name,
    type: file.type === "file" ? fileTypeEnum.DOCUMENT : fileTypeEnum.FOLDER,
    size: file.size ? file.size : undefined,
    lastUpdated: file.last_updated
      ? new Date(file.last_updated * 1000)
      : undefined,
    path: file.relative_path,
    lastCommitMsg: file.commit_message ? file.commit_message : undefined,
  };
};

/**
 * Maps an api file information to file preview infomation
 * @param file - an api file information
 * @returns a {@link FilePreview | 'FilePreview'} specific file information
 * @category ApiMapper
 */
export const mapBePreviewToPreviewFile = (info: fileInfo): info => {
  return {
    type: info.mime_type,
    isBinary: info.is_binary,
    size: info.file_size,
    lastUpdated: info.last_updated_time
      ? new Date(info.last_updated_time)
      : undefined,
    author: info.last_updated_author,
    lineCount: info.line_count,
    content: info.content,
  };
};

/**
 * Maps a websocket message to a notification
 * @param message - an api message
 * @returns a notification status
 * @category ApiMapper
 */
export const mapWsMessageToNotification = (
  message: wsMessage
): notificationAtomType => {
  const status =
    message.content.status === "ERROR"
      ? notificationStatusEnum.ALERT
      : message.content.status === "SUCCESS"
      ? notificationStatusEnum.SUCCESS
      : message.type === "ERROR"
      ? notificationStatusEnum.ALERT
      : message.type === "RUNNING"
      ? notificationStatusEnum.ATTENTION
      : message.type === "WARNING"
      ? notificationStatusEnum.ATTENTION
      : undefined;

  return {
    label: message.content.title,
    body: message.content.description,
    status: status,
  };
};
