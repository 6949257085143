import { ChainedCommands } from "@tiptap/react";
import { control } from "./richTextEditorProps";

const Bold = () => (
  <svg
    aria-hidden="true"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    width="16"
  >
    <path
      fillRule="evenodd"
      d="M4 2a1 1 0 00-1 1v10a1 1 0 001 1h5.5a3.5 3.5 0 001.852-6.47A3.5 3.5 0 008.5 2H4zm4.5 5a1.5 1.5 0 100-3H5v3h3.5zM5 9v3h4.5a1.5 1.5 0 000-3H5z"
    ></path>
  </svg>
);

const Italic = () => (
  <svg
    aria-hidden="true"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    width="16"
  >
    <path
      fillRule="evenodd"
      d="M6 2.75A.75.75 0 016.75 2h6.5a.75.75 0 010 1.5h-2.505l-3.858 9H9.25a.75.75 0 010 1.5h-6.5a.75.75 0 010-1.5h2.505l3.858-9H6.75A.75.75 0 016 2.75z"
    ></path>
  </svg>
);

const Codeblock = () => (
  <svg
    aria-hidden="true"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    width="16"
  >
    <path
      fillRule="evenodd"
      d="M4.72 3.22a.75.75 0 011.06 1.06L2.06 8l3.72 3.72a.75.75 0 11-1.06 1.06L.47 8.53a.75.75 0 010-1.06l4.25-4.25zm6.56 0a.75.75 0 10-1.06 1.06L13.94 8l-3.72 3.72a.75.75 0 101.06 1.06l4.25-4.25a.75.75 0 000-1.06l-4.25-4.25z"
    ></path>
  </svg>
);

const BulletList = () => (
  <svg
    aria-hidden="true"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    width="16"
  >
    <path
      fillRule="evenodd"
      d="M2 4a1 1 0 100-2 1 1 0 000 2zm3.75-1.5a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zm0 5a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zm0 5a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM3 8a1 1 0 11-2 0 1 1 0 012 0zm-1 6a1 1 0 100-2 1 1 0 000 2z"
    ></path>
  </svg>
);

const Heading = () => (
  <svg
    aria-hidden="true"
    height="16"
    viewBox="0 0 16 16"
    version="1.1"
    width="16"
  >
    <path
      fillRule="evenodd"
      d="M3.75 2a.75.75 0 01.75.75V7h7V2.75a.75.75 0 011.5 0v10.5a.75.75 0 01-1.5 0V8.5h-7v4.75a.75.75 0 01-1.5 0V2.75A.75.75 0 013.75 2z"
    ></path>
  </svg>
);

/**
 * A list of possible contol options for {@link EditorControls | 'EditorControls'}
 */
export const controls: control[] = [
  {
    name: "bold",
    icon: <Bold />,
    action: (chain: ChainedCommands) => {
      chain.focus().toggleBold().run();
    },
  },
  {
    name: "italic",
    icon: <Italic />,
    action: (chain: ChainedCommands) => {
      chain.focus().toggleItalic().run();
    },
  },
  {
    name: "codeblock",
    icon: <Codeblock />,
    action: (chain: ChainedCommands) => {
      chain.focus().toggleCodeBlock().run();
    },
  },
  {
    name: "heading",
    icon: <Heading />,
    variant: { level: 3 },
    action: (chain: ChainedCommands) => {
      chain.focus().toggleHeading({ level: 3 }).run();
    },
  },
  {
    name: "bulletlist",
    icon: <BulletList />,
    action: (chain: ChainedCommands) => {
      chain.focus().toggleBulletList().run();
    },
  },
];
