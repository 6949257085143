export type FileTableProps = {
  lastCommiter: string;
  timeLastCommited: Date;
  files: tableFile[];
  className?: string;
  showPrev: boolean;
  commitMessage: string;
  hexSHA: string;
};

export type tableFile = {
  name: string;
  type: fileTypeEnum;
  size?: number;
  lastUpdated?: Date;
  path: string;
  lastCommitMsg?: string;
};

export enum fileTypeEnum {
  DOCUMENT = "DOCUMENT",
  FOLDER = "FOLDER",
}
