import { atom } from "recoil";

/**
 * Enum for notification statuses
 *
 * @category Type
 * @enum
 */
export enum notificationStatusEnum {
  ALERT,
  SUCCESS,
  ATTENTION,
}

/**
 * Type for {@link notificationAtomType | 'notificationAtomType'}
 *
 * @category Type
 */
export type notificationAtomType = {
  label?: string;
  body?: string;
  status?: notificationStatusEnum;
};

/**
 * Recoil notification atom
 */
export const notificationAtom = atom<notificationAtomType>({
  key: "notification",
  default: {},
});
