import { mapBeModelToFeModelInfo } from "api/apiHelpers";
import { model } from "api/apiTypes";
import { Loading } from "atoms/Loading/Loading";
import useAxios from "axios-hooks";
import { CommentSection } from "components/Model/ModelDiscussion/CommentSection/CommentSection";
import { ModelDetailPageParams } from "components/Model/ModelDiscussion/CommentSection/commentSectionProps";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ModelDiscussionProvider } from "./ModelDiscussionContext";
import { ModelInfo } from "../ModelInfo/ModelInfo";

/**
 * Renders a model discussion section
 *
 * @category Component
 */
export const ModelDiscussion = () => {
  const { owner, model } = useParams<ModelDetailPageParams>();
  const [{ data, loading }, fetchModel] = useAxios<model>({
    url: `models/${owner}/${model}`,
  });

  const dataMemoized = useMemo(
    () => (data ? mapBeModelToFeModelInfo(data) : undefined),
    [data]
  );

  if (loading || !dataMemoized || !data) {
    return <Loading />;
  }

  const handleUpdate = () => {
    fetchModel();
  };
  return (
    <div className="relative grow">
      <div>
        <ModelInfo {...dataMemoized} onModelUpdate={handleUpdate} />
        <ModelDiscussionProvider modelId={data.id} onUpdate={handleUpdate}>
          <CommentSection comments={data?.comments} />
        </ModelDiscussionProvider>
      </div>
    </div>
  );
};
