/**
 * Creates a pagination list
 * @param totalCount - total number of items
 * @param limit - limit of items per page
 * @returns a list of page numbers
 */
export const createPaginationList = (
  totalCount = 0,
  limit: number
): number[] => {
  const pageCount = totalCount === 0 ? 1 : Math.ceil(totalCount / limit);
  const paginationList: number[] = [];
  for (let i = 0; i < pageCount; i++) {
    paginationList.push(i + 1);
  }
  return paginationList;
};

/**
 * Identifies if a page button should be rendered
 * @param page - for what page the check is being called
 * @param amount - total amount of pages
 * @param bounds - elipsis determination parameter @see {@link PaginationProps | 'PaginationProps'}
 * @param activePage - what page is active at the moment
 * @returns a render flag
 */
export const shouldRenderPageButton = (
  page: number,
  amount: number,
  bounds: number,
  activePage: number
) => {
  const isWithinLeftBounds = page <= bounds;
  const isWithinRightBounds = page >= amount - bounds;
  const isWithinCurrentPageBounds = Math.abs(page - activePage) <= bounds;
  return isWithinLeftBounds || isWithinRightBounds || isWithinCurrentPageBounds;
};
