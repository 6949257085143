import { Transition } from "@headlessui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { TooltipProps } from "./tooltipTypes";

/**
 * Renders an on hover tooltip wrapper
 * ```tsx
 *  <Tooltip
 *    textComponent={
 *      <p>Hey, I am a tooltip</p>
 *    }
 *  >
 *    <InformationCircleIcon className="h-3 w-3" />
 *  </Tooltip>
 * ```
 * @category Atom
 */
function Tooltip({
  children,
  textComponent,
  className,
  containerStyle,
  containerClass,
}: TooltipProps) {
  const [tooltipRect, setTooltipRect] = useState<undefined | DOMRect>();
  const childrenRef = useRef<HTMLDivElement | null>(null);
  const [left, setLeft] = useState("50%");
  const [show, setShow] = useState<boolean>(false);
  const [moved, setMoved] = useState(false);

  const handleTooltipRect = useCallback((node) => {
    setTooltipRect(node?.getBoundingClientRect());
  }, []);

  /**
   * Moves the tooltip from the left edge of the screen, if it overflows it
   */
  useEffect(() => {
    if (tooltipRect) {
      if (tooltipRect.left < 0) {
        setLeft(`${Math.abs(tooltipRect.left) + 20}px`);
      }
      setMoved(true);
    }
  }, [show, tooltipRect]);

  return (
    <div
      className={overrideTailwindClasses(
        `relative w-fit flex items-center ${containerClass}`
      )}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      style={containerStyle}
    >
      <Transition
        show={show}
        enter="transition-all duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          style={{
            opacity: moved ? 1 : 0,
            left,
          }}
          className={overrideTailwindClasses(
            `absolute z-50 bg-white border-2 min-w-max text-black text-sm font-medium px-3 py-2 rounded-lg flex items-center -translate-x-[50%] select-none bottom-6 left-1/2 ${className}`
          )}
          ref={handleTooltipRect}
        >
          <div className="whitespace-pre-line">{textComponent}</div>
        </div>
      </Transition>
      <div className="absolute" ref={childrenRef}>
        {children}
      </div>
    </div>
  );
}

export default Tooltip;
