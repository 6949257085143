import { mapBeModelsToFeModelPlaques } from "api/apiHelpers";
import { modelList } from "api/apiTypes";
import { Loading } from "atoms/Loading/Loading";
import { ModelPlaque } from "atoms/ModelPlaque/ModelPlaque";
import useAxios from "axios-hooks";
import { Pagination } from "components/Pagination/Pagination";
import { createPaginationList } from "components/Pagination/paginationHelpers";
import { useState, useEffect, useMemo } from "react";
import { GenericModelListProps } from "./genericModelListTypes";

/**
 * Renders and fetches a list of {@link ModelPlaque | 'ModelPlaque'}
 * @category Component
 */
export function GenericModelList({
  modelsFetchParams,
  modelMaxAmount,
}: GenericModelListProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const [{ loading: isLoadingModels, data: models }, fetchModels] =
    useAxios<modelList>({}, { manual: true });

  useEffect(() => {
    fetchModels({
      url: `models/`,
      params: {
        ...modelsFetchParams,
        limit: modelMaxAmount,
        offset,
      },
    });
  }, [fetchModels, offset, modelsFetchParams, modelMaxAmount]);
  const pages = useMemo(
    () => createPaginationList(models?.total_count, modelMaxAmount),
    [modelMaxAmount, models?.total_count]
  );
  const handleNext = () => {
    if (currentPage < pages.length) {
      setCurrentPage((state) => state + 1);
      setOffset((state) => state + modelMaxAmount);
    }
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((state) => state - 1);
      setOffset((state) => state - modelMaxAmount);
    }
  };
  const handlePageSelect = (page: number) => () => {
    setCurrentPage(page);
    setOffset((page - 1) * modelMaxAmount);
  };
  if (!models || isLoadingModels) {
    return (
      <div className="grow w-9/12 p-4 flex flex-col">
        <Loading />
      </div>
    );
  }
  return (
    <div className="flex flex-col grow">
      <div className="grow grid gap-4 auto-rows-min grid-cols-1 2xl:grid-cols-2">
        {mapBeModelsToFeModelPlaques(models.model_meta_data).map((model) => {
          return <ModelPlaque key={model.name} {...model} />;
        })}
      </div>
      <div className="flex justify-center">
        <Pagination
          pages={pages}
          activePage={currentPage}
          onPageSelect={handlePageSelect}
          onPrevPage={handlePrev}
          onNextPage={handleNext}
        />
      </div>
    </div>
  );
}
