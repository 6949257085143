import { modelSortEnum } from "api/apiTypes";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { accentStyleEnum } from "atoms/genericStyles";
import { GenericModelList } from "components/GenericModelList/GenericModelList";
import { valuePair } from "genericTypes";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const MODEL_LIMIT_PER_PAGE = 20;

/**
 * Renders a filtered list of models for {@link ModelList | 'ModelList'}
 *
 * @category Component
 */
export function ModelListModels() {
  const [searchParams] = useSearchParams();
  const [sorting, setSorting] = useState<valuePair<modelSortEnum>>({
    label: modelSortEnum.UPDATED,
    value: modelSortEnum.UPDATED,
  });

  const [fetchParams, setFetchParams] = useState<any>({
    model_name: searchParams.getAll("search"),
    tag_names: searchParams.getAll("tag_names"),
    lib_names: searchParams.getAll("lib_names"),
    language_names: searchParams.getAll("language_names"),
    task_name: searchParams.get("task_name"),
    dataset_names: searchParams.getAll("dataset_names"),
    sort_option: sorting.value,
  });

  useEffect(() => {
    setFetchParams({
      model_name: searchParams.getAll("search"),
      tag_names: searchParams.getAll("tag_names"),
      lib_names: searchParams.getAll("lib_names"),
      language_names: searchParams.getAll("language_names"),
      task_name: searchParams.get("task_name"),
      dataset_names: searchParams.getAll("dataset_names"),
      sort_option: sorting.value,
    });
  }, [searchParams, sorting.value]);

  return (
    <div className="grow w-9/12 p-4 flex flex-col">
      <div className="ml-auto mt-2 mb-4">
        <Dropdown
          containerClassName="min-w-[160px]"
          style={accentStyleEnum.SIMPLE}
          value={sorting}
          options={Object.values(modelSortEnum).map((value) => {
            return { label: value, value };
          })}
          onChange={(value: valuePair<modelSortEnum>) => {
            setSorting(value);
          }}
        />
      </div>
      <GenericModelList
        modelsFetchParams={fetchParams}
        modelMaxAmount={MODEL_LIMIT_PER_PAGE}
      />
    </div>
  );
}
