import { useMemo, useState } from "react";
import { FilterTag } from "atoms/FilterTag/FilterTag";
import { FilterSectionProps, filter } from "./filterSectionTypes";
import { useSearchParams } from "react-router-dom";

/**
 * Renders a group of filter tags
 *
 * When filter option is selected, sets a respective search parameter
 * @category Component
 */
export function FilterSection({
  label,
  filterOptions,
  sectionColor,
  filterApiLabel,
  multiselect = true,
}: FilterSectionProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<boolean>(false);
  const tags = useMemo(() => {
    return searchParams.getAll(filterApiLabel);
  }, [searchParams, filterApiLabel]);

  const existingParams = useMemo(() => {
    const localExistingParams: any = {};

    searchParams.forEach((value, key) => {
      localExistingParams[key] = localExistingParams[key]
        ? [...localExistingParams[key], value]
        : [value];
    });

    return localExistingParams;
  }, [searchParams]);

  const handleTagClick = (tagFilterType: filter) => {
    if (multiselect) {
      if (tags) {
        if (
          tags.some((selectedFilter) => selectedFilter === tagFilterType.label)
        ) {
          setSearchParams({
            ...existingParams,
            [filterApiLabel]: tags.filter(
              (selectedFilter) => selectedFilter !== tagFilterType.label
            ),
          });
        } else {
          setSearchParams({
            ...existingParams,
            [filterApiLabel]: [...tags, tagFilterType.label],
          });
        }
      } else {
        setSearchParams({
          ...existingParams,
          [filterApiLabel]: [tagFilterType.label],
        });
      }
    } else {
      if (
        tags.some((selectedFilter) => selectedFilter === tagFilterType.label)
      ) {
        setSearchParams({
          [filterApiLabel]: [],
        });
      } else {
        setSearchParams({
          [filterApiLabel]: [tagFilterType.label],
        });
      }
    }
  };

  const mapFilterArray = (filterArray: filter[]) => {
    return filterArray.map((filter) => {
      return (
        <FilterTag
          key={filter.label}
          label={filter.label}
          showClose={tags.some(
            (selectedFilter) => selectedFilter === filter.label
          )}
          grayedOut={
            tags.length !== 0 &&
            !tags.some((selectedFilter) => selectedFilter === filter.label)
          }
          tagStyle={sectionColor}
          onClick={() => handleTagClick(filter)}
        />
      );
    });
  };

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <div className="w-full flex flex-col items-start gap-2">
      <div className="w-full flex items-baseline gap-4">
        <div className="font-medium">{label}</div>
        {tags.length !== 0 && (
          <div
            className="text-sm text-slate-400 underline cursor-pointer"
            onClick={() => {
              setSearchParams({ ...existingParams, [filterApiLabel]: [] });
            }}
          >
            Clear all
          </div>
        )}
        {tags.length !== 0 && (
          <div className="text-sm text-slate-400 ml-auto mr-4">
            Selected {tags.length}
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        {mapFilterArray(filterOptions.slice(0, 8))}
        {mapFilterArray(
          filterOptions
            .slice(9)
            .filter(
              (filter) =>
                expanded ||
                tags.some((selectedFilter) => selectedFilter === filter.label)
            )
        )}
        {filterOptions.length > 9 && (
          <FilterTag
            label={expanded ? `⬅` : `+ ${filterOptions.length - 9}`}
            grayedOut={true}
            tagStyle={sectionColor}
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        )}
      </div>
    </div>
  );
}
