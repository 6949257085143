import { CodeIcon } from "@heroicons/react/outline";
import { Button } from "atoms/Button/Button";
import { accentStyleEnum } from "atoms/genericStyles";
import { Modal } from "atoms/Modal/Modal";
import { useMemo, useState } from "react";
import { ModelTipProps } from "./modelTipTypes";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

/**
 * Renders a model tip button
 *
 * On press, renders a modal with DeepMind library usage example
 *
 * @category Component
 */
export function ModelTip({ modelName, modelOwner }: ModelTipProps) {
  const [open, setOpen] = useState(false);
  const transformerString = useMemo(
    () =>
      `from deepmind_api.dm_api import DmApi\n\r# instantiate an instance - DeepMind would log you in\n\rdm_instance = DmApi()\n\rmodel = dm_instance.from_pretrained_model('${modelOwner}/${modelName}')\n\rtokenizer = dm_instance.from_pretrained_tokenizer('${modelOwner}/${modelName}')`,
    [modelName, modelOwner]
  );
  const modelString = useMemo(
    () =>
      `from deepmind_api.dm_api import Repository\n\r# You could either clone from an existing repo\n\rrepo = Repository(local_dir = '${modelOwner}/${modelName}', clone_from = '${modelOwner}/${modelName}')\n\rrepo.git_dvc_pull() `,
    [modelName, modelOwner]
  );
  return (
    <>
      <Button
        type="button"
        style={accentStyleEnum.SIMPLE}
        className="h-8 mb-1 flex"
        onClick={() => setOpen(true)}
      >
        <CodeIcon className="w-4 h-4 mr-1" />
        Load using DeepMind library
      </Button>
      <Modal className="max-w-4xl" open={open} setOpen={setOpen}>
        <p className="font-medium my-1">
          How to load this model using DeepMind Python Library
        </p>
        <p>
          If the model is a{" "}
          <span className="font-mono bg-gray-200 px-0.5">transformer</span> use
          the following to load in memory:
        </p>
        <SyntaxHighlighter
          language={"python"}
          style={docco}
          showLineNumbers={true}
        >
          {transformerString}
        </SyntaxHighlighter>
        <p className="mt-2">To clone any model type:</p>
        <SyntaxHighlighter
          language={"python"}
          style={docco}
          showLineNumbers={true}
        >
          {modelString}
        </SyntaxHighlighter>
      </Modal>
    </>
  );
}
