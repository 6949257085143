import { model } from "api/apiTypes";
import { genericFormProps } from "genericTypes";
import { BaseSchema } from "yup";

/**
 * Status enum for models sagemaker hosting status
 * @category Type
 * @enum
 */
export enum iWidgetStatusEnum {
  STANDBY = 0,
  PENDING = 1,
  READY = 2,
}

/**
 * The props type for {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Type
 */
export interface IWidgetProps {
  /**
   * Models sagemaker hosting status
   */
  status: iWidgetStatusEnum;
  modelName: string;
  /**
   * Models owner
   */
  owner: string;
  /**
   * Model classification
   */
  classification?: iWidgetModelTypesEnum;
  /**
   * Model modaltiy
   */
  modality: string;
  /**
   * Handler to update the `status`
   */
  onUpdate: () => Promise<model | undefined>;
}

/**
 * The props type for {@link InterfaceWidgetStatus | 'InterfaceWidgetStatus'}.
 * @category Type
 */
export interface IWidgetStatusProps {
  /**
   * Models sagemaker hosting status
   */
  status: iWidgetStatusEnum;
  loading: boolean;
}

/**
 * Basic properties for widget input and output components
 * @category Type
 */
export interface IWidgetNestedEssentialCustomProps {
  label?: string;
  placeholder?: string;
}

/**
 * Properties for widget output components
 * @category Type
 */
export interface IWidgetNestedCustomOutputProps<T = any>
  extends IWidgetNestedEssentialCustomProps {
  inputValue?: any;
  value?: T;
}

/**
 * Properties for widget input components
 * @category Type
 */
export interface IWidgetNestedCustomInputProps
  extends IWidgetNestedEssentialCustomProps {
  disabled?: boolean;
}

/**
 * Extended properties for widget input components
 * @category Type
 */
export interface IWidgetNestedProps extends IWidgetNestedCustomInputProps {
  name: string;
}

/**
 * Form property for input components
 * @category Type
 */
export type IWidgetNestedFProps = genericFormProps;

/**
 * Type displaying and mapping a list of input components for  {@link InterfaceWidgetStatus | 'InterfaceWidgetStatus'}.
 * @category Type
 */
export interface iWidgetNestedParamsInput {
  /**
   * A list of input components and their properties
   */
  components: {
    componentProps: IWidgetNestedCustomInputProps;
    component: React.FC<IWidgetNestedProps>;
  }[];
  /**
   * Yup schema to validate provided components valus
   */
  formValidationSchema: BaseSchema<IWidgetNestedFProps>;
  /**
   * Mapper funciton, which maps components values to the classification appropriate API parameters
   */
  componentMapper: (values: IWidgetNestedFProps) => any;
  /**
   * additional tips or explanations
   */
  tips?: string | React.FC | React.Component;
}

/**
 * Type displaying and mapping a list of output components for  {@link InterfaceWidgetStatus | 'InterfaceWidgetStatus'}.
 * @category Type
 */
export interface iWidgetNestedParamsOutput<T = any> {
  /**
   * A list of output components and their properties
   */
  components: {
    componentProps: IWidgetNestedCustomOutputProps<T>;
    component: React.FC<IWidgetNestedCustomOutputProps<T>>;
  }[];
}

/**
 * The parameters type for mapping input components for {@link InterfaceWidget | 'InterfaceWidget'}
 * @category Type
 */
export interface iWidgetInputParams {
  /**
   * Input components
   */
  input: iWidgetNestedParamsInput;
  /**
   * Default parameters for the input components
   */
  defaultParams: any;
}

/**
 * The parameters type for mapping output components for {@link InterfaceWidget | 'InterfaceWidget'}
 * @category Type
 */
export interface iWidgetOutputParams<T = any> {
  /**
   * Output components
   */
  output?: iWidgetNestedParamsOutput<T>;
  /**
   * Flags, if output mapping has failed
   */
  outputFailed?: boolean;
}

/**
 * The props type for {@link InterfaceWidgetConversation | 'InterfaceWidgetConversation'}
 * @category Type
 */
export type iWidgetConversationProps = {
  conversation: {
    generatedResponses: string[];
    pastUserInputs: string[];
  };
};

/**
 * The props type for {@link InterfaceWidgetConversation | 'InterfaceWidgetConversation'} related components
 * @category Type
 */
export type ConversationProps = {
  input: string;
};

/**
 * The values type for {@link InterfaceWidgetScore | 'InterfaceWidgetScore'}.
 * @category Type
 */
export interface iWidgetScoreValue {
  score: number;
}

/**
 * The values type for {@link InterfaceWidgetScoreText | 'InterfaceWidgetScoreText'}.
 * @category Type
 */
export interface iWidgetScoreTextValue {
  score: number;
  text: string;
}

/**
 * The values type for {@link InterfaceWidgetTable | 'InterfaceWidgetTable'}.
 * @category Type
 */
export interface iWidgetTableValue {
  values: number[][];
}

/**
 * The enum type for model classifications
 * @category Type
 * @enum
 */
export enum iWidgetModelTypesEnum {
  TEXT_CLASSIFICATION = "text-classification",
  TOKEN_CLASSIFICATION = "token-classification",
  TABLE_QUESTION_ANSWERING = "table-question-answering",
  QUESTION_ANSWERING = "question-answering",
  ZERO_SHOT_CLASSIFICATION = "zero-shot-classification",
  TRANSLATION = "translation",
  SUMMARIZATION = "summarization",
  CONVERSATIONAL = "conversational",
  FEATURE_EXTRACTION = "feature-extraction",
  TEXT_GENERATION = "text-generation",
  TEXT2TEXT_GENERATION = "text2text-generation",
  FILL_MASK = "fill-mask",
  SENTENCE_SIMILARITY = "sentence-similarity",
  TEXT_TO_SPEECH = "text-to-speech",
  AUTOMATIC_SPEECH_RECOGNITION = "automatic-speech-recognition",
  AUDIO_TO_AUDIO = "audio-to-audio",
  AUDIO_CLASSIFICATION = "audio-classification",
  VOICE_ACTIVITY_DETECTION = "voice-activity-detection",
  IMAGE_CLASSIFICATION = "image-classification",
  OBJECT_DETECTION = "object-detection",
  IMAGE_SEGMENTATION = "image-segmentation",
  TEXT_TO_IMAGE = "text-to-image",
  IMAGE_TO_TEXT = "image-to-text",
  IMAGE_TO_IMAGE = "image-to-image",
  UNCONDITIONAL_IMAGE_GENERATION = "unconditional-image-generation",
  REINFORCEMENT_LEARNING = "reinforcement-learning",
  TABULAR_CLASSIFICATION = "tabular-classification",
  TABULAR_REGRESSION = "tabular-regression",
  TABULAR_TO_TEXT = "tabular-to-text",
  TABLE_TO_TEXT = "table-to-text",
  MULTIPLE_CHOICE = "multiple-choice",
  TEXT_RETRIVAL = "text-retrieval",
  TIME_SERIES_FORECASTING = "time-series-forecasting",
  VISUAL_QUESTION_ANSWERING = "visual-question-answering",
  ZERO_SHOT_IMAGE_CLASSIFICATION = "zero-shot-image-classification",
  OTHER = "other",
}
