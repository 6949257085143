import { useEffect } from "react";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import { Loading } from "atoms/Loading/Loading";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";

export function BinaryPreview() {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const branchName = urlParams["branch"];
  const gitPath = urlParams["*"];
  const fileType = gitPath?.split(".").slice(-1)[0] || "";

  const [, setNotification] = useRecoilState(notificationAtom);

  const [
    { loading: isLoadingFile, data: fetchedFile, error: fileError },
    fetchFile,
  ] = useAxios<Blob>(
    {
      url: `models/${modelOwner}/${modelName}/${branchName}/download?filepath=${gitPath}`,
      transformResponse: [],
      responseType: "blob",
    },
    { manual: true }
  );

  useEffect(() => {
    fetchFile();
  }, [fetchFile]);

  useEffect(() => {
    if (fileError)
      setNotification({
        label: `Server error: ${fileError.code}`,
        body: "Could not load file",
      });
  }, [fileError]);

  if (isLoadingFile) {
    return (
      <div className="h-auto min-w-[246px] p-4 border-r-2 border-slate-100 bg-gradient-to-r from-white to-slate-50 justify-start">
        <Loading />
      </div>
    );
  }

  return (
    <div className="p-4">
      {["png", "jpg", "jpeg", "svg", "gif", "apng", "avif", "webp"].includes(
        fileType.toLowerCase()
      ) ? (
        fetchedFile && (
          <img src={window.URL.createObjectURL(fetchedFile)} alt={gitPath} />
        )
      ) : (
        <span>No Preview</span>
      )}
    </div>
  );
}
