import { accentStyles } from "atoms/genericStyles";
import { FilterTagProps } from "./filterTagTypes";
import { XIcon } from "@heroicons/react/solid";
import { GrayedOutTag } from "./filterTagStyles";

/**
 * Renders a styled filter pill
 * ```tsx
 * <FilterTag
 *    label="tag"
 *    showClose={false}
 *    grayedOut={false}
 *    disabled
 *    elevated={false}
 *    tagStyle={accentStyleEnum.CADET}
 * />
 *```
 * @category Atom
 */
export function FilterTag({
  label,
  showClose = false,
  grayedOut = false,
  elevated = true,
  disabled = false,
  tagStyle,
  onClick,
}: FilterTagProps) {
  return (
    <div>
      <button
        className={`${`bg-gradient-to-b ${
          (grayedOut ? GrayedOutTag : accentStyles[tagStyle]).gradientFrom
        } ${(grayedOut ? GrayedOutTag : accentStyles[tagStyle]).gradientTo} ${
          !disabled &&
          `active:bg-gradient-to-t hover:${
            (grayedOut ? GrayedOutTag : accentStyles[tagStyle]).gradientToToned
          }`
        }`} border border-slate-200 rounded-lg ${
          elevated && "drop-shadow"
        } px-2 py-0.5 overflow-hidden ${
          (grayedOut ? GrayedOutTag : accentStyles[tagStyle]).textColorContrast
        } text-sm flex gap-1 items-center`}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
        {showClose && <XIcon className="h-3 w-3" />}
      </button>
    </div>
  );
}
