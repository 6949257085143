import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { overrideTailwindClasses } from "tailwind-override";
import { ModalProps } from "./modalTypes";

/**
 * Renders a basic modal
 * ```tsx
 * const [open, setOpen] = useState(false);
 * ...
 * <Modal open={open} setOpen={setOpen}>
 *    ...
 *    content
 *    ...
 * </Modal>
 * ```
 * @category Atom
 */
export function Modal({ open, setOpen, children, className }: ModalProps) {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={overrideTailwindClasses(
                  `inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-md border-2 bg-gradient-to-b from-white to-slate-50 rounded-2xl ${className}`
                )}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
