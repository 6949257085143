import { FC } from "react";
import {
  ConversationProps,
  iWidgetConversationProps,
  IWidgetNestedCustomOutputProps,
} from "../interfaceWidgetTypes";

const Question = ({ input }: ConversationProps) => (
  <div className="px-3 py-2 rounded-2xl bg-blue-600 text-white ml-7">
    {input}
  </div>
);

const Answer = ({ input }: ConversationProps) => (
  <div className="px-3 py-2 rounded-2xl place-self-start bg-gray-50 dark:bg-gray-850 dark:text-gray-200 mr-7">
    {input}
  </div>
);

const Layout: FC = ({ children }) => (
  <div
    data-testid="widget-conversation-output"
    className="overflow-y-auto h-64 border border-b-0 rounded-t-lg leading-tight bg-white"
  >
    <div className="flex flex-col items-end space-y-4 p-3">{children}</div>
  </div>
);

/**
 * Renders a conversation history output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetConversation(
  props: IWidgetNestedCustomOutputProps<iWidgetConversationProps>
) {
  const { value } = props;

  if (!value?.conversation) {
    return null;
  }
  return (
    <Layout>
      {value?.conversation.pastUserInputs.map((input, index) => (
        <div key={`user-input-${input}-${index}`}>
          <Question input={input} />
          <Answer input={value?.conversation.generatedResponses[index]} />
        </div>
      ))}
    </Layout>
  );
}
