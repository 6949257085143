import useAxios from "axios-hooks";
import { useEffect, useMemo } from "react";
import { model } from "api/apiTypes";
import { InterfaceWidget } from "atoms/InterfaceWidget/InterfaceWidget";
import { Navigate, useParams } from "react-router-dom";
import { ModelInfo } from "../ModelInfo/ModelInfo";
import { mapBeModelToFeModelInfo } from "api/apiHelpers";
import { Loading } from "atoms/Loading/Loading";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";
import { Markdown } from "atoms/Markdown/Markdown";
import { iWidgetModelTypesEnum } from "atoms/InterfaceWidget/interfaceWidgetTypes";

/**
 * Renders a model description with an interface model test widget
 *
 * @category Component
 */
export function ModelDetails() {
  const urlParams = useParams();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const [, setNotification] = useRecoilState(notificationAtom);

  const [{ data: originalModel, error: modelError }, fetchOriginalModel] =
    useAxios<model>({
      url: `models/${modelOwner}/${modelName}`,
    });

  const model = useMemo(
    () => (originalModel ? mapBeModelToFeModelInfo(originalModel) : undefined),
    [originalModel]
  );

  const [
    { loading: isLoadingReadme, data: fetchedReadme, error: readmeError },
    fetchReadme,
  ] = useAxios<string>(
    {
      url: `models/${modelOwner}/${modelName}/master/download?filepath=README.md`,
      transformResponse: [],
    },
    { manual: true }
  );

  useEffect(() => {
    fetchReadme();
  }, [fetchReadme]);

  useEffect(() => {
    if (readmeError)
      setNotification({
        label: `Server error: ${readmeError.code}`,
        body: "Could not load file",
      });
  }, [readmeError]);

  if (modelError) {
    return <Navigate to={"/404"} />;
  }

  if (!model) {
    return (
      <div className="grow flex">
        <Loading />
      </div>
    );
  }

  return (
    <div className="relative grow">
      <ModelInfo
        {...model}
        onModelUpdate={() => {
          fetchOriginalModel();
        }}
      />
      <div className="flex md:flex-row flex-col h-full container mx-auto">
        <div
          className={`w-full ${
            originalModel?.deployed && "md:w-7/12"
          } p-4 flex flex-col gap-4`}
        >
          {isLoadingReadme ? (
            <Loading />
          ) : (
            fetchedReadme && <Markdown content={fetchedReadme} />
          )}
        </div>
        {originalModel?.deployed && (
          <div className="order-first md:order-none p-4 h-fit md:h-auto w-full md:w-5/12 border-b-2 md:border-l-2 md:border-b-0 border-slate-100 bg-white flex justify-start gap-4 flex-col">
            <div className="">
              <InterfaceWidget
                status={originalModel.sagemaker_pinned}
                modelName={model.name}
                owner={model.owner}
                classification={
                  originalModel?.tasks[0]?.text as iWidgetModelTypesEnum
                }
                modality={originalModel.modality}
                onUpdate={async () => {
                  try {
                    const { data } = await fetchOriginalModel();
                    return data;
                  } catch {
                    return;
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
