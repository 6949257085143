import { Field } from "formik";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders a checkbox input component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetCheckbox(props: IWidgetNestedProps) {
  const { disabled, name, label, placeholder } = props;
  return (
    <div data-testid="widget-checkbox-input">
      <Field
        type="checkbox"
        placeholder={placeholder}
        name={name}
        className="w-4 h-4 sm:text-sm border-gray-300 rounded-md rounded-lg shadow-sm"
        disabled={disabled}
      ></Field>
      {label && <span className="ml-2 text-sm text-gray-600">{label}</span>}
    </div>
  );
}
