import { Navigate, useParams } from "react-router-dom";
import { OrganizationInfo } from "./OrganizationInfo";
import { OrganizationModels } from "./OrganizationModels/OrganizationModels";

/**
 * Renders an organization page
 *
 * @category Component
 */
export function Organization() {
  const { orgName } = useParams();

  if (!orgName) {
    return <Navigate to="/404" replace />;
  }

  return (
    <div className="container mx-auto relative grow flex flex-row bg-white">
      <OrganizationInfo />
      <OrganizationModels />
    </div>
  );
}
