import { Fragment, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { Form, Formik, FormikHelpers } from "formik";
import { Disclosure, Transition } from "@headlessui/react";
import {
  iWidgetStatusEnum,
  IWidgetNestedFProps,
  iWidgetNestedParamsInput,
  iWidgetNestedParamsOutput,
  IWidgetProps,
  iWidgetModelTypesEnum,
} from "./interfaceWidgetTypes";
import { Button } from "atoms/Button/Button";
import Tooltip from "atoms/Tooltip/Tooltip";
import { accentStyleEnum } from "atoms/genericStyles";
import { modelTypeToInput, modelTypeToOutput } from "./interfaceWidgetHelpers";
import { InterfaceWidgetStatus } from "./InterfaceWidgetStatus";
import SyntaxHighlighter from "react-syntax-highlighter";
import { arduinoLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  InformationCircleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/solid";

const MODEL_KEEP_ALIVE_DELAY = 360;
const INFERENCE_TIMEOUT = 100_000;
const UPDATER_TIMEOUT_DELAY = 10_000;

/**
 * Renders interface widget content for API hosted model testing
 * @category Atom
 */
export function InterfaceWidgetContent({
  status,
  modelName,
  owner,
  classification = iWidgetModelTypesEnum.TEXT2TEXT_GENERATION,
  modality,
  onUpdate,
}: IWidgetProps) {
  const [inferenceLodading, setInferenceLoading] = useState(
    status === iWidgetStatusEnum.PENDING
  );
  const [inferenceResult, setInferenceResult] = useState<any>();
  const [inferenceInputComponents, setInferenceInputComponents] = useState<
    iWidgetNestedParamsInput | undefined
  >();
  const [inferenceInputValues, setInferenceInputValues] = useState<
    IWidgetNestedFProps | undefined
  >();
  const [inferenceOutputComponents, setInferenceOutputComponents] = useState<
    iWidgetNestedParamsOutput | undefined
  >();
  const [outputFailed, setOutputFailed] = useState<boolean>();
  const [inferenceFailed, setInferenceFailed] = useState<boolean>();
  const [inferenceDefaultValues, setInferenceDefaultValues] =
    useState<IWidgetNestedFProps>({});
  const [inferenceSchema, setInferenceSchema] = useState<any>(undefined);
  const [, requestInference] = useAxios(
    {
      method: "POST",
      url: "api-inference/test",
      params: {
        org_or_user_owner: owner,
        name: modelName,
        model_type: "Huggingface",
      },
      data: {
        inputs: undefined,
        delay: MODEL_KEEP_ALIVE_DELAY,
        task_name: classification as any,
        model_type: modality,
      },
      timeout: INFERENCE_TIMEOUT,
    },
    { manual: true }
  );

  useEffect(() => {
    setInferenceLoading(status === iWidgetStatusEnum.PENDING);
  }, [status]);

  useEffect(() => {
    if (!inferenceLodading) {
      return;
    }
    let id = setTimeout(async function updater() {
      const result = await onUpdate();
      if (result && result.sagemaker_pinned === iWidgetStatusEnum.PENDING) {
        id = setTimeout(updater, UPDATER_TIMEOUT_DELAY);
      }
    }, UPDATER_TIMEOUT_DELAY);

    return () => {
      clearTimeout(id);
    };
  }, [onUpdate, inferenceLodading]);

  useEffect(() => {
    const assignOutputs = async () => {
      let outputComponents = undefined;
      let output = false;
      if (inferenceResult !== undefined && !inferenceResult.status) {
        const mappedOutput = await modelTypeToOutput(
          classification,
          inferenceResult,
          inferenceInputValues
        );
        outputComponents = mappedOutput.output;
        output = !!mappedOutput.outputFailed;
      }

      setInferenceOutputComponents(outputComponents);
      setOutputFailed(output);
    };

    assignOutputs();
  }, [classification, inferenceResult, inferenceInputValues]);

  useEffect(() => {
    const mappedInput = modelTypeToInput(classification, inferenceInputValues);
    setInferenceInputComponents(mappedInput?.input);
    setInferenceDefaultValues(mappedInput?.defaultParams);
    setInferenceSchema(mappedInput?.input.formValidationSchema);
    setInferenceFailed(false);
  }, [classification, inferenceInputValues]);

  const handleDeploy = async () => {
    await requestInference();
    setInferenceLoading(true);
  };

  const handleSubmit = async (
    values: IWidgetNestedFProps,
    formikHelpers: FormikHelpers<IWidgetNestedFProps>
  ) => {
    try {
      const { data } = await requestInference({
        data: {
          ...inferenceInputComponents?.componentMapper(values),
          model_type: modality,
          delay: MODEL_KEEP_ALIVE_DELAY,
          task_name: classification as any,
        },
      });
      setInferenceResult(data);
      setInferenceInputValues(values);
      onUpdate();
      setInferenceFailed(false);
    } catch {
      setInferenceFailed(true);
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <div>
      <div className="text-sm px-2 py-1 rounded-md bg-violet-100 text-slate-700 font-medium w-fit mb-2">
        {classification}
      </div>
      <InterfaceWidgetStatus status={status} loading={inferenceLodading} />
      <Formik
        initialValues={inferenceDefaultValues}
        onSubmit={handleSubmit}
        validationSchema={inferenceSchema}
        validateOnChange
        validateOnMount
        enableReinitialize
      >
        {({ isSubmitting, touched, errors, isValid }) => (
          <Form className="w-full relative w-full flex flex-col gap-2">
            {inferenceInputComponents !== undefined ? (
              <>
                {inferenceInputComponents.components.map((part, index) => {
                  return (
                    <part.component
                      {...part.componentProps}
                      key={index}
                      name={index.toString()}
                      disabled={
                        isSubmitting || status !== iWidgetStatusEnum.READY
                      }
                    />
                  );
                })}
                {inferenceInputComponents.tips ? (
                  <Tooltip
                    textComponent={
                      <div className="w-80 text-slate-700 font-sm font-normal">
                        {inferenceInputComponents.tips}
                      </div>
                    }
                  >
                    <InformationCircleIcon className="h-4 w-4" />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div>Model classification is not yet supported</div>
            )}
            {touched.text && errors.text && (
              <div className="text-red-700 border-2 rounded-lg border-red-100 bg-red-50 px-4 py-2">
                {errors.text}
              </div>
            )}
            {status !== iWidgetStatusEnum.STANDBY && (
              <Button
                type="submit"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  inferenceLodading ||
                  inferenceInputComponents === undefined
                }
                style={accentStyleEnum.SIMPLE}
                className="h-10 mt-1 place-self-end"
              >
                Compute
              </Button>
            )}
            {status === iWidgetStatusEnum.STANDBY && (
              <Button
                type="button"
                loading={isSubmitting}
                style={accentStyleEnum.SIMPLE}
                className="h-10 mt-1 place-self-end"
                onClick={handleDeploy}
                disabled={
                  inferenceInputComponents === undefined || inferenceLodading
                }
              >
                Deploy on Sagemaker
              </Button>
            )}
            {inferenceFailed && (
              <div className="mt-4 px-3 py-2 rounded-md font-medium border-2 border-red-300 bg-red-100 text-sm text-gray-900">
                Inference call failed: this model does not support used
                classification
              </div>
            )}
            {inferenceOutputComponents && (
              <div className="mt-4 flex flex-col gap-4">
                {outputFailed && (
                  <div className="px-3 py-2 rounded-md font-medium border-2 border-yellow-300 bg-yellow-100 text-sm text-gray-900">
                    Could not map the output, defaulting to JSON view
                  </div>
                )}
                {inferenceOutputComponents.components.map((part, index) => {
                  return (
                    <part.component
                      {...part.componentProps}
                      key={`output-${index}`}
                    />
                  );
                })}
                <div>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button>
                          <span className="text-sm text-gray-600 inline-flex items-center">
                            JSON Output
                            {open ? (
                              <ArrowUpIcon className="mx-1 w-3 h-3" />
                            ) : (
                              <ArrowDownIcon className="mx-1 w-3 h-3" />
                            )}
                          </span>
                        </Disclosure.Button>
                        <Transition
                          as={Fragment}
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel>
                            <SyntaxHighlighter
                              className="rounded-md h-40 overflow-y-auto"
                              language={"json"}
                              style={arduinoLight}
                            >
                              {JSON.stringify(inferenceResult, null, 2)}
                            </SyntaxHighlighter>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
