import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";
import { Notification } from "../atoms/Notification/Notification";

/**
 * Renders a notification shower component
 *
 * @category Component
 */
export function NotificationBlock() {
  const [notification, setNotification] = useRecoilState(notificationAtom);
  const [showNotification, setShowNotification] = useState(false);
  const timoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearNotification = useCallback(() => {
    setShowNotification(false);
    setNotification({});
  }, [setNotification]);

  useEffect(() => {
    if (notification.label || notification.body) {
      setShowNotification(true);
      if (timoutRef.current !== undefined) {
        clearTimeout(timoutRef.current);
      }
      timoutRef.current = setTimeout(() => {
        clearNotification();
      }, 10000);
    }
  }, [notification.label, notification.body, clearNotification]);

  if (showNotification) {
    return (
      <div
        className={`fixed min-w-[100px] top-14 right-14 rounded-lg shadow-md border-1 z-40 bg-white flex gap-2 ${
          notification.label && notification.body
            ? "items-start"
            : "items-center"
        }`}
        onClick={() => clearNotification()}
        onBlur={() => clearNotification()}
      >
        <Notification {...notification} />
      </div>
    );
  } else {
    return null;
  }
}
