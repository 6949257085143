import { commentContent } from "api/apiTypes";
import { CommentWithReplies, ParentChildMap } from "./commentSectionProps";

/**
 * Sorting helper for comment
 * @param a - first comment data
 * @param b - second comment data
 * @returns difference between comment creation date
 * @category Logic
 */
export const byDate = (a: commentContent, b: commentContent) =>
  new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

/**
 * Root helper for comment
 * @param comment - comment data
 * @returns flag if comment is root
 * @category Logic
 */
export const isRoot = ({ parent_id }: commentContent) => parent_id === null;

/**
 * Converts comments to reply comment structure
 * @param comments - comments to convert
 * @returns list with structured comments
 */
export const addReplies = (
  comments: commentContent[] = []
): CommentWithReplies[] => {
  const parentChildMap: ParentChildMap = {};
  const commentsWithReplies: CommentWithReplies[] = comments.map((comment) => {
    return { ...comment, replies: [] };
  });
  for (const comment of commentsWithReplies) {
    parentChildMap[comment.id] = comment;
  }

  for (const comment of commentsWithReplies) {
    if (!comment.replies) {
      comment.replies = [];
    }
    if (!isRoot(comment)) {
      const parent = parentChildMap[comment.parent_id] as CommentWithReplies;

      const hasReply = parent?.replies?.find(
        (reply) => reply.id === comment.id
      );
      if (!hasReply && parent?.replies) {
        parent.replies.push(comment as CommentWithReplies);
      }
    }
  }

  return commentsWithReplies.filter(isRoot);
};
