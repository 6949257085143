import { model } from "api/apiTypes";
import { Loading } from "atoms/Loading/Loading";
import useAxios from "axios-hooks";
import {
  useModelId,
  useUpdateHandler,
} from "components/Model/ModelDiscussion/ModelDiscussionContext";
import { RichTextEditor } from "components/Model/ModelDiscussion/CommentSection/RichTextEditor/RichTextEditor";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notificationAtom } from "recoil/notification/atom";
import { userAtom } from "recoil/user/atom";
import { AddCommentProps, ModelDetailPageParams } from "./commentSectionProps";

/**
 * Renders a new comment editor
 *
 * @category Component
 */
export const AddComment: FC<AddCommentProps> = ({
  children,
  variant = "post",
}) => {
  const modelId = useModelId();
  const refetchComments = useUpdateHandler();
  const [, setNotification] = useRecoilState(notificationAtom);
  const [user] = useRecoilState(userAtom);
  const { owner, model } = useParams<ModelDetailPageParams>();
  const [{ data, loading, error }, request] = useAxios<model>(
    {
      url: `models/${owner}/${model}`,
      method: "PATCH",
    },
    { manual: true }
  );

  const handleClick = (body?: string) => async () => {
    await request({
      data: {
        add_comment: [
          {
            body,
            user_id: user.id,
            model_id: modelId,
            parent_id: variant !== "post" ? variant.id : undefined,
          },
        ],
      },
    });
    refetchComments();
  };

  if (loading && !data) {
    return <Loading />;
  }

  if (error) {
    setNotification({
      label: `Server error: ${error.code}`,
      body: "Could not post comment",
    });
  }

  return <RichTextEditor onClick={handleClick}>{children}</RichTextEditor>;
};
