import { IWidgetNestedCustomOutputProps } from "../interfaceWidgetTypes";

/**
 * Renders a textarea output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetTextArea(props: IWidgetNestedCustomOutputProps) {
  const { label, placeholder, value, inputValue } = props;
  return (
    <div data-testid="widget-textarea-output">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <div className="bg-white p-4 overflow-auto whitespace-pre-line">
        {value
          ? (value as string).split(inputValue).map((v, i) => (
              <>
                {i > 0 && inputValue}
                <span className="text-green-600">{v}</span>
              </>
            ))
          : placeholder}
      </div>
    </div>
  );
}
