import { Field } from "formik";
import { IWidgetNestedProps } from "../interfaceWidgetTypes";

/**
 * Renders a number input component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetNumber(props: IWidgetNestedProps) {
  const { disabled, name, label, placeholder } = props;
  return (
    <div data-testid="widget-number-input">
      {label && <span className="text-sm text-gray-600">{label}</span>}
      <Field
        placeholder={placeholder}
        name={name}
        className="h-6 pl-2 pr-2 ml-2 w-12 text-slate-600 sm:text-sm border-gray-300 rounded-md shadow-sm"
        disabled={disabled}
        // validate={(v: number) => v >= 20 && v <= 1000}
      ></Field>
    </div>
  );
}
