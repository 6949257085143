import {
  IWidgetNestedCustomOutputProps,
  iWidgetScoreValue,
} from "../interfaceWidgetTypes";

/**
 * Renders a score output component for a diret use in {@link InterfaceWidget | 'InterfaceWidget'}.
 * @category Atom
 */
export function InterfaceWidgetScore(
  props: IWidgetNestedCustomOutputProps<iWidgetScoreValue>
) {
  const { label, placeholder, value } = props;

  return (
    <div data-testid="widget-score-output">
      {value ? (
        <div className="w-full">
          <div className="flex gap-4 items-center">
            <div className="rounded-xl w-full bg-transparent/10">
              <div
                className="rounded-xl h-2 mr-auto bg-violet-600"
                style={{
                  width: `${value.score * 100}%`,
                }}
              />
            </div>
            <div className="text-sm text-gray-800 font-monos">
              {value.score.toPrecision(3)}
            </div>
          </div>

          {label && (
            <div className="text-sm text-gray-800 font-mono -mt-1">{label}</div>
          )}
        </div>
      ) : (
        <div>{placeholder ? placeholder : "No value was provided"}</div>
      )}
    </div>
  );
}
