import { RadioGroup } from "@headlessui/react";
import { modelModalityEnum } from "api/apiTypes";
import { useFormikContext } from "formik";
import { modelParamsSettingsFormProps } from "../modelParamsSettingsTypes";

/**
 * Renders radio options for model modality setting
 * @category Component
 */
export function ModalitySetting() {
  const { setFieldValue, values } =
    useFormikContext<modelParamsSettingsFormProps>();

  const modalityRadioOptions = [
    {
      label: "Huggingface",
      description: "Huggingface description.",
      value: modelModalityEnum.HUGGINGFACE,
    },
    {
      label: "PyTorch",
      description: "Pytorch description.",
      value: modelModalityEnum.PYTORCH,
    },
    {
      label: "Tensorflow",
      description: "Tensorflow description.",
      value: modelModalityEnum.TENSORFLOW,
    },
  ];

  return (
    <div>
      <p className="font-semibold text-sm">Modality</p>
      <div className="ml-4">
        <RadioGroup
          className="w-full flex flex-col gap-1 rounded-lg"
          value={values.modality}
          onChange={(value: string) => setFieldValue("modality", value)}
        >
          {modalityRadioOptions.map((item) => {
            return (
              <RadioGroup.Option key={item.value} value={item.value}>
                {({ checked }) => (
                  <div
                    className={`flex items-center p-2 gap-2 items-start rounded-lg cursor-pointer hover:bg-slate-50`}
                  >
                    <div className="border rounded-full h-4 w-4 flex">
                      {checked && (
                        <div className="bg-slate-700 h-2 w-2 rounded-full m-auto" />
                      )}
                    </div>
                    <div className="h-full">
                      <p className="text-sm font-semibold">{item.label}</p>
                      <p className="text-xs font-medium text-slate-500">
                        {item.description}
                      </p>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
}
