import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import "./AnimationTitle.css";

const FADE_INTERVAL_MS = 2000;
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2;
const WORDS_TO_ANIMATE = [
  "MAPV",
  "Natural Language Processing",
  "Computer Vision",
  "Drug Discovery",
  "Insight Generation",
  "Pharmacology",
  "Radiology",
];

type FadingProperty = { fade: "fade-in" | "fade-out" };

export function AnimatedText() {
  const [fadeProp, setFadeProp] = useState<FadingProperty>({ fade: "fade-in" });
  const [wordOrder, setWordOrder] = useState(0);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === "fade-in"
        ? setFadeProp({ fade: "fade-out" })
        : setFadeProp({ fade: "fade-in" });
    }, FADE_INTERVAL_MS);

    return () => clearInterval(fadeTimeout);
  }, [fadeProp]);

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder(
        (prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length
      );
    }, WORD_CHANGE_INTERVAL_MS);

    return () => clearInterval(wordTimeout);
  }, []);

  return (
    <div className="items-center text-center">
      <div className="bg-gradient-to-r from-deepmind-between to-deepmind-green text-white">
        <h2 className="pt-40 text-4xl container mx-auto lg:w-2/4">
          Collaborative Machine Learning Platform For
        </h2>
        <h2 className="text-7xl font-bold h-48 container mx-auto lg:w-2/4">
          <span className={fadeProp.fade}>{WORDS_TO_ANIMATE[wordOrder]}</span>
        </h2>
      </div>
      <div className="pt-8">
        {isAuthenticated && (
          <Link to="/models">
            <button
              type="button"
              className="inline-flex text-2xl items-center px-10 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-deepmind-between hover:bg-deepmind-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepmind-blue"
            >
              EXPLORE THE MODEL COLLECTION
            </button>
          </Link>
        )}
      </div>
      <h2 className="container mx-auto lg:w-2/4 font-bold text-7xl pt-40 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-deepmind-blue to-deepmind-green">
        Empowering Collaborative ML and Digital Transformation
      </h2>
    </div>
  );
}
