import { useEffect, useMemo, useState } from "react";
import { DocumentAddIcon, DownloadIcon } from "@heroicons/react/solid";
import useAxios from "axios-hooks";
import { model, revisions, file } from "api/apiTypes";
import { useRecoilState } from "recoil";
import {
  notificationAtom,
  notificationStatusEnum,
} from "recoil/notification/atom";
import { Button } from "atoms/Button/Button";
import { Dropdown } from "atoms/Dropdown/Dropdown";
import { FileTable } from "atoms/FileTable/FileTable";
import { FilePreview } from "atoms/FilePreview/FilePreview";
import { accentStyleEnum } from "atoms/genericStyles";
import { UploadModal } from "components/Model/UploadModal/UploadModal";
import { valuePair } from "genericTypes";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ModelInfo } from "./ModelInfo/ModelInfo";
import {
  mapBeFileToTableFile,
  mapBeModelToFeModelInfo,
  mapBeRevisionsToFeValuePairs,
} from "api/apiHelpers";
import { Loading } from "atoms/Loading/Loading";
import FileDownlaod from "js-file-download";
import { Breadcrumbs } from "atoms/Breadcrumbs/Breadcrumbs";

/**
 * Renders a model file section
 *
 * @category Component
 */
export function ModelFiles() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const modelName = urlParams["model"];
  const modelOwner = urlParams["owner"];
  const branchName = urlParams["branch"];
  const gitPath = urlParams["*"];

  const [, setNotification] = useRecoilState(notificationAtom);
  const [openUpload, setOpenUpload] = useState<boolean>(false);

  const [
    { loading: isLoadingModel, data: fetchedModel, error: modelError },
    fetchModel,
  ] = useAxios<model>({
    url: `models/${modelOwner}/${modelName}`,
  });

  const model = useMemo(
    () => (fetchedModel ? mapBeModelToFeModelInfo(fetchedModel) : undefined),
    [fetchedModel]
  );

  const [
    { loading: isLoadingBranches, data: fetchedBranches, error: branchesError },
    fetchBranches,
  ] = useAxios<revisions>(
    {
      url: `models/${modelOwner}/${modelName}/revision`,
    },
    { manual: true }
  );

  const branches: valuePair<string>[] | undefined = useMemo(
    () =>
      fetchedBranches
        ? mapBeRevisionsToFeValuePairs(fetchedBranches)
        : undefined,
    [fetchedBranches]
  );

  const [{ loading: isLoadingFiles, data: files }, fetchFiles] = useAxios<file>(
    {
      url: `models/${modelOwner}/${modelName}/${branchName}`,
    },
    { manual: true }
  );

  const { mappedLocalFiles, currentFile } = useMemo(() => {
    if (files) {
      const gitPathFile = gitPath
        ? gitPath.split("/").reduce<file | undefined>((currentFile, path) => {
            return currentFile?.children?.find((file) => file.name === path);
          }, files)
        : files;
      if (gitPathFile) {
        return {
          mappedLocalFiles: (gitPathFile.children || []).map((file) =>
            mapBeFileToTableFile(file)
          ),
          currentFile: gitPathFile,
        };
      }
    }
    return {
      mappedLocalFiles: undefined,
      currentFile: undefined,
    };
  }, [files, gitPath]);

  const [, download] = useAxios(
    {
      url: `models/${modelOwner}/${modelName}/${branchName}/download`,
      responseType: "blob",
    },
    {
      manual: true,
    }
  );

  const [, toS3] = useAxios(
    {
      url: `models/${modelOwner}/${modelName}/pull`,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (model) {
      fetchBranches();
    }
  }, [model]);

  useEffect(() => {
    if (branches) {
      if (branches.some((branch) => branch.value === branchName)) {
        fetchFiles();
      } else {
        navigate(`${branches[0].value}`, { replace: true });
      }
    }
  }, [branches, branchName]);

  const setBranch = (branch: string) => {
    if (branch !== branchName) navigate(`${branch}`, { replace: true });
  };

  const pushToS3 = () => {
    toS3().then(
      () => {
        setNotification({
          label: "Uploading to S3",
          status: notificationStatusEnum.SUCCESS,
        });
      },
      () => {
        setNotification({
          label: "Failed when Uploading to S3",
          status: notificationStatusEnum.ALERT,
        });
      }
    );
  };

  if (branchesError || modelError) {
    return <Navigate to={"/404"} />;
  }

  if (isLoadingModel || isLoadingFiles || !model) {
    return (
      <div className="grow flex">
        <Loading />
      </div>
    );
  }

  return (
    <div className="relative grow flex">
      <div className="flex flex-col grow">
        <ModelInfo
          {...model}
          onModelUpdate={() => {
            fetchModel();
          }}
        />
        {mappedLocalFiles && branches && (
          <div className="flex flex-col grow">
            <div className="container mx-auto mt-6 flex items-center">
              <Dropdown
                style={accentStyleEnum.SIMPLE}
                options={branches}
                value={
                  branchName
                    ? { value: branchName, label: branchName }
                    : undefined
                }
                onChange={({ value }) => setBranch(value)}
                className="min-w-16"
              />
              <div className="ml-2  h-full">
                <Breadcrumbs
                  basePath={`/models/${modelOwner}/${modelName}/files/${branchName}`}
                  breadcrumbsPath={gitPath ?? ""}
                  includeBasePath
                  basePathAbrv={modelName}
                />
              </div>
              {currentFile?.type !== "file" && (
                <>
                  <Button
                    className="ml-auto"
                    style={accentStyleEnum.SIMPLE}
                    onClick={() =>
                      download().then((response) => {
                        FileDownlaod(
                          response.data,
                          `${model.name}_${branchName}.zip`
                        );
                      })
                    }
                  >
                    Download
                    <DownloadIcon className="h-4 w-4" />
                  </Button>
                  <Button
                    style={accentStyleEnum.SIMPLE}
                    className="ml-2"
                    onClick={() => setOpenUpload(true)}
                  >
                    Add file <DocumentAddIcon className="h-4 w-4" />
                  </Button>
                  <Button
                    style={accentStyleEnum.SIMPLE}
                    className="ml-2"
                    onClick={pushToS3}
                  >
                    upload to S3
                  </Button>
                </>
              )}
            </div>
            {currentFile?.type === "file" ? (
              <FilePreview className="mt-4" />
            ) : (
              <FileTable
                lastCommiter={
                  fetchedBranches?.find((branch) => branch.name === branchName)
                    ?.author_name || "Unknown"
                }
                hexSHA={
                  fetchedBranches?.find((branch) => branch.name === branchName)
                    ?.hexsha || "Unknown"
                }
                commitMessage={
                  fetchedBranches?.find((branch) => branch.name === branchName)
                    ?.message || "Unknown"
                }
                timeLastCommited={model.lastUpdated}
                files={mappedLocalFiles}
                showPrev={!!gitPath}
                className="mt-4"
              />
            )}
            <UploadModal
              open={openUpload}
              setOpen={setOpenUpload}
              availableBranches={branches}
              path={gitPath}
              onUpdate={() => {
                if (branchName) {
                  fetchFiles();
                  fetchModel();
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
