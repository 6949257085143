import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import { accentStyles } from "atoms/genericStyles";
import { overrideTailwindClasses } from "tailwind-override";
import { DropdownProps } from "./dropdownTypes";

/**
 * Renders a dropdown component
 * ```tsx
 * const options = [{value: 1, label: "option 1"}, {value: 2, label: "option 2"}];
 * const [option, setOption] = useState(options[0]);
 * ...
 *  <Dropdown
 *    className="w-full mb-2"
 *    style={accentStyleEnum.SIMPLE}
 *    value={option}
 *    options={options}
 *    onChange={(value) => {
 *      setOption(value);
 *    }}
 * />
 * ```
 * @category Atom
 */
export function Dropdown({
  options,
  placeholder,
  value,
  onChange,
  disabled,
  className,
  containerClassName,
  style,
  label,
}: DropdownProps) {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      <div
        className={overrideTailwindClasses(
          `w-max flex flex-col gap-2 ${containerClassName}`
        )}
      >
        {label && (
          <Listbox.Label className="text-sm font-medium">{label}</Listbox.Label>
        )}
        <div className="relative">
          <Listbox.Button
            className={overrideTailwindClasses(
              `w-full relative font-medium py-2 mr-6 px-3 text-left rounded-lg bg-gradient-to-b hover:brightness-95 ${
                disabled
                  ? `bg-gray-200 text-gray-500 ${
                      accentStyles[style].borderColor &&
                      "border border-gray-400"
                    }`
                  : `bg-gradient-to-b hover:brightness-95 ${
                      accentStyles[style].gradientFrom
                    } ${accentStyles[style].textColorContrast} ${
                      accentStyles[style].gradientTo
                    } ${
                      accentStyles[style].borderColor &&
                      "border " + accentStyles[style].borderColor
                    } `
              } px-3 cursor-default sm:text-sm ${className}`
            )}
          >
            <span className="block truncate">
              {value ? value.label : placeholder}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, id) => (
                <Listbox.Option
                  key={`dropdown-option-${option.value}-${id}`}
                  className={({ active }) =>
                    `${
                      active
                        ? "brightness-95 bg-white"
                        : "brightness-100 bg-white"
                    }
                          cursor-default select-none relative py-2 px-4 text-gray-900`
                  }
                  value={option}
                >
                  <>
                    <span className={`block truncate`}>{option.label}</span>
                  </>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </div>
    </Listbox>
  );
}
