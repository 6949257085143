import { Link } from "react-router-dom";
import { dateToHumanText } from "utils";
import { FilterTag } from "atoms/FilterTag/FilterTag";
import { accentStyleEnum, accentStyles } from "atoms/genericStyles";
import { ModelPlaqueProps } from "./modelPlaqueTypes";
import { InformationCircleIcon, ThumbUpIcon } from "@heroicons/react/solid";
import Tooltip from "atoms/Tooltip/Tooltip";

/**
 * Renders a plaque with model specific information
 * @category Atom
 */
export function ModelPlaque({
  name,
  author,
  tasks,
  tags,
  libraries,
  languages,
  lastUpdated,
  urlTo,
  thumbs,
  sagemakerStatus,
}: ModelPlaqueProps) {
  const { sagemakerText, sagemakerColor } =
    sagemakerStatus === 0
      ? { sagemakerText: "not deployed", sagemakerColor: "fill-red-300" }
      : sagemakerStatus === 1
      ? { sagemakerText: "loading", sagemakerColor: "fill-yellow-300" }
      : sagemakerStatus === 2
      ? { sagemakerText: "deployed", sagemakerColor: "fill-green-300" }
      : { sagemakerText: "unknown", sagemakerColor: "fill-grey-300" };

  return (
    <Link
      className="h-fit overflow-hidden rounded-lg drop-shadow-md px-5 py-4 flex flex-col gap-2 items-center bg-gradient-to-b from-slate-50 to-slate-100 hover:to-slate-200 active:bg-gradient-to-t"
      to={urlTo}
    >
      <div className="flex gap-4 w-full justify-between">
        <div className="text-md font-medium inline-flex">
          {name}

          <Tooltip
            className="py-0 px-2 font-normal z-10 bottom-auto left-16 top-5"
            textComponent={
              <div className="text-sm py-1 px-1">sagemaker {sagemakerText}</div>
            }
          >
            <InformationCircleIcon
              className={`pl-1 h-4 w-4 ${sagemakerColor}`}
            />
          </Tooltip>
        </div>
        <div
          className={`${
            accentStyles[accentStyleEnum.SIMPLE].bgColor
          } hidden sm:block mt-1 py-0.5 px-2 text-sm ${
            accentStyles[accentStyleEnum.SIMPLE].textColorContrast
          } rounded-md w-fit`}
        >
          {author}
        </div>
      </div>
      <div className="flex w-full">
        <div className="text-ellipsis overflow-hidden flex-wrap h-[26px] flex gap-2">
          {tasks.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.VIOLET}
              />
            );
          })}
          {tags.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.CADET}
              />
            );
          })}
          {libraries.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.SALMON}
              />
            );
          })}
          {languages.map((tag) => {
            return (
              <FilterTag
                key={tag}
                label={tag}
                showClose={false}
                grayedOut={false}
                disabled
                elevated={false}
                tagStyle={accentStyleEnum.YELLOW}
              />
            );
          })}
        </div>

        <div className="ml-auto mr-3 flex items-center text-slate-400">
          {thumbs.length}
          <ThumbUpIcon className="w-4 h-4 ml-1" />
        </div>

        <div className="whitespace-nowrap text-slate-400">
          Updated {dateToHumanText(lastUpdated)}
        </div>
      </div>
    </Link>
  );
}
