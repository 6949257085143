import * as Yup from "yup";

/**
 * Schema for {@link UploadModal | 'UploadModal'}
 * @category Schema
 */
export const uploadModalSchema = Yup.object().shape({
  files: Yup.array().min(1, "You need to upload at least one file"),
  commitMessage: Yup.string().required("You need to enter a commit message"),
});
